export enum Colors {
	'border-white' = 'border-white',
	'white-primary' = 'white-primary',
	'white-secondary' = 'white-secondary',
	'white-tertiary' = 'white-tertiary',
	'white-red' = 'white-red',
	'white-gray' = 'white-gray',
	'white-green' = 'white-green',
	'white-yellow' = 'white-yellow',
	primary = 'primary',
	'primary-light' = 'primary-light',
	'primary-dark' = 'primary-dark',
	'border-primary' = 'border-primary',
	'outline-primary' = 'outline-primary',
	secondary = 'secondary',
	'secondary-light' = 'secondary-light',
	'secondary-dark' = 'secondary-dark',
	'border-secondary' = 'border-secondary',
	'outline-secondary' = 'outline-secondary',
	tertiary = 'tertiary',
	'tertiary-light' = 'tertiary-light',
	'tertiary-dark' = 'tertiary-dark',
	'border-tertiary' = 'border-tertiary',
	'outline-tertiary' = 'outline-tertiary',
	yellow = 'yellow',
	'border-yellow' = 'border-yellow',
	'outline-yellow' = 'outline-yellow',
	red = 'red',
	'border-red' = 'border-red',
	'outline-red' = 'outline-red',
	green = 'green',
	'border-green' = 'border-green',
	'outline-green' = 'outline-green',
	gray = 'gray',
	'border-gray' = 'border-gray',
	'outline-gray' = 'outline-gray',
	'gray-1' = 'gray-1',
	'border-gray-1' = 'border-gray-1',
	'outline-gray-1' = 'outline-gray-1',
	'gray-2' = 'gray-2',
	'border-gray-2' = 'border-gray-2',
	'outline-gray-2' = 'outline-gray-2',
	'gray-3' = 'gray-3',
	'border-gray-3' = 'border-gray-3',
	'outline-gray-3' = 'outline-gray-3',
	'gray-4' = 'gray-4',
	'border-gray-4' = 'border-gray-4',
	'outline-gray-4' = 'outline-gray-4',
	'gray-5' = 'gray-5',
	'border-gray-5' = 'border-gray-5',
	'outline-gray-5' = 'outline-gray-5',
	'gray-6' = 'gray-6',
	'gray-7' = 'gray-7',
	'border-gray-6' = 'border-gray-6',
	'outline-gray-6' = 'outline-gray-6',
	success = 'success',
	warning = 'warning',
	error = 'error',
	danger = 'danger',
	muted = 'muted',
	empty = 'empty',
	premium = 'premium',
	association = 'association',
	job = 'job'
}

export enum ColorValues {
	primary = '#96BD68',
	'primary-light' = '#AED67E',
	'primary-dark' = '#648E33',
	secondary = '#FF8C00',
	'secondary-light' = '#fcc597',
	'secondary-dark' = '#f08400',
	tertiary = '#74dbf7',
	'tertiary-light' = '#aae8f2',
	'tertiary-dark' = '#5ebad3',
	yellow = '#ff9800',
	red = '#f44336',
	green = '#4caf50',
	'gray-1' = '#f2f2f4',
	'gray-2' = '#d5d5db',
	'gray-3' = '#737373',
	'gray-4' = '#5d5c70',
	'gray-5' = '#0c0c0E',
	'gray-6' = '#242429',
	'gray-7' = '#EFF2F5',
	'linkedin' = '#0077B5'
}
