import { FC } from 'react';
import { Col, Row } from 'reactstrap';
import { clx } from 'Utils';
import { LoadingMask } from 'Elements';
import { UserCategory } from 'Types';
import styles from 'partials/homepage/card/job-statistic-card.module.scss';

interface Props {
	userType: UserCategory;
}

export const JobStatisticsSectionLoading: FC<Props> = ({ userType }) => {
	return (
		<Row>
			<Col
				sm={6}
				xl={userType === 'communityPartner' ? 4 : 6}
				className={clx(
					'mb-4',
					userType === 'communityPartner' && 'mb-xl-0'
				)}>
				<div className="d-flex flex-row flex-lg-column gap-3 gap-lg-2 align-items-center">
					<LoadingMask
						className={clx(styles.loadingIcon, styles.loading)}
					/>
					<div className="d-flex flex-column align-items-start align-items-lg-center flex-grow-1 gap-1 w-100">
						<LoadingMask className={clx('w-80', styles.loading)} />
						<LoadingMask
							className={clx(
								'w-50',
								styles.loading,
								styles.loadingSm
							)}
						/>
					</div>
				</div>
			</Col>
			<Col
				sm={6}
				xl={userType === 'communityPartner' ? 4 : 6}
				className={clx(
					'mb-4',
					userType === 'communityPartner' && 'mb-xl-0'
				)}>
				<div className="d-flex flex-row flex-lg-column gap-3 gap-lg-2 align-items-center">
					<LoadingMask
						className={clx(styles.loadingIcon, styles.loading)}
					/>
					<div className="d-flex flex-column align-items-start align-items-lg-center flex-grow-1 gap-1 w-100">
						<LoadingMask className={clx('w-80', styles.loading)} />
						<LoadingMask
							className={clx(
								'w-50',
								styles.loading,
								styles.loadingSm
							)}
						/>
					</div>
				</div>
			</Col>
			<Col sm={6} xl={userType === 'communityPartner' ? 4 : 6}>
				<div className="d-flex flex-row flex-lg-column gap-3 gap-lg-2 align-items-center">
					<LoadingMask
						className={clx(styles.loadingIcon, styles.loading)}
					/>
					<div className="d-flex flex-column align-items-start align-items-lg-center flex-grow-1 gap-1 w-100">
						<LoadingMask className={clx('w-80', styles.loading)} />
						<LoadingMask
							className={clx(
								'w-50',
								styles.loading,
								styles.loadingSm
							)}
						/>
					</div>
				</div>
			</Col>
			{userType === 'businessPartner' && (
				<Col sm={6}>
					<div className="d-flex flex-row flex-lg-column gap-3 gap-lg-2 align-items-center">
						<LoadingMask
							className={clx(styles.loadingIcon, styles.loading)}
						/>
						<div className="d-flex flex-column align-items-start align-items-lg-center flex-grow-1 gap-1 w-100">
							<LoadingMask
								className={clx('w-80', styles.loading)}
							/>
							<LoadingMask
								className={clx(
									'w-50',
									styles.loading,
									styles.loadingSm
								)}
							/>
						</div>
					</div>
				</Col>
			)}
		</Row>
	);
};
