import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AspectRatio, ModalProps, SubscriptionPlan } from 'Types';
import {
	FontAwesome,
	LoadingMask,
	LoadingSpinner,
	ModalBox,
	ModalBoxChangeImage,
	SkeletonWrapper
} from 'Elements';
import { useSubscriptionPlansFeature } from 'src/hooks/useSubscription';
import { clx, currencyFormatter } from 'src/helpers/Utils';
import styles from 'partials/step/step-container.module.scss';
import { Col, Row } from 'reactstrap';
import { useSubscriptionPlanDetails } from 'src/hooks/useSubscriptionPlans';
import { SwiperSlide, Swiper } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useModal } from 'src/hooks/useModal';

interface Props extends ModalProps {
	plan: SubscriptionPlan;
}

export const ModalBoxPlanInf: FC<Props> = ({ isOpen, onClose, plan }) => {
	const { t } = useTranslation();
	const [openModal, toggleModal] = useModal();
	const [image, setImage] = useState<any>();

	const { data: subscriptionPlansFeature, isLoading: isLoadingPlansFeature } =
		useSubscriptionPlansFeature(
			plan?.subscriptionPlanFeatureId as string,
			plan?.id as string,
			{
				enabled: !!plan?.subscriptionPlanFeatureId
			}
		);

	const { data: subscriptionPlanDetails, isLoading } =
		useSubscriptionPlanDetails(plan?.id as string, {
			enabled: !!plan?.id
		});

	const breakpoints = {
		0: {
			slidesPerView: 1,
			spaceBetween: 10
		},
		576: {
			slidesPerView: 3,
			spaceBetween: 10
		},
		992: {
			slidesPerView: 5,
			spaceBetween: 10
		},
		1200: {
			slidesPerView: 5,
			spaceBetween: 10
		}
	};

	const breakpointsFeatures = {
		0: {
			slidesPerView: 1,
			spaceBetween: 10
		},
		576: {
			slidesPerView: 2,
			spaceBetween: 10
		},
		992: {
			slidesPerView: 2,
			spaceBetween: 10
		},
		1200: {
			slidesPerView: 2,
			spaceBetween: 10
		}
	};

	const SkeletonImge = () => {
		return (
			<div
				className={clx(
					'd-flex flex-column justify-content-between',
					styles.cardPlanImge
				)}>
				<div className="d-flex flex-column align-items-start gap-3 pt-0">
					<LoadingMask />
				</div>
			</div>
		);
	};

	const SkeletonFeature = () => {
		return (
			<div
				className={clx(
					'd-flex flex-column justify-content-between',
					styles.cardPlanImge
				)}>
				<div className="pt-0">
					<Row>
						<LoadingMask />
						<Col
							sm={12}
							xl={6}
							className="d-flex flex-column gap-3">
							<div className={styles.labelText}></div>
							<div className={styles.labelText}></div>
							<div className={styles.labelText}></div>
							<div className={styles.labelText}></div>
							<div className={styles.labelText}></div>
						</Col>
						<Col
							sm={12}
							xl={6}
							className="d-flex flex-column gap-3">
							<div className={styles.labelText}></div>
							<div className={styles.labelText}></div>
							<div className={styles.labelText}></div>
							<div className={styles.labelText}></div>
							<div className={styles.labelText}></div>
						</Col>
						<div className={clx('mt-5', styles.labelHeder)}></div>
						<Col sm={12} xl={12} className="d-flex gap-3 mt-3">
							<div className={styles.imgeSkeleton}></div>
							<div className={styles.imgeSkeleton}></div>
						</Col>
					</Row>
				</div>
			</div>
		);
	};

	return (
		<ModalBox
			size="xl"
			isOpen={isOpen}
			onClose={onClose}
			title={t('text.more-info-about-this')}>
			<div className="d-flex flex-column gap-2">
				<div className="d-flex align-items-center justify-content-between">
					<h2 className="fw-500 fs-3">{plan?.name}</h2>
					<div className="d-flex">
						<div className="d-flex flex-column pe-3 me-3 text-primary">
							<h5 className="fw-500 fs-3 mb-0">
								{currencyFormatter(plan?.price ?? 0)}
							</h5>
							<div>
								<span className="fw-300 lh-base">
									{t('title.per')}
								</span>
								<span className="mt-auto fs-base">
									{`${plan?.durationDays ?? ''} ${t(
										'title.days'
									)}`}
								</span>
							</div>
						</div>
					</div>
				</div>
				{subscriptionPlanDetails?.shortDescription && (
					<div>
						<p className="fw-400 fs-sm">
							{subscriptionPlanDetails?.shortDescription ??
								plan?.shortDescription}
						</p>
					</div>
				)}
				<div className='px-2' style={{ overflowY: 'auto', height: '500px' }}>
					{plan.type === 1 && isLoading && (
						<Row className="position-relative">
							<div className="d-flex align-items-center gap-2 w-100">
								{[1, 2, 3, 4, 5].map((item) => {
									return (
										<SkeletonWrapper
											key={item}
											count={1}
											wrapperClassName="col"
											skeleton={SkeletonImge}
										/>
									);
								})}
							</div>
						</Row>
					)}
					{plan.type === 1 &&
						subscriptionPlanDetails?.subscriptionPlanGalleries.length !== 0 && (
							<div className="mt-3 mb-3">
								<Swiper
									breakpoints={breakpoints}
									navigation={true}
									modules={[Navigation]}
									style={{
										height: '85px'
									}}
									// className={styles.mySwiper}
								>
									{subscriptionPlanDetails?.subscriptionPlanGalleries?.map(
										(item) => (
											<SwiperSlide
												key={plan.id}
												style={{
													width: '150px',
													cursor: 'pointer'
												}}>
												{item.mediaType === 0 ? (
													<img
														alt={item.id}
														style={{
															borderRadius: '8px',
															objectFit: 'cover'
														}}
														className="w-100 h-100"
														src={item.url}
														onClick={() => {
															setImage(item);
															toggleModal();
														}}
													/>
												) : (
													<div
														style={{
															borderRadius: '8px',
															background:
																'#f5f5f7',
															position:
																'relative',
															width: '100%',
															height: '100%'
														}}
														onClick={() => {
															setImage(item);
															toggleModal();
														}}>
														<video
															className="w-100 h-100"
															src={item.url}
														/>
														<div
															style={{
																position:
																	'absolute',
																top: 0,
																left: 0,
																width: '100%',
																height: '100%',
																textAlign:
																	'center',
																display: 'flex',
																alignItems:
																	'center',
																justifyContent:
																	'center'
															}}>
															<FontAwesome
																icon="play"
																size="3x"
																className="text-white"
															/>
														</div>
													</div>
												)}
											</SwiperSlide>
										)
									)}
								</Swiper>
							</div>
						)}
					<div>
						<p
							dangerouslySetInnerHTML={{
								__html:
									subscriptionPlanDetails?.description ??
									plan?.description
							}}
						/>
					</div>

					{isLoadingPlansFeature && (
						<SkeletonWrapper
							count={1}
							wrapperClassName="col"
							skeleton={SkeletonFeature}
						/>
					)}
					{!isLoadingPlansFeature && subscriptionPlansFeature && (
						<div className="border p-4 rounded">
							<Row>
								{subscriptionPlansFeature?.eventSetting
									?.eventsEnabled && (
									<Col sm={12} xl={6}>
										<div className="d-flex align-items-center gap-2 mb-3">
											<FontAwesome
												icon="square-check"
												size="lg"
												className="flex-shrink-0 text-secondary"
											/>
											<div>
												{t(
													'text.feature.events-enabled'
												)}
												:
											</div>
											<div>
												{subscriptionPlansFeature
													?.eventSetting
													?.eventsEnabled
													? t('label.yes')
													: t('label.no')}
											</div>
										</div>
									</Col>
								)}

								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t(
												'text.feature.max-publish-events'
											)}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.eventSetting
												?.maxPublishEvents ?? 0}
										</div>
									</div>
								</Col>

								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t(
												'text.feature.publish-events-enabled'
											)}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.eventSetting
												?.publishEventsEnabled
												? t('label.yes')
												: t('label.no')}
										</div>
									</div>
								</Col>

								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t(
												'text.feature.internship-enabled'
											)}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.internshipSetting
												?.internshipEnabled
												? t('label.yes')
												: t('label.no')}
										</div>
									</div>
								</Col>

								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t(
												'text.feature.internship-manager-enabled'
											)}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.internshipSetting
												?.internshipManagerEnabled
												? t('label.yes')
												: t('label.no')}
										</div>
									</div>
								</Col>

								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t(
												'text.feature.max-publish-internships'
											)}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.internshipSetting
												?.maxPublishInternships ?? 0}
										</div>
									</div>
								</Col>

								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t(
												'text.feature.publish-internship-enabled'
											)}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.internshipSetting
												?.publishInternshipEnabled
												? t('label.yes')
												: t('label.no')}
										</div>
									</div>
								</Col>
								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t('text.feature.job-enabled')}:
										</div>
										<div>
											{subscriptionPlansFeature
												?.jobSetting?.jobEnabled
												? t('label.yes')
												: t('label.no')}
										</div>
									</div>
								</Col>
								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t(
												'text.feature.jobs-google-index'
											)}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.jobSetting?.jobsGoogleIndex
												? t('label.yes')
												: t('label.no')}
										</div>
									</div>
								</Col>
								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t(
												'text.feature.limit-job-visibility'
											)}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.jobSetting?.limitJobVisibility
												? t('label.yes')
												: t('label.no')}
										</div>
									</div>
								</Col>
								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t('text.feature.max-publish-jobs')}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.jobSetting?.maxPublishJobs ??
												0}
										</div>
									</div>
								</Col>
								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t(
												'text.feature.publish-job-enabled'
											)}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.jobSetting?.publishJobEnabled
												? t('label.yes')
												: t('label.no')}
										</div>
									</div>
								</Col>
								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t(
												'text.feature.max-user-accounts'
											)}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.userAccountSetting
												?.maxUserAccounts ?? 0}
										</div>
									</div>
								</Col>
							</Row>
							<Row className="mt-3">
								{plan.type === 1 && isLoading && (
									<LoadingSpinner />
								)}
								{plan.type === 1 &&
									!isLoading &&
									subscriptionPlanDetails?.features &&
									subscriptionPlanDetails?.features?.length >
										0 && (
										<div>
											<div className="d-flex gap-2 text-primary">
												<FontAwesome icon="check" />
												<h1 className="fs-5 fw-500 text-primary">
													Features offered by this
													partner
												</h1>
											</div>
											<Swiper
												breakpoints={
													breakpointsFeatures
												}
												navigation={true}
												modules={[Navigation]}
												// className={styles.mySwiper}
											>
												{subscriptionPlanDetails?.features?.map(
													(item, index) => (
														<SwiperSlide
															key={index}
															style={{
																width: '500px',
																height: '200px'
															}}>
															<div
																className="d-flex align-items-start gap-2 mt-3"
																style={{
																	height: '100%',
																	borderRadius:
																		'8px',
																	padding:
																		'10px 16px',
																	background:
																		'#F2F2F4'
																}}>
																<FontAwesome
																	icon={
																		item.icon
																	}
																	size="2x"
																	className="flex-shrink-0 text-primary"
																/>
																<div className="d-flex flex-column gap-2">
																	<span>
																		{
																			item.title
																		}
																	</span>
																	<div
																		className="lh-md text-gray-3"
																		style={{
																			whiteSpace:
																				'pre-wrap'
																		}}
																		dangerouslySetInnerHTML={{
																			__html: item?.description as string
																		}}
																	/>
																</div>
															</div>
														</SwiperSlide>
													)
												)}
											</Swiper>
											{/* <div className="d-flex gap-3 flex-wrap mb-3">
											{subscriptionPlanDetails?.features?.map(
												(item, index) => {
													return (
														<div key={index}>
															<Col
																className="d-flex align-items-start gap-2 mt-3 h-100"
																style={{
																	width: '300px',
																	borderRadius:
																		'8px',
																	padding:
																		'8px 16px',
																	background:
																		'#F2F2F4'
																}}>
																<FontAwesome
																	icon={
																		item.icon
																	}
																	size="2x"
																	className="flex-shrink-0 text-primary"
																/>
																<div className="d-flex flex-column gap-2">
																	<span>
																		{
																			item.title
																		}
																	</span>
																	<div
																		className="lh-md text-gray-3"
																		style={{
																			whiteSpace:
																				'pre-wrap'
																		}}
																		dangerouslySetInnerHTML={{
																			__html: item?.description as string
																		}}
																	/>
																</div>
															</Col>
														</div>
													);
												}
											)}
										</div> */}
										</div>
									)}
							</Row>
						</div>
					)}
				</div>
			</div>
			<ModalBoxChangeImage
				name="image"
				title="title.change-media"
				isOpen={openModal}
				image={image?.url || ''}
				mediaType={image?.mediaType}
				withVideo
				onlyView
				showButtonUpload={image?.url ? false : true}
				aspectRatio={AspectRatio['16/9']}
				onClose={toggleModal}
			/>
		</ModalBox>
	);
};
