import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDelayUnmount, useModal } from 'Hooks';
import { AgentProfile, Colors } from 'Types';
import { Button, ModalBoxSwitchAgent, ShimmerImage, Badge, FontAwesome } from 'Elements';
import styles from 'partials/card/invite-business-partner-card.module.scss';

interface Props {
	card: AgentProfile;
	onSelectAgent: () => void;
}

export const ChooseAgentToSwitchCard: FC<Props> = memo(
	({ card, onSelectAgent }) => {
		const { t } = useTranslation();
		const defaultLogo = '/assets/img/agent-default.jpg';
		const [openModal, toggleModal] = useModal();
		const shouldRenderModal = useDelayUnmount(openModal, 350);


		return (
			<div className={styles.card}>
				<div className={styles.logoBox}>
					<ShimmerImage
						src={card?.agentSetting?.logo}
						width={50}
						height={50}
						alt={card.agentName}
						defaultImage={defaultLogo}
						className={styles.logo}
					/>
				</div>
				<div className="d-flex align-items-center gap-3 flex-grow-1">
					<div className="w-100">
						<div className='d-flex align-items-center gap-2'>
							<h2 className="fw-500 fs-sm lh-base mb-0 text-break">
								{card.agentName}
							</h2>
							{card?.commissionGroup?.IsPremium && (
								<Badge
									color={Colors.premium}
									className="gap-2">
									<FontAwesome
										icon={'medal'}
										size="lg"
									/>
									{card?.commissionGroup.label}
								</Badge>
							)}
						</div>
						{card.mainRegion?.name && (
							<div className="d-flex align-items-center text-gray-3 mt-1 fs-small">
								{card.mainRegion.name}
							</div>
						)}
						<div className='mt-2'>
							{!card?.companyApprovalStatus &&
								<Badge color={Colors["warning"]}>
									<FontAwesome
										icon={"info-circle"}
										size="1x"
									/>
									<span className='ms-1'>
										{t('badge.no-approval')}
									</span>
								</Badge>}
						</div>
					</div>
					<Button
						size="sm"
						color={Colors['white-primary']}
						className="flex-shrink-0"
						label={t('button.choose-community-partner') as string}
						onClick={toggleModal}
					/>
				</div>
				{shouldRenderModal && (
					<ModalBoxSwitchAgent
						isOpen={openModal}
						onClose={toggleModal}
						selectedAgent={card}
						onSelectAgent={onSelectAgent}
					/>
				)}
			</div>
		);
	}
);

ChooseAgentToSwitchCard.displayName = 'ChooseAgentToSwitchCard';
