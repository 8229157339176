import { useMemo } from 'react';
import { JobKind } from 'src/types/Job';

const useGetJobKind = (generalUsage) => {
	const getJobCapacity = useMemo(() => {
		return (kind: JobKind) => {
			if (generalUsage) {
				return generalUsage.find((job) => job.kind === kind);
			}
		};
	}, [generalUsage]);
	return { getJobCapacity };
};

export default useGetJobKind;
