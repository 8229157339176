import './styles/globals.scss';
import { memo, useState } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { MsalProvider } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import { store } from './redux/store';
import { authInstance } from 'Utils';
import './lang/i18n';
import './assets/css/fontawesome.css';
import './assets/css/brands.css';
import './assets/css/duotone.css';
import { ScrollRestoration } from 'react-router-dom';

const AppDOM = memo(() => {
	const [queryClient] = useState(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: {
						retry: 0,
						retryDelay: 5000,
						refetchOnWindowFocus: false
					}
				}
			})
	);
	return (
		<>
			<MsalProvider instance={authInstance}>
				<Provider store={store}>
					<QueryClientProvider client={queryClient}>
						<App />
					</QueryClientProvider>
				</Provider>
			</MsalProvider>
		</>
	);
});

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(<AppDOM />);
AppDOM.displayName = 'AppDOM';

// {/* </StrictMode> */}
// // <StrictMode>
