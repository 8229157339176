import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useSearchParams } from 'react-router-dom';
import { useAxios, useBusinessPartnerMe } from 'Hooks';
import { LoadingSpinner, PaymentFailed, PaymentSuccess } from 'Elements';
import styles from 'partials/payment/payment-result.module.scss';
import { api } from 'Utils';

export const PaymentResult = () => {
	const [searchParams] = useSearchParams();
	const [paymentSuccess, setPaymentSuccess] = useState(false);
	const { sendRequest, isLoading } = useAxios<any>();
	const status = searchParams.get('status') as string;
	const subscriptionPaymentId = searchParams.get(
		'SubscriptionPaymentId'
	) as string;
	const paymentMethod = searchParams.get('paymentMethod') as string;

	const { data: businessPartnerMe, isLoading: isLoadingProfile } =
		useBusinessPartnerMe({
			enabled: status === '1'
		});

	useEffect(() => {
		if (status === '1' && !isLoadingProfile) {
			checkPay();
		}
	}, [isLoadingProfile]);

	const checkPay = async () => {
		await sendRequest(
			api.subscriptionPay(subscriptionPaymentId),
			{},
			() => {
				setPaymentSuccess(true);
			}
		);
	};

	return (
		<div className={styles.wrapper}>
			<Container>
				<Row className="justify-content-center">
					<Col md={9} xl={7} xxl={5}>
						{status === '1' ? (
							paymentSuccess ? (
								<PaymentSuccess />
							) : (
								<div
									className={
										paymentMethod === '5'
											? ''
											: styles.container
									}>
									{isLoading && (
										<LoadingSpinner className="mt-5" />
									)}
								</div>
							)
						) : status === '0' ? (
							<div className={styles.container}>
								<PaymentFailed />
							</div>
						) : (
							<LoadingSpinner />
						)}
					</Col>
				</Row>
			</Container>
		</div>
	);
};
