import {
	Activity,
	ActivityId,
	Address,
	ApplicantDemand,
	CompanySetting,
	JobType,
	Sector
} from 'Types';

export enum UpdateJobActionTypes {
	UPDATE_JOB = 'UPDATE_JOB'
}

export enum JobStatisticReportRange {
	Monthly = 0,
	SixMonth = 1,
	Yearly = 2
}

export enum JobKind {
	Job = 0,
	Internship = 1
}

export enum JobStatusEnum {
	Draft = 0,
	Published = 1,
	Unpublished = 2,
	Expired = 3
}

export interface Job {
	auditInfo: AuditInfo;
	code: string;
	id: string;
	tenantId?: string;
	jobStatus: number;
	kind: number;
	partnerName: string;
	jobPeriods?: internshipPeriods[];
	expiredDate: string | undefined | Date;
	startDate: string | undefined | Date;
	partnerType?: number;
	publishedVersion: number | null;
	slugUrl: string;
	title: string;
	publishedBy?: string;
	publishedAt?: string;
	featuredPhoto: { url: string; thumbnail: string };
	featuredVideo: { url: string; thumbnail: string; mimeType: string };
	version: number;
	shortDescription: string;
	description: string;
	benefitsText: string;
	requirementsText: string;
	responsibilitiesText: string;
	showBenefitsText: boolean;
	showRequirementsText: boolean;
	showResponsibilitiesText: boolean;
	showDescription: boolean;
	contactPerson: JobContactPerson | null;
	jobTags: JobTagsOption[] | null;
	jobActivities: string[] | null;
}

export interface internshipPeriods {
	id?: string;
	title: string;
	from: string;
	to: string;
	startTime?: string;
	endTime?: string;
	includeTimeRange: boolean;
}

export interface Translate {
	language: string;
	value: string;
}
export interface AuditInfo {
	createdBy: string;
	createdAt: string;
	updatedBy: string;
	updatedAt: string;
}

export interface JobTagCategories {
	id: string;
	partitionKey: string;
	title: string;
	description: string;
	icon: string;
	order: number;
	translateTitles: Translate[];
	translateDescriptions: Translate[];
	auditInfo: AuditInfo;
	isActive: boolean;
}

export interface ActivityTag {
	id: string;
	partitionKey: string;
	title: string;
	code: string;
	translateTitles: Translate;
	auditInfo: AuditInfo;
	isActive: boolean;
}

export interface JobTags {
	id: string;
	jobTagCategoryId: string;
	partitionKey: string;
	title: string;
	description: string;
	translateTitles: Translate[];
	translateDescriptions: Translate[];
	auditInfo: AuditInfo;
	isActive: boolean;
}

export interface PublishedInfo {
	downloadUrl: string;
	slugUrl: string;
	jobVersion: number;
	publishedVersion: number;
	publishJobStatus: number | null;
}

export interface JobStatus {
	statusTypes: number;
	createdDate: string;
	createdUser: string;
	userName: string;
}

export interface ApplicantJob {
	id: string;
	jobId: string;
	code: string;
	shortDescription: string;
	title: string;
	description: string;
	benefitsText: string;
	requirementsText: string;
	responsibilitiesText: string;
	companyId: string;
	sectorId: string;
	sector: Sector;
	jobTypeId: string;
	jobType: JobType;
	activityList: ActivityId[];
	activityLists: Activity[];
	applicantDemand: ApplicantDemand;
	company: Partial<CompanySetting>;
}

export interface JobFormGeneral {
	code: string;
	title: string;
	kind?: number;
	jobStatus?: number;
	shortDescription: string;
	startDate: string | undefined | Date;
	expiredDate: string | undefined | Date;
	isActive: boolean;
	featuredVideo?: { url: string; thumbnail: string; mimeType: string };
	hasStartDate?: boolean;
	hasExpireDate?: boolean;
}

export interface JobFormFeatured {
	featuredPhoto: { url: string; thumbnail: string };
	featuredVideo: { url: string; thumbnail: string; mimeType: string };
}

export interface JobContactPerson {
	fullName: string;
	emailAddress: string;
	phoneNumber: string;
	avatar: { url: string; thumbnail: string };
	visibleInPublic: boolean;
}

export interface JobTagsOption {
	jobTagId: string;
	order: number;
	title?: string;
}

export interface JobFormInfo {
	jobTags: JobTagsOption[] | null;
	jobActivities: string[] | null;
}

export interface JobVisibilitySetting {
	jobVisibilities: {
		subscriptionId: string;
		communityPartnerId: string;
	}[];
}

export interface JobFormDescription {
	description: string;
	benefitsText: string;
	requirementsText: string;
	responsibilitiesText: string;
	showDescription: boolean;
	showBenefitsText: boolean;
	showRequirementsText: boolean;
	showResponsibilitiesText: boolean;
}

export interface JobInternshipType {
	title: string;
	from: string;
	to: string;
	includeTimeRange: boolean;
	startTime?: string;
	endTime?: string;
}

export type JobFormInitialData = JobFormGeneral &
	JobFormInfo &
	JobFormFeatured & {
		jobPeriods: JobInternshipType[];
	} & JobFormDescription & {
		contactPerson: JobContactPerson;
	} & JobVisibilitySetting & {
		allowPublish: boolean;
	};

export interface JobForm {
	step0: { kind: number };
	step1: JobFormGeneral;
	step2: JobFormFeatured;
	step3: JobFormInfo;
	step4: JobFormDescription;
	step5: JobInternshipType[];
	step6: JobVisibilitySetting;
}

export interface JobFormData {
	id?: string;
	auditInfo?: AuditInfo;
	code: string;
	title: string;
	jobPeriods?: JobInternshipType[];
	kind: number;
	jobStatus?: number;
	shortDescription: string;
	partnerName: string;

	primaryJobLocation: {
		regionCode: number;
		title: string;
		locationPoint: {
			longitude: number;
			latitude: number;
		};
	};
	isActive: boolean;
	jobTags: JobTagsOption[];
	featuredPhoto?: {
		url: string;
		thumbnail: string;
	} | null;
	featuredVideo?: {
		url: string;
		thumbnail: string;
		mimeType: string;
	} | null;
	publishedVersion?: number;
	version?: number;
	startDate: string | undefined | Date;
	expiredDate: string | undefined | Date;
	partnerType?: number;
	jobActivities: any[];
	description: string;
	benefitsText: string;
	requirementsText: string;
	responsibilitiesText: string;
	contactPerson: JobContactPerson | null;
	showBenefitsText: boolean;
	showRequirementsText: boolean;
	showResponsibilitiesText: boolean;
	showDescription: boolean;
	jobVisibilities?: {
		subscriptionId: string;
		communityPartnerId: string;
	}[];
}

export interface JobStatistic {
	id: string;
	draft: number;
	autoPublished: number;
	published: number;
	expired: number;
	unpublished: number;
	companyId: string;
	createdAt: string;
}

export interface TotalJobStatistic {
	id: string;
	totalDraft: number;
	totalAutoPublished: number;
	totalPublished: number;
	totalExpired: number;
	totalUnpublished: number;
	companyId: string;
	createdAt: string;
}

export interface JobUsage {
	kind: JobKind;
	usage: string;
	capacity: number;
	count: number;
	subscriptionId: string;
}
