import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SubscriptionDetails } from 'Types';
import { Card, LoadingContent } from 'Elements';
import { clx, currencyFormatter, getDiscountFormat } from 'Utils';
import styles from 'partials/card/subscription-card.module.scss';

interface Props {
	subscription: SubscriptionDetails;
	subscriptionPayments: any;
	isLoading: boolean;
}

const CardBox = styled(Card)`
	min-height: 286px;
`;

export const SubscriptionInvoice: FC<Props> = ({
	isLoading,
	subscriptionPayments
}) => {
	const { t } = useTranslation();
	const row_class = 'justify-content-between';
	const discountType: any =
		subscriptionPayments?.price?.discountAmount !== 0
			? 'Amount'
			: 'Percent';

	return (
		<CardBox className="h-100">
			<h6 className="text-gray-3 mb-3 fs-6 fw-400 d-flex align-items-center">
				{t('title.invoice')}
				{/* <Badge
					color={getStatusData()?.color ?? Colors.muted}
					className="ms-auto">
					{t(`${getStatusData()?.label}`)}
				</Badge> */}
			</h6>
			<div className={clx(styles.row, row_class)}>
				<span className={styles.rowLabel}>{t('label.net-price')}:</span>
				<span>
					{currencyFormatter(
						subscriptionPayments?.price?.subscriptionPrice ?? 0
					)}
				</span>
			</div>
			{subscriptionPayments?.price?.voucherCode && (
				<div className={clx(styles.row, row_class)}>
					<span className={styles.rowLabel}>
						{t('label.voucher-code')}:
					</span>
					<span>
						{subscriptionPayments?.price?.voucherCode ?? '____'}
					</span>
				</div>
			)}
			{subscriptionPayments?.price?.discountAmount > 0 && (
				<div className={clx(styles.row, row_class)}>
					<div className={clx(styles.rowLabel, 'd-flex flex-column')}>
						<div className="d-flex align-items-center">
							{t('label.discount')}
							<span className="ms-1 text-gray-3 fs-small">
								(
								{getDiscountFormat(
									subscriptionPayments?.price?.discountAmount,
									discountType
								)}
								)
							</span>
							:
						</div>
					</div>
					<span>
						{subscriptionPayments?.price?.discountAmount > 0
							? `- ${currencyFormatter(
									subscriptionPayments?.price
										?.discountAmount ?? 0
							  )}`
							: currencyFormatter(0)}
					</span>
				</div>
			)}
			<div className={clx(styles.row, row_class)}>
				<span className={styles.rowLabel}>{t('label.subtotal')}:</span>
				<span>
					{currencyFormatter(
						subscriptionPayments?.price?.subTotal ?? 0
					)}
				</span>
			</div>
			<div className={clx(styles.row, row_class)}>
				<span className={styles.rowLabel}>
					{t('label.tax')}
					<span className="ms-1 text-gray-3 fs-small">
						({subscriptionPayments?.price?.taxPercent ?? 0}
						%)
					</span>
					:
				</span>
				<span>
					{subscriptionPayments?.price?.taxAmount > 0
						? `+ ${currencyFormatter(
								subscriptionPayments?.price?.taxAmount ?? 0
						  )}`
						: currencyFormatter(0)}
				</span>
			</div>
			<div className={clx(styles.row, row_class)}>
				<span className={clx(styles.rowLabel, 'fw-500')}>
					{t('label.total')}
				</span>
				<div className="d-flex flex-column text-end">
					<span className="fs-6 fw-500">
						{currencyFormatter(
							subscriptionPayments?.price?.totalAmount ?? 0
						)}
					</span>
					<span className="text-gray-3 lh-base fs-small">
						{t('label.tax-included')}
					</span>
				</div>
			</div>
			{isLoading && <LoadingContent />}
		</CardBox>
	);
};
