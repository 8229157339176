import { Button, FontAwesome } from 'src/containers/Elements';
import { FC, useState } from 'react';
import { api } from 'src/helpers/API';
import { useAxios } from 'src/hooks/useAxios';
import { config } from 'src/helpers/Config';
import { Colors } from 'src/types/Color';
import styles from 'partials/step/step-container.module.scss';
import { useTranslation } from 'react-i18next';
import { clx } from 'src/helpers/Utils';

import Drawer from 'react-modern-drawer';

//import styles 👇
import 'react-modern-drawer/dist/index.css';
import { usePaymentMethodList } from 'src/hooks/useBusinessPartner';
import { useSelector } from 'src/hooks/useRedux';

interface Props {
	isOpen?: boolean;
	subscriptionId?: string;
	paymentMethodIdentifier?: string;
	toggleDrawer?: () => void;
	refetch: () => void;
}

export const PaymentsMethodDrawer: FC<Props> = ({
	isOpen,
	subscriptionId,
	paymentMethodIdentifier,
	toggleDrawer,
	refetch
}) => {
	const { t } = useTranslation();
	const { assetConfig } = useSelector((state) => state.marketing);
	const { data, isLoading } = usePaymentMethodList();
	const [disabled, setDisabled] = useState(false);
	const { sendRequest } = useAxios();

	const onClickAddPayment = async () => {
		const returnUrl: any = `${config.redirectUrl}subscriptions/${subscriptionId}/assigned-payment-method?showDrawer=true`;
		setDisabled(true);
		await sendRequest(
			api.addPaymentMethod,
			{ data: { returnUrl: returnUrl } },
			async (data: any) => {
				window.location = data;
			}
		).finally(() => {
			setDisabled(false);
		});
	};

	const onClickChoosePayment = async (id) => {
		setDisabled(true);
		await sendRequest(
			api.choosePaymentMethodSubscription(
				subscriptionId,
				id,
				assetConfig?.providerCustomerId
			),
			{ method: 'put' },
			async () => {
				setDisabled(false);
				refetch();
				toggleDrawer && toggleDrawer();
			}
		).finally(() => {
			setDisabled(false);
		});
	};

	return (
		<Drawer
			open={isOpen as never}
			onClose={() => {
				toggleDrawer && toggleDrawer();
			}}
			lockBackgroundScroll
			zIndex={100}
			direction="right"
			className="d-flex flex-column gap-3"
			size="34rem">
			<div className="d-flex justify-content-between align-items-center px-4 py-4 border-bottom border-gray-1">
				<h2 className="fs-5 fw-600 ">
					{t('text.select-payment-method-to-assign')}
				</h2>
				<Button
					size="sm"
					color={Colors['white-gray']}
					className="gap-2"
					onClick={() => {
						toggleDrawer && toggleDrawer();
					}}>
					<FontAwesome icon="close" />
					{t('button.close')}
				</Button>
			</div>
			<div className="d-flex flex-column gap-3 px-4">
				<div className="border-bottom border-gray-1">
					<p className="fs-sm fw-400">
						{t('text.description-payment-method-to-assign')}
					</p>
				</div>
				{!isLoading &&
					data?.map((item) => {
						return (
							paymentMethodIdentifier !==
								item.paymentMethodId && (
								<div
									onClick={() => {
										if (!disabled) {
											onClickChoosePayment(
												item.paymentMethodId
											);
										}
									}}
									key={item.paymentMethodId}
									className={clx(
										'w-100',
										disabled
											? styles.disabledCardPayment
											: styles.cardPayment
									)}>
									<div className="d-flex align-items-center justify-content-between">
										<div>
											<div className="d-flex align-items-center gap-2">
												<FontAwesome
													icon={'credit-card'}
													className={
														disabled
															? 'text-gray-2'
															: 'text-primary'
													}
													size="1x"
													swapOpacity
												/>
												<span
													className={`fs-5 fw-500 ${
														disabled
															? 'text-gray-2'
															: 'text-gray-4'
													}`}>
													{`${
														item.brand
															? item.brand
															: ''
													} ${item.title}
												${item.last4 ? `•••• ${item.last4}` : ''}
												`}
												</span>
											</div>
											<div className="mt-2">
												{item.expYear &&
													item.expYear !== '0' && (
														<span
															className={`fw-400 fs-sm 	${
																disabled
																	? 'text-gray-2'
																	: 'text-gray-3'
															} mx-3`}>
															{`Expired ${item.expMonth}/${item.expYear}`}
														</span>
													)}
												{item.expYear === '0' && (
													<span className="fw-400 fs-sm text-gray-3 mx-3">
														{item.email}
													</span>
												)}
											</div>
										</div>
									</div>
								</div>
							)
						);
					})}
				{isLoading &&
					[1, 2, 3].map((item) => {
						return (
							<div
								key={item}
								className={styles.cardPaymentSkeleton}></div>
						);
					})}
				{!isLoading && data?.length !== 0 && (
					<div
						className={clx(
							'w-100',
							disabled
								? styles.disabledCardPayment
								: styles.addCardPayment
						)}
						onClick={onClickAddPayment}>
						<div className="d-flex align-items-center gap-2">
							<FontAwesome
								icon="plus"
								className={
									disabled ? 'text-gray-2' : 'text-primary'
								}
								size="1x"
								swapOpacity
							/>
							<span
								className={`fs-5 fw-500  ${
									disabled ? 'text-gray-2' : 'text-primary'
								}`}>
								{t('button.add-payment-method')}
							</span>
						</div>
						<div className="mt-2">
							<span
								className={`fw-400 fs-sm mx-3 ${
									disabled ? 'text-gray-2' : 'text-gray-3'
								}`}>
								{t('button.add-new-payment-method')}
							</span>
						</div>
					</div>
				)}
				{isLoading && (
					<div className={styles.cardPaymentSkeleton}></div>
				)}
				{!isLoading && data?.length === 0 && (
					<div className="mx-auto flex flex-column justify-content-center align-items-center">
						<div className="text-center mb-3">
							<FontAwesome
								icon="credit-card"
								size="3x"
								className="text-primary"
							/>
						</div>
						<div className="text-center mb-3">
							<h2 className="fs-6 fw-500">
								{t('button.no-payment-method')}
							</h2>
						</div>
						<div className="text-center">
							<p className="fs-small fw-400">
								{t('text.you-have-no-payment-method-yet')}:
							</p>
						</div>
						<div className="text-center">
							<Button
								onClick={() => {
									if (!disabled) {
										onClickAddPayment();
									}
								}}
								className="gap-2"
								color={
									disabled ? Colors['gray-2'] : Colors.primary
								}>
								<FontAwesome icon="add" size="1x" />
								{t('button.add-payment-method')}
							</Button>
						</div>
					</div>
				)}
			</div>
		</Drawer>
	);
};
