import { memo, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { AspectRatio, Logo, Media, Member } from 'Types';
import { useDelayUnmount, useModal, usePrompt, useUpdateMember } from 'Hooks';
import {
	Card,
	SubmitButtonGroup,
	SwitchController,
	AvatarController,
	LoadingContent,
	ModalBoxDelete
} from 'Elements';

export const UpdateMember = () => {
	const descriptionLimit = 270;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { member_id } = useParams();
	const [image, setImage] = useState({ url: '', thumbnail: '' });
	const [openModal, toggleModal] = useModal();
	const [isRemoving, setIsRemoving] = useState(false);
	const {
		data: fields,
		isFetching,
		updateMember,
		removeMember
	} = useUpdateMember(member_id as string);
	const shouldRenderModal = useDelayUnmount(openModal, 350);

	const schema = yup.object().shape({
		fullName: yup.string().required(t('validation.fullName.required'))
	});

	const {
		reset,
		watch,
		register,
		control,
		handleSubmit,
		setValue,
		formState: { errors, isDirty, isSubmitting }
	} = useForm<Partial<Member>>({
		resolver: yupResolver(schema)
	});

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const handleRemoveImage = async () => {
		setImage({
			url: '',
			thumbnail: ''
		});
		setValue(
			'image',
			{
				url: '',
				thumbnail: ''
			},
			{ shouldDirty: true }
		);
	};

	const handleRemoveUser = async () => {
		try {
			setIsRemoving(true);
			await removeMember(() => {
				setIsRemoving(false);
				navigate('/members');
			});
		} finally {
			setIsRemoving(false);
		}
	};

	const handleUploadImage = async (image_data: Media) => {
		if (image_data) {
			setImage({
				url: image_data.url,
				thumbnail: image_data.thumbnailUrl
			});
			setValue(
				'image',
				{
					url: image_data.url,
					thumbnail: image_data.thumbnailUrl
				},
				{ shouldDirty: true }
			);
		}
	};

	const onSubmit: SubmitHandler<Partial<Member>> = async (data) => {
		await updateMember(data);
	};

	useEffect(() => {
		reset({
			...fields,
			description: fields?.description.substring(0, descriptionLimit)
		});
		setImage(fields?.image as Logo);
	}, [reset, fields]);

	return (
		<Card>
			<Row>
				<Col sm={5} xl={4}>
					<FormGroup>
						<AvatarController
							name="image"
							aspectRatio={AspectRatio['3/4']}
							control={control}
							image={image?.url}
							onRemove={handleRemoveImage}
							onUpload={handleUploadImage}
						/>
					</FormGroup>
				</Col>
				<Col sm={7} xl={8}>
					<form onSubmit={handleSubmit(onSubmit)} noValidate>
						<Row>
							<Col xl={9} xxl={7}>
								<FormGroup>
									<Label htmlFor="fullName">
										{t('forms.fullName')}
										<small className="ms-1">
											({t('validation.required')})
										</small>
									</Label>
									<input
										{...register('fullName')}
										type="text"
										id="fullName"
										aria-invalid={!!errors.fullName}
										className="inputbox w-100"
									/>
									{errors.fullName && (
										<div className="invalid-feedback d-block">
											{errors.fullName.message}
										</div>
									)}
								</FormGroup>
								<FormGroup>
									<Label htmlFor="email">
										{t('forms.email')}
									</Label>
									<input
										{...register('email')}
										type="email"
										id="email"
										className="inputbox w-100"
									/>
								</FormGroup>
								<FormGroup>
									<Label htmlFor="position">
										{t('forms.position')}
									</Label>
									<input
										{...register('jobPosition')}
										type="text"
										id="jobPosition"
										className="inputbox w-100"
									/>
								</FormGroup>
								<FormGroup>
									<Label htmlFor="phoneNumber">
										{t('forms.phone-number')}
									</Label>
									<input
										{...register('phoneNumber')}
										type="tel"
										inputMode="tel"
										id="phoneNumber"
										className="inputbox w-100"
									/>
								</FormGroup>
								<FormGroup className="position-relative">
									<Label htmlFor="description">
										{t('forms.agent.member-desc')}
									</Label>
									<textarea
										{...register('description', {
											maxLength: descriptionLimit
										})}
										id="description"
										className="inputbox w-100"
										cols={10}
										rows={5}
										aria-invalid={!!errors.description}
										maxLength={descriptionLimit}
									/>
									<div className="position-absolute end-0">
										<small className="ms-auto mt-1">
											{watch('description')?.length || 0}/
											{descriptionLimit}
										</small>
									</div>
								</FormGroup>
								<FormGroup>
									<SwitchController
										name="isActive"
										control={control}
										label="forms.show-in-home"
									/>
								</FormGroup>
								<SubmitButtonGroup
									isDisable={!isDirty}
									isSubmitting={isSubmitting}
									removeTitle="button.remove"
									onRemove={toggleModal}
								/>
							</Col>
						</Row>
					</form>
				</Col>
			</Row>
			{shouldRenderModal && (
				<ModalBoxDelete
					isOpen={openModal}
					onClose={toggleModal}
					onRemove={handleRemoveUser}
					isRemoving={isRemoving}
					title={fields?.fullName ?? ''}
				/>
			)}
			{isFetching && <LoadingContent />}
		</Card>
	);
};
