import { lazy } from 'react';

export const ErrorPage = lazy(() => import('../components/pages/ErrorPage'));
export const Dashboard = lazy(() => import('../components/pages/Dashboard'));

// Company Pages
export const MyBusinessPartner = lazy(
	() => import('../components/pages/business-partner/MyBusinessPartner')
);
export const MyBusinessPartnerProfilePage = lazy(
	() =>
		import(
			'../components/pages/business-partner/MyBusinessPartnerProfilePage'
		)
);
export const MyBusinessPartnerPreviewProfilePage = lazy(
	() =>
		import(
			'../components/pages/business-partner/MyBusinessPartnerPreviewProfilePage'
		)
);

// Agent Pages
export const MyCommunityPartner = lazy(
	() => import('../components/pages/community-partner/MyCommunityPartner')
);
export const MyCommunityPartnerProfilePage = lazy(
	() =>
		import(
			'../components/pages/community-partner/MyCommunityPartnerProfilePage'
		)
);
export const MyCommunityPartnerPreviewProfilePage = lazy(
	() =>
		import(
			'../components/pages/community-partner/MyCommunityPartnerPreviewProfilePage'
		)
);
export const PartnerList = lazy(
	() => import('../components/pages/community-partner/partners/PartnerList')
);

// Employee Pages
export const EmployeeCreate = lazy(
	() => import('../components/pages/business-partner/employee/EmployeeCreate')
);
export const EmployeeList = lazy(
	() => import('../components/pages/business-partner/employee/EmployeeList')
);
export const EmployeeUpdate = lazy(
	() => import('../components/pages/business-partner/employee/EmployeeUpdate')
);

// Agent Member Pages
export const MemberCreate = lazy(
	() => import('../components/pages/community-partner/member/MemberCreate')
);
export const MemberList = lazy(
	() => import('../components/pages/community-partner/member/MemberList')
);
export const MemberUpdate = lazy(
	() => import('../components/pages/community-partner/member/MemberUpdate')
);

// Agent Department Pages
export const DepartmentCreate = lazy(
	() =>
		import(
			'../components/pages/community-partner/department/DepartmentCreate'
		)
);
export const DepartmentList = lazy(
	() =>
		import(
			'../components/pages/community-partner/department/DepartmentList'
		)
);
export const DepartmentUpdate = lazy(
	() =>
		import(
			'../components/pages/community-partner/department/DepartmentUpdate'
		)
);

// Job Pages
export const JobList = lazy(
	() => import('../components/pages/jobs/job/JobList')
);
export const JobCreate = lazy(
	() => import('../components/pages/jobs/job/JobCreate')
);
export const JobUpdate = lazy(
	() => import('../components/pages/jobs/job/JobUpdate')
);
export * from '../components/pages/jobs/job/JobPreviewPage';

// Internship Pages
export const InternshipList = lazy(
	() => import('../components/pages/jobs/internship/InternshipList')
);
export const InternshipCreate = lazy(
	() => import('../components/pages/jobs/internship/InternshipCreate')
);
export const InternshipUpdate = lazy(
	() => import('../components/pages/jobs/internship/InternshipUpdate')
);
export const InternshipPreviewPage = lazy(
	() => import('../components/pages/jobs/internship/InternshipPreviewPage')
);

// Internship Management Pages
export const InternshipManagementList = lazy(
	() =>
		import(
			'../components/pages/jobs/internship-management/InternshipManagementList'
		)
);
// export const JobPreviewPage = lazy(
// 	() => import('../components/pages/jobs/job/JobPreviewPage')
// );

// Requests Pages
export const RequestList = lazy(
	() => import('../components/pages/jobs/requests/RequestList')
);
export const RequestDetails = lazy(
	() => import('../components/pages/jobs/requests/RequestDetails')
);

// Internship Participants Pages
export const InternshipParticipantsList = lazy(
	() =>
		import(
			'../components/pages/jobs/internship-participants/InternshipParticipantsList'
		)
);
export const InternshipParticipantsDetails = lazy(
	() =>
		import(
			'../components/pages/jobs/internship-participants/InternshipParticipantsDetails'
		)
);

// User Pages
export const UserCreate = lazy(
	() => import('../components/pages/users/profile/UserCreate')
);
export const UserList = lazy(
	() => import('../components/pages/users/profile/UserList')
);
export const UserUpdate = lazy(
	() => import('../components/pages/users/profile/UserUpdate')
);
export const UserRoleCreate = lazy(
	() => import('../components/pages/users/role/UserRoleCreate')
);
export const UserRoleList = lazy(
	() => import('../components/pages/users/role/UserRoleList')
);
export const UserRoleUpdate = lazy(
	() => import('../components/pages/users/role/UserRoleUpdate')
);
export const UserGroupCreate = lazy(
	() => import('../components/pages/users/group/UserGroupCreate')
);
export const UserGroupList = lazy(
	() => import('../components/pages/users/group/UserGroupList')
);
export const UserGroupUpdate = lazy(
	() => import('../components/pages/users/group/UserGroupUpdate')
);

// Subscription Pages
export const SubscriptionCreate = lazy(
	() =>
		import(
			'../components/pages/business-partner/subscription/SubscriptionCreate'
		)
);
export const SubscriptionList = lazy(
	() =>
		import(
			'../components/pages/business-partner/subscription/SubscriptionList'
		)
);
export const PaymentMethodList = lazy(
	() =>
		import(
			'../components/pages/business-partner/payment-methods/PaymentMethodList'
		)
);
export const SubscriptionDetails = lazy(
	() =>
		import(
			'../components/pages/business-partner/subscription/SubscriptionDetails'
		)
);
export const SubscriptionCheckout = lazy(
	() =>
		import(
			'../components/pages/business-partner/subscription/SubscriptionCheckout'
		)
);
export const SubscriptionPaymentResult = lazy(
	() =>
		import(
			'../components/pages/business-partner/subscription/SubscriptionPaymentResult'
		)
);
export const SubscriptionPaymentInvoice = lazy(
	() =>
		import(
			'../components/pages/business-partner/subscription/SubscriptionPaymentInvoice'
		)
);

// Settlements Pages
export const TransactionList = lazy(
	() => import('../components/pages/settlements/transactions/TransactionList')
);
export const TransactionDetails = lazy(
	() =>
		import(
			'../components/pages/settlements/transactions/TransactionDetails'
		)
);
export const InvoiceList = lazy(
	() => import('../components/pages/settlements/invoice/InvoiceList')
);
export const InvoiceDetails = lazy(
	() => import('../components/pages/settlements/invoice/InvoiceDetails')
);
export const CreditList = lazy(
	() => import('../components/pages/settlements/credit/CreditList')
);
export const CreditDetails = lazy(
	() => import('../components/pages/settlements/credit/CreditDetails')
);
export const PaymentList = lazy(
	() => import('../components/pages/settlements/payment/PaymentList')
);
export const PaymentDetails = lazy(
	() => import('../components/pages/settlements/payment/PaymentDetails')
);

// Voucher Pages
export const VoucherList = lazy(
	() => import('../components/pages/community-partner/voucher/VoucherList')
);
export const VoucherDetails = lazy(
	() => import('../components/pages/community-partner/voucher/VoucherDetails')
);

// Invitation Pages
export const InvitationList = lazy(
	() =>
		import(
			'../components/pages/community-partner/invitation/InvitationList'
		)
);
export const InvitationCreate = lazy(
	() =>
		import(
			'../components/pages/community-partner/invitation/InvitationCreate'
		)
);
export const InvitationUpdate = lazy(
	() =>
		import(
			'../components/pages/community-partner/invitation/InvitationUpdate'
		)
);

// Event Pages
export const EventList = lazy(
	() => import('../components/pages/event/EventList')
);
export const EventDetails = lazy(
	() => import('../components/pages/event/EventDetails')
);
export const EventCreate = lazy(
	() => import('../components/pages/community-partner/event/EventCreate')
);
export const EventUpdate = lazy(
	() => import('../components/pages/community-partner/event/EventUpdate')
);
export const MyEvents = lazy(
	() => import('../components/pages/business-partner/event/MyEvents')
);
export const MyEventDetails = lazy(
	() => import('../components/pages/business-partner/event/MyEventDetails')
);

// Marketing Pages
export const ToolboxWelcome = lazy(
	() => import('../components/pages/marketing/toolbox/ToolboxWelcome')
);
export const Integration = lazy(
	() => import('../components/pages/marketing/integration/Integration')
);

// Integration App Pages
export const IntegrationApps = lazy(
	() =>
		import(
			'../components/pages/business-partner/integration/IntegrationUpdate'
		)
);

// community partner Subscription Plans  Pages
export const SubscriptionPlansList = lazy(
	() =>
		import(
			'../components/pages/community-partner/subscription-plans/SubscriptionPlansList'
		)
);
export const SubscriptionPlansCreate = lazy(
	() =>
		import(
			'../components/pages/community-partner/subscription-plans/SubscriptionPlansCreate'
		)
);
export const SubscriptionPlansUpdate = lazy(
	() =>
		import(
			'../components/pages/community-partner/subscription-plans/SubscriptionPlansUpdate'
		)
);

// Contact Groups Pages
export const ListContactGroups = lazy(
	() =>
		import(
			'../components/pages/community-partner/contactGroup/ListContactGroups'
		)
);
export const ContactGroupCreate = lazy(
	() =>
		import(
			'../components/pages/community-partner/contactGroup/ContactGroupCreate'
		)
);
export const ContactGroupUpdate = lazy(
	() =>
		import(
			'../components/pages/community-partner/contactGroup/ContactGroupUpdate'
		)
);

// Extern Partners Pages
export const ListExternPartners = lazy(
	() =>
		import(
			'../components/pages/community-partner/partners/extern-partner/ListExternPartners'
		)
);
export const ExternPartnerCreate = lazy(
	() =>
		import(
			'../components/pages/community-partner/partners/extern-partner/ExternPartnerCreate'
		)
);
export const ExternPartnerUpdate = lazy(
	() =>
		import(
			'../components/pages/community-partner/partners/extern-partner/ExternPartnerUpdate'
		)
);

// Abo Calculator
export const AboCalculator = lazy(
	() =>
		import(
			'../components/pages/community-partner/abo-calculator/AboCalculator'
		)
);
