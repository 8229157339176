import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	BusinessPartnerProfileStatus,
	ColorValues,
	Colors,
	CommunityPartnerProfileStatus,
	ModalProps
} from 'Types';
import { useAxios, useUpdateCommunityPartner } from 'Hooks';
import {
	Button,
	ModalBox,
	ModalBoxFooter,
	SubmitButton,
	FontAwesome
} from 'Elements';
import { api, toastify } from 'Utils';
import { useNavigate } from 'react-router-dom';

interface Props extends ModalProps {
	type: 'publish' | 'unPublish';
	profileId?: string;
	status?: number;
	userType: 'communityPartner' | 'businessPartner';
	refetch?: () => void;
	refetchPartnerMe?: () => void;
}
export const ModalBoxPublish: FC<Props> = ({
	isOpen,
	type,
	userType,
	status,
	onClose,
	profileId,
	refetch,
	refetchPartnerMe
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const {
		sendRequest: sendPublishBusinessPartnerProfile,
		isLoading: isSubmittingPublishBusinessPartnerProfile
	} = useAxios<string>();

	const {
		sendRequest: sendPublishCommunityPartnerProfile,
		isLoading: isSubmittingPublishCommunityPartnerProfile
	} = useAxios<string>();

	const { handleSubmit } = useForm();

	const onPublish = async () => {
		if (userType === 'businessPartner') {
			if (localStorage.getItem('showPm') === 'false') {
				type === 'unPublish'
					? await sendPublishBusinessPartnerProfile(
							api.unpublishBusinessPartnerProfile(
								profileId as string
							),
							{
								data: {}
							}
					  ).then(() => {
							refetch && refetch();
							refetchPartnerMe && refetchPartnerMe();
							toastify('toastify.business-partner-updated', {
								type: 'success'
							});
							onClose && onClose();
					  })
					: await sendPublishBusinessPartnerProfile(
							api.publishBusinessPartnerProfile(
								profileId as string
							),
							{
								data: {}
							}
					  ).then(() => {
							refetch && refetch();
							refetchPartnerMe && refetchPartnerMe();
							toastify('toastify.business-partner-updated', {
								type: 'success'
							});
							onClose && onClose();
					  });
			} else {
				navigate('/subscriptions');
			}
		} else {
			type === 'unPublish'
				? await sendPublishCommunityPartnerProfile(
						api.unpublishCommunityPartnerProfile,
						{
							data: {}
						}
				  ).then(() => {
						refetch && refetch();
						refetchPartnerMe && refetchPartnerMe();
						toastify('toastify.community-partner-updated', {
							type: 'success'
						});
						onClose && onClose();
				  })
				: await sendPublishCommunityPartnerProfile(
						api.publishCommunityPartnerProfile,
						{
							data: {}
						}
				  ).then(() => {
						refetch && refetch();
						refetchPartnerMe && refetchPartnerMe();
						toastify('toastify.community-partner-updated', {
							type: 'success'
						});
						onClose && onClose();
				  });
		}
	};

	return (
		<ModalBox size="lg" isOpen={isOpen} onClose={onClose}>
			<form onSubmit={handleSubmit(onPublish)}>
				<header className="d-flex flex-column align-items-center text-center">
					{userType === 'businessPartner' ? (
						localStorage.getItem('showPm') === 'false' ? (
							<>
								<FontAwesome
									icon={
										type === 'publish'
											? 'building-circle-check'
											: 'triangle-exclamation'
									}
									size="8x"
									color={
										type === 'publish'
											? ColorValues.green
											: ColorValues.red
									}
									className="mb-4"
								/>
								<h4 className="h6 lh-base">
									{t(
										type === 'publish'
											? `text.are-you-sure-to-publish-your-${userType}`
											: `text.are-you-sure-to-unPublish-your-${userType}`
									)}
								</h4>
								<p className="text-gray-3 lh-md">
									{t(
										type === 'publish'
											? `text.${userType}-publish-sub-text`
											: `text.${userType}-unPublish-sub-text`
									)}
								</p>
							</>
						) : (
							<>
								<FontAwesome
									icon={'lock'}
									size="2x"
									className="mb-4"
								/>
								<h4 className="h6 lh-base">
									{t(`text.you-can-not-publish-this-session`)}
								</h4>
								<p className="text-gray-3 lh-md">
									{t(`text.not-publish-sub-text`)}
								</p>
							</>
						)
					) : (
						status === BusinessPartnerProfileStatus.Active && (
							<>
								<FontAwesome
									icon={
										type === 'publish'
											? 'building-circle-check'
											: 'triangle-exclamation'
									}
									size="8x"
									color={
										type === 'publish'
											? ColorValues.green
											: ColorValues.red
									}
									className="mb-4"
								/>
								<h4 className="h6 lh-base">
									{t(
										type === 'publish'
											? `text.are-you-sure-to-publish-your-${userType}`
											: `text.are-you-sure-to-unPublish-your-${userType}`
									)}
								</h4>
								<p className="text-gray-3 lh-md">
									{t(
										type === 'publish'
											? `text.${userType}-publish-sub-text`
											: `text.${userType}-unPublish-sub-text`
									)}
								</p>
							</>
						)
					)}
				</header>
				<ModalBoxFooter className="justify-content-between pt-3">
					<Button color={Colors['white-gray']} onClick={onClose}>
						{t('button.cancel')}
					</Button>
					<SubmitButton
						icon={
							userType === 'businessPartner' &&
							localStorage.getItem('showPm') === 'true'
								? ''
								: type === 'publish' && 'upload'
						}
						color={
							userType === 'businessPartner' &&
							localStorage.getItem('showPm') === 'true'
								? 'white-primary'
								: type === 'publish'
								? 'white-primary'
								: 'white-red'
						}
						isSubmitting={
							isSubmittingPublishBusinessPartnerProfile ||
							isSubmittingPublishCommunityPartnerProfile
						}
						label={
							userType === 'businessPartner' &&
							localStorage.getItem('showPm') === 'true'
								? 'button.start-registration-process'
								: type === 'publish'
								? 'button.publish'
								: 'button.unPublish'
						}
						savingLabel={
							userType === 'businessPartner' &&
							localStorage.getItem('showPm') === 'true'
								? 'button.start-registration-process'
								: type === 'publish'
								? 'button.publishing'
								: 'button.unPublishing'
						}
					/>
				</ModalBoxFooter>
			</form>
		</ModalBox>
	);
};
