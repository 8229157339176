import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { fetch } from 'Utils';
import { queryKey } from 'Constants';
import {
	AgentPaymentTransaction,
	AgentTransactionDetails,
	Credit,
	CreditDetails,
	DataList,
	DataQueryParams,
	Payment,
	PaymentList,
	Transaction,
	TransactionDetails
} from 'Types';

const config = (options = {}) => {
	return {
		...options,
		keepPreviousData: false
	};
};

export const useCommunityPartnerCredits = <T = DataList<Credit>>(
	{
		communityPartnerCreditStatus = '',
		pageSize = 10,
		pageNumber = 1,
		keyword = ''
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<Credit>>
): UseQueryResult<DataList<Credit>, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.credits,
			{ communityPartnerCreditStatus, pageSize, pageNumber, keyword }
		],
		queryFn: () =>
			fetch.communityPartnerCreditList(
				communityPartnerCreditStatus,
				pageSize,
				pageNumber,
				keyword
			),
		...config(options)
	});
};

export const useCommunityPartnerPayments = <T = DataList<PaymentList>>(
	{
		subscriptionId = '',
		pageSize = 10,
		pageNumber = 1,
		keyword = '',
		status,
		isInvoicedPayment
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<PaymentList>>
): UseQueryResult<DataList<PaymentList>, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.paymentsList,
			{
				subscriptionId,
				pageSize,
				pageNumber,
				keyword,
				status,
				isInvoicedPayment
			}
		],
		queryFn: () =>
			fetch.communityPartnerPaymentList(
				subscriptionId,
				pageSize,
				pageNumber,
				keyword,
				status,
				isInvoicedPayment
			),
		...config(options)
	});
};

export const useCompanyTransactions = <T = DataList<Transaction>>(
	{
		status = '',
		type = '',
		pageSize = 10,
		pageNumber = 1,
		keyword = ''
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<Transaction>>
): UseQueryResult<DataList<Transaction>, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.transactions,
			{ status, type, pageSize, pageNumber, keyword }
		],
		queryFn: () =>
			fetch.companyTransactionList(
				status,
				type,
				pageSize,
				pageNumber,
				keyword
			),
		...config(options)
	});
};

export const useTransaction = <T = TransactionDetails>(
	transactionId: string,
	options?: UseQueryOptions<T, any, TransactionDetails>
): UseQueryResult<TransactionDetails, unknown> => {
	return useQuery({
		queryKey: [queryKey.transaction, { transactionId }],
		queryFn: () => fetch.transactionDetails(transactionId),
		...config(options)
	});
};

export const useCommunityPartnerCredit = <T = CreditDetails>(
	creditId: string,
	options?: UseQueryOptions<T, any, CreditDetails>
): UseQueryResult<CreditDetails, unknown> => {
	return useQuery({
		queryKey: [queryKey.credit, { creditId }],
		queryFn: () => fetch.communityPartnerCreditDetails(creditId),
		...config(options)
	});
};

export const useAgentPaymentTransaction = <T = AgentPaymentTransaction[]>(
	transactionId: string,
	options?: UseQueryOptions<T, any, AgentPaymentTransaction[]>
): UseQueryResult<AgentPaymentTransaction[], unknown> => {
	return useQuery({
		queryKey: [queryKey.paymentTransaction, { transactionId }],
		queryFn: () => fetch.agentPaymentTransactionDetails(transactionId),
		...config(options)
	});
};
