import {
	Subscription,
	CommunityPartnerBankAccount,
	Voucher,
	VoucherDiscountType,
	SubscriptionDetails
} from 'Types';

export enum TransactionType {
	'payment' = 0,
	'credit' = 1,
	'invoice' = 2,
	'sell' = 3
}

export enum CreditStatus {
	Pending = 0,
	Paid = 1,
	Failed = 2,
	InProcess = 3,
	Canceled = 4
}

export enum TransactionStatus {
	Pending = 0,
	Paid = 1,
	Failed = 2,
	InProcess = 3,
	Canceled = 4
}

export interface Transaction {
	id: string;
	transactionCode: string;
	customerCode: string;
	performAt: string;
	currency: string;
	amount: number;
	taxPercent: number;
	totalAmount: number;
	taxAmount: number;
	donate: boolean;
	downloadUrl: string;
	taxIncluded: boolean;
	type: TransactionType;
	status: TransactionStatus;
	createdAt: string;
	agent: TransactionAgent;
	company: TransactionCompany;
	productInfo: ProductInfo;
	payment: TransactionPayment;
}

export interface Credit {
	id: string;
	creditNumber: string;
	communityPartnerId: string;
	totalCreditAmount: number;
	includedTax: boolean;
	taxAmount: number;
	taxPercent: number;
	createdAt: string;
	creditStatus: number;
	isSmallCompany: boolean;
	activeAboCount: number;
	communityPartnerInfo: {
		communityPartnerId: string;
		organizationName: string;
		emailAddress: string;
		thumbnailLogo: number;
	};
	subscriptionInfo: {
		subscriptionId: string;
		businessPartnerId: string;
		startDate: string;
		endDate: string;
	};
	subscriptionPaymentReason: number;
	businessPartnerInfo: {
		businessPartnerId: string;
		firstName: string;
		lastName: string;
		organizationName: string;
		emailAddress: string;
		thumbnailLogo: number;
	};
}
export interface PaymentList {
	id: string;
	subscriptionId: string;
	businessPartnerId: string;
	planName: string;
	communityPartnerId: string;
	communityPartnerCreditId: string;
	createdAt: string;
	lastUpdatedAt: string;
	status: number;
	reason: number;
	provider: {
		paymentReference: string;
		paymentProvider: string;
		providerStatus: string;
		description: string;
		paymentMethodInfo: {
			paymentMethodIdentifier: string;
			type: string;
			brand: string;
			last4: string;
			expMonth: string;
			expYear: string;
			email: string;
		};
	};
	price: {
		subscriptionPrice: number;
		totalAmount: number;
		taxAmount: number;
		taxPercent: number;
		discountAmount: number;
		discountPercent: number;
		subscriptionDuration: number;
		subTotal: number;
		voucherCode: string;
	};
	invoiceInfo: {
		firstName: string;
		lastName: string;
		organizationName: string;
		emailAddress: string;
		thumbnailLogo: string;
		address: {
			name: string;
			countryName: string;
			countryIso2: string;
			countryIso3: string;
			state: string;
			city: string;
			postalCode: string;
			street: string;
			houseNumber: string;
			location: {
				longitude: number;
				latitude: number;
			};
		};
		invoiceNumber: string;
		invoiceDocumentUrl: string;
	};
}

export interface CreditDetails {
	id: string;
	communityPartnerId: string;
	subscriptionPaymentId: string;
	creditNumber: string;
	creditStatus: number;
	totalCreditAmount: number;
	includedTax: boolean;
	taxAmount: number;
	taxPercent: number;
	isSmallCompany: boolean;
	createdAt: string;
	updatedAt: string;
	subscriptionPlanInfo: {
		subscriptionPlanId: {
			value: string;
		};
		subscriptionPlanCode: string;
		price: number;
		name: string;
		type: number;
		durationDays: number;
		taxPercentage: number;
	};
	commissionGroupInfo: {
		commissionGroupId: {
			value: string;
		};
		title: string;
		isActive: boolean;
		isPremium: boolean;
	};
	commissionLevelInfo: {
		commissionLevelId: {
			value: string;
		};
		title: string;
		minActiveAbo: number;
		maxActiveAbo: number;
		commissionPercent: number;
		commissionCost: number;
	};
	activeAboCount: number;
	communityPartnerInfo: {
		communityPartnerId: {
			value: string;
		};
		organizationName: string;
		emailAddress: string;
		thumbnailLogo: string;
	};
	subscriptionInfo: {
		subscriptionId: {
			value: string;
		};
		businessPartnerId: {
			value: string;
		};
		startDate: string;
		endDate: string;
	};
	subscriptionPaymentReason: number;
	businessPartnerInfo: {
		businessPartnerId: {
			value: string;
		};
		firstName: string;
		lastName: string;
		organizationName: string;
		emailAddress: string;
		thumbnailLogo: string;
	};
}
export interface TransactionDetails {
	id: string;
	transactionCode: string;
	customerCode: string;
	performAt: string;
	currency: string;
	amount: number;
	taxPercent: number;
	total: number;
	totalAmount: number;
	taxAmount: number;
	discountAmount: number;
	discountType: VoucherDiscountType;
	donate: boolean;
	taxIncluded: boolean;
	discount: number;
	downloadUrl: string;
	subTotal: number;
	type: TransactionType;
	status: TransactionStatus;
	createdAt: string;
	typeId: string;
	tenantId: string;
	companySubscription: SubscriptionDetails;
	agent: TransactionCustomer;
	company: TransactionCustomer;
	relatedTransaction: string;
	relatedTransactionCode: string;
	productInfo: ProductInfo;
	payment: TransactionPayment;
	applyVoucher: Voucher;
	invoiceNumber: string;
}

export interface AgentTransactionDetails {
	id: string;
	transactionCode: string;
	customerCode: string;
	performAt: string;
	currency: string;
	taxNumber: string;
	smallCompany: boolean;
	taxIncluded: boolean;
	amount: number;
	taxPercent: number;
	total: number;
	totalAmount: number;
	taxAmount: number;
	donate: boolean;
	discountAmount: number;
	discount: number;
	subTotal: number;
	type: TransactionType;
	status: TransactionStatus;
	createdAt: string;
	typeId: string;
	tenantId: string;
	companySubscription: Subscription;
	agent: TransactionCustomer;
	company: TransactionCustomer;
	relatedTransaction: string;
	relatedTransactionCode: string;
	productInfo: ProductInfo;
	payment: TransactionPayment;
	applyVoucher: Voucher;
	bankAccount: CommunityPartnerBankAccount;
	creditNumber: string;
}

export interface TransactionCustomer {
	id: string;
	name: string;
	email: string;
	logo: string;
	type: string;
}

export interface TransactionAgent {
	agentId: string;
	name: string;
	agentEmail: string;
}

export interface TransactionCompany {
	companyId: string;
	name: string;
	companyEmail: string;
}

export interface TransactionPayment {
	id: string;
	paymentCode: string;
	payUrl: string;
	paymentProvider: string;
	currency: string;
	amount: number;
	status: string;
	createdAt: string;
	paymentMethod: string;
	returnUrl: string;
	codingSystemId: string;
}

export interface AgentPaymentTransaction {
	id: string;
	name: string;
	email: string;
	logo: string;
	branch: string;
	totalAmount: number;
	planTitle: string;
	purchaseDate: string;
	type: TransactionType;
	status: TransactionStatus;
	transactionId: string;
	transactionCode: string;
	invoiceNumber: string;
	taxIncluded: string;
	donate: boolean;
}

export interface ProductInfo {
	code: string;
	name: string;
	type: string;
	idNumber: string;
}
