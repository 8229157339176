import { Fragment, Suspense, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { RouterProvider, ScrollRestoration } from 'react-router-dom';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { withTranslation } from 'react-i18next';
import { routes, loginRequest } from 'Utils';
import { useGetB2bUserProfileQuery } from 'Hooks';
import { LoadingAuthentication, LoadingContent } from 'Elements';

const App = () => {
	const { inProgress } = useMsal();
	const { data } = useGetB2bUserProfileQuery();

	useEffect(() => {
		if (data?.partnerType) {
			let userRole = '';
			if (data?.partnerType === 2) {
				userRole = 'businessPartner';
			}
			if (data?.partnerType === 1) {
				userRole = 'communityPartner';
			}

			userRole && localStorage.setItem('userRole', userRole);
		}
	}, [data]);

	return (
		<Fragment>
			{inProgress !== InteractionStatus.Startup &&
				inProgress !== InteractionStatus.HandleRedirect && (
					<MsalAuthenticationTemplate
						interactionType={InteractionType.Redirect}
						authenticationRequest={loginRequest}
						loadingComponent={LoadingAuthentication}>
						<RouterProvider router={routes} />
					</MsalAuthenticationTemplate>
				)}
			<ToastContainer newestOnTop limit={1} icon={false} />
		</Fragment>
	);
};

export default withTranslation()(App);
