import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { SubmitHandler } from 'react-hook-form/dist/types';
import { usePrompt } from 'Hooks';
import { Colors, internshipPeriods, JobInternshipType } from 'Types';
import styles from 'partials/step/step-container.module.scss';
import {
	Button,
	FontAwesome,
	RadioItem,
	StepNavigation
} from 'src/containers/Elements';

import { Col, Row } from 'reactstrap';
import CardInternship from '../CardInternship';

interface Props {
	fields: JobInternshipType[];
	onPrev: () => void;
	onNext: (data: JobInternshipType[]) => void;
}

export const CreateJobInternshipType: FC<Props> = ({
	fields,
	onPrev,
	onNext
}) => {
	const { t } = useTranslation();
	const [radioInternship, setRadioInternship] = useState('1');
	const [listPeriod, setListPeriod] = useState<internshipPeriods[]>([
		{
			title: '',
			from: '',
			to: '',
			includeTimeRange: false
		}
	]);
	const {
		handleSubmit,
		formState: { isDirty }
	} = useForm<JobInternshipType[]>({
		defaultValues: fields
	});

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const onSubmit: SubmitHandler<JobInternshipType[]> = () => {
		if (radioInternship === '2') {
			const allValuesFilled = listPeriod.every((obj) =>
				Object.values(obj).every(
					(value) =>
						value !== null && value !== undefined && value !== ''
				)
			);

			if (allValuesFilled) {
				onNext?.(listPeriod);
			}
		} else {
			onNext?.(listPeriod);
		}
	};

	const onChangeHandler = (index, key, value) => {
		const listNew = [...listPeriod];
		if (key === 'from') {
			listNew[index]['to'] = '';
		}
		if (key === 'includeTimeRange' && value) {
			listNew[index]['startTime'] = '';
			listNew[index]['endTime'] = '';
		} else if (key === 'includeTimeRange' && !value) {
			delete listNew[index]['startTime'];
			delete listNew[index]['endTime'];
		}
		listNew[index][key] = value;
		setListPeriod(listNew);
	};

	useEffect(() => {
		if (fields.length !== 0 && fields[0]?.title !== '') {
			setRadioInternship('2');
			setListPeriod(fields);
		}
	}, [fields]);

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className={styles.form}
			noValidate>
			<Row>
				<Col>
					<div className="w-100">
						<div className="d-flex flex-column gap-3">
							<span className="fs-sm fw-400">
								{t('title.internship.internship-type')}
							</span>
							<p className="fs-small fw-400">
								{t(
									'text.please-select-one-of-the-following-options-in-order'
								)}
							</p>
						</div>
						<div className="mt-2 d-flex flex-column gap-3 border-bottom border-gray-1 pb-4">
							<RadioItem
								id="internship1"
								name="internship"
								value="1"
								label={t('text.free-to-apply-internship')}
								checked={radioInternship === '1'}
								onChange={() => {
									setListPeriod([
										{
											title: '',
											from: '',
											to: '',
											includeTimeRange: false
										}
									]);
									setRadioInternship('1');
								}}
							/>
							<div className="d-flex align-items-start gap-3">
								<FontAwesome icon="circle-info" size="1x" />
								<span>
									{t('text.massage-free-to-apply-internship')}
								</span>
							</div>
						</div>
						<div className="mt-3 d-flex flex-column gap-3 ">
							<RadioItem
								id="internship2"
								name="internship"
								value="1"
								label={t(
									'text.with-defined-appointment-internship'
								)}
								checked={radioInternship === '2'}
								onChange={() => setRadioInternship('2')}
							/>
							<div className="d-flex align-items-start gap-3">
								<FontAwesome icon="circle-info" size="1x" />
								<span>
									{t(
										'text.massage-with-defined-appointment-internship'
									)}
								</span>
							</div>
						</div>
					</div>
				</Col>
			</Row>
			{radioInternship === '2' && (
				<Row className="mt-5">
					<Col sm={6} className="d-flex flex-column gap-3">
						<CardInternship
							listPeriod={listPeriod}
							setListPeriod={setListPeriod}
							onChangeHandler={onChangeHandler}
						/>
						<Button
							type="button"
							className="w-45 gap-2"
							color={Colors['white-primary']}
							onClick={() => {
								setListPeriod([
									...listPeriod,
									{
										title: '',
										from: '',
										to: '',
										includeTimeRange: false
									}
								]);
							}}>
							<FontAwesome size="sm" icon="add" />
							{t('button.new-date-period')}
						</Button>
					</Col>
				</Row>
			)}
			<div className="mt-6">
				<StepNavigation onPrev={onPrev} />
			</div>
		</form>
	);
};
