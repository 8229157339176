import { useState } from 'react';
import { Row } from 'reactstrap';
import { useSearchParams } from 'react-router-dom';
import { useDepartments } from 'Hooks';
import {
	DepartmentCard,
	DepartmentCardLoading,
	ListingPage,
	NoData,
	Pagination,
	SystemErrorAlert
} from 'Elements';

export const ListDepartment = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';

	const [state, setState] = useState({
		pageSize: 12,
		currentPage: pageParam,
		keyword: queryParam
	});

	const { data, isLoading, isFetching, isError } = useDepartments({
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword
	});

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	return (
		<ListingPage
			pageSizes={[4, 8, 12]}
			totalItems={data?.totalItems}
			selectedPageSize={state.pageSize}
			onPageSizeChange={onChangePageSize}
			onSearchChange={handleSearch}>
			{!isFetching && (
				<Row>
					{data?.items?.map((card) => {
						return <DepartmentCard key={card.id} card={card} />;
					})}
				</Row>
			)}
			{!isFetching && data?.totalItems === 0 && (
				<NoData message="title.no-department" />
			)}
			{isFetching && <DepartmentCardLoading />}
			{isError && <SystemErrorAlert />}
			{!isLoading && data && data?.totalPages > 1 && (
				<Pagination
					totalItemsCount={data?.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
		</ListingPage>
	);
};
