import { Button } from 'containers/Elements';
import { Colors, IBadgeStatus } from 'containers/Types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ResponsiveSize } from 'src/types/GeoLocation';

interface Props {
	selectedFilter: any;
	filters: IBadgeStatus[];
	onChange: (filter: IBadgeStatus) => void;
}

const PageBadgeFilter: FC<Props> = ({ filters, selectedFilter, onChange }) => {
	const { t } = useTranslation();
	return (
		<div className="d-flex justify-content-start align-items-start gap-3 w-100 ">
			{filters.map((filter: IBadgeStatus) => {
				return (
					<Button
						onClick={() => onChange(filter)}
						color={
							selectedFilter == filter.title
								? Colors.primary
								: Colors['white-gray']
						}
						key={filter.value}
						size={ResponsiveSize.sm}>
						{t(filter.label)}
					</Button>
				);
			})}
		</div>
	);
};

export default PageBadgeFilter;
