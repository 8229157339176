import { FC, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Label, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form/dist/types';
import {
	Button,
	FontAwesome,
	ModalBoxActivityTags,
	ModalBoxJobTags,
	StepNavigation
} from 'Elements';
import { useActivityTags, useJobTags, useModal, usePrompt } from 'Hooks';
import { Colors, JobFormInfo, JobTagsOption } from 'Types';
import styles from 'partials/step/step-container.module.scss';
import { getCurrentLocale } from 'src/helpers/Utils';

interface Props {
	fields: JobFormInfo;
	onPrev: () => void;
	onNext: (data: JobFormInfo) => void;
}

export const CreateJobInfo: FC<Props> = ({ fields, onNext, onPrev }) => {
	const { t } = useTranslation();
	const [openModalJobTags, toggleModalJobTags] = useModal();
	const [openModalActivityTags, toggleModalActivityTags] = useModal();
	const [selectedJobTags, setSelectedJobTags] = useState<any>(
		fields?.jobTags ?? []
	);
	const [selectedActivityTags, setSelectedActivityTags] = useState<any>(
		fields?.jobActivities ?? []
	);

	const { data: dataJobsTags } = useJobTags({
		pageSize: 500,
		pageNumber: 1,
		keyword: '',
		jobTagCategoryId: '',
		noAssignedCategory: false,
		isActive:""
	});

	const { data: dataActivityTags } = useActivityTags({
		pageSize: 500,
		pageNumber: 1,
		keyword: '',
		isActive:""
	});

	const schema = yup.object().shape({
		jobTags: yup
			.array()
			.nullable()
			.required(t('validation.select-job-type.required')),
		jobActivities: yup
			.array()
			.nullable()
			.required(t('validation.select-activity.required'))
	});

	const {
		handleSubmit,
		setValue,
		formState: { errors, isDirty }
	} = useForm<JobFormInfo>({
		resolver: yupResolver(schema)
	});

	const valueConfig = {
		shouldDirty: true,
		shouldValidate: true
	};

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const onSubmit: SubmitHandler<JobFormInfo> = (data) => {
		onNext?.(data);
	};

	const onClickJobTags = (selected: JobTagsOption[]) => {
		setValue(
			'jobTags',
			selected.length !== 0 ? selected : null,
			valueConfig
		);
		setSelectedJobTags(selected);
	};

	const onClickActivityTags = (selected: string[]) => {
		setSelectedActivityTags(selected);
		setValue(
			'jobActivities',
			selected.length !== 0 ? selected : null,
			valueConfig
		);
	};

	useEffect(() => {
		setValue(
			'jobActivities',
			selectedActivityTags.length !== 0 ? selectedActivityTags : null
		);
		setValue(
			'jobTags',
			selectedJobTags.length !== 0 ? selectedJobTags : null
		);
	}, [selectedActivityTags, selectedJobTags]);

	const getTitle = (id: any, array: any) => {
		let title = '';
		array?.items?.forEach((element) => {
			if (id === element.id) {
				title = element.title;
				element.translateTitles.find((item) => {
					if (
						item?.language.toLowerCase() ===
						getCurrentLocale().code.toLowerCase()
					) {
						title = item.value
							? item.value
							: element.translateTitles[0].value ?? element.title;
					}
				});
				if (title === '') {
					title = element.title;
				}
			}
		});
		return title;
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className={styles.form}
			noValidate>
			<Row>
				<Col xxl={8}>
					<div className="d-flex flex-column gap-1 mb-2">
						<div className="d-flex align-items-center justify-content-between">
							<Label htmlFor="jobTagsId">
								{t('forms.job-tags')}
								<small className="ms-1">
									({t('validation.required')})
								</small>
							</Label>
							<Button
								color={Colors['white-primary']}
								className="mt-3 gap-2"
								onClick={toggleModalJobTags}>
								<FontAwesome icon="edit" size="lg" />
							</Button>
						</div>
						<div
							className={`border border-1 border-gray-2 py-4 px-3 mt-2 ${styles.roundedMd}`}>
							{selectedJobTags.length === 0 && (
								<div className="d-flex flex-column gap-2">
									<span className="text-primary fs-sm fw-500">
										{t('text.no-tags-added')}
									</span>
									<p className="text-gray-3 fs-sm fw-400">
										{t(
											'text.add-job-tags-describe-your-job-position-better'
										)}
									</p>
								</div>
							)}
							{selectedJobTags.length > 0 && (
								<div className="d-flex flex-wrap align-items-center gap-2">
									{selectedJobTags?.map((item) => {
										return (
											<div
												key={item.jobTagId}
												className={`d-flex align-items-center gap-2  px-3 `}
												style={{
													height: '41px',
													borderRadius: '8px',
													background:
														'linear-gradient(180deg, #EFEFF2 0%, #F8F8F9 100%)'
												}}>
												<span
													className={`fw-400 fs-base text-gray-3`}>
													{getTitle(
														item.jobTagId,
														dataJobsTags
													)}
												</span>
											</div>
										);
									})}
								</div>
							)}
						</div>
					</div>
					{errors.jobTags && (
						<div className="invalid-feedback d-block">
							{errors.jobTags.message}
						</div>
					)}
					<div className="d-flex flex-column gap-1">
						<div className="d-flex align-items-center justify-content-between">
							<Label htmlFor="activityTagsId">
								{t('forms.activity-tags')}
								<small className="ms-1">
									({t('validation.required')})
								</small>
							</Label>
							<Button
								color={Colors['white-primary']}
								className="mt-3 gap-2"
								onClick={toggleModalActivityTags}>
								<FontAwesome icon="edit" size="lg" />
							</Button>
						</div>
						<div
							className={`border border-1 border-gray-2 py-4 px-3 mt-2 ${styles.roundedMd}`}>
							{selectedActivityTags.length === 0 && (
								<div className="d-flex flex-column gap-2">
									<span className="text-primary fs-sm fw-500">
										{t('text.no-activity-added')}
									</span>
									<p className="text-gray-3 fs-sm fw-400">
										{t(
											'text.add-activity-tags-describe-your-job-position-better'
										)}
									</p>
								</div>
							)}
							{selectedActivityTags.length > 0 && (
								<div className="d-flex flex-wrap align-items-center gap-2">
									{selectedActivityTags?.map((item) => {
										return (
											<div
												key={item}
												className={`d-flex align-items-center gap-2  px-3 `}
												style={{
													height: '41px',
													borderRadius: '8px',
													background:
														'linear-gradient(180deg, #EFEFF2 0%, #F8F8F9 100%)'
												}}>
												<span
													className={`fw-400 fs-base text-gray-3`}>
													{getTitle(
														item,
														dataActivityTags
													)}
												</span>
											</div>
										);
									})}
								</div>
							)}
						</div>
					</div>
					{errors.jobActivities && (
						<div className="invalid-feedback d-block">
							{errors.jobActivities.message}
						</div>
					)}
					<StepNavigation onPrev={onPrev} />
				</Col>
			</Row>
			{openModalJobTags && (
				<ModalBoxJobTags
					isOpen={openModalJobTags}
					onClose={toggleModalJobTags}
					selectedJobTags={selectedJobTags}
					onClickJobTags={onClickJobTags}
				/>
			)}
			{openModalActivityTags && (
				<ModalBoxActivityTags
					isOpen={openModalActivityTags}
					onClose={toggleModalActivityTags}
					selectedActivityTags={selectedActivityTags}
					onClickActivityTags={onClickActivityTags}
				/>
			)}
		</form>
	);
};
