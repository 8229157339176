import { FC } from 'react';
import { Container } from 'reactstrap';
import { clx } from 'Utils';

import styles from 'partials/homepage/home-header-preview.module.scss';
import classes from 'partials/shared/header.module.scss';
import { IconLogo } from 'src/containers/Elements';
import { useTranslation } from 'react-i18next';

interface Props {}

export const HomepagePreviewHeader: FC<Props> = () => {
	const { t } = useTranslation();

	return (
		<header className={styles.header}>
			<Container>
				<nav
					className={clx(styles.navbar)}>
					<div>
						<IconLogo fill="#000" className={classes.logo_image} />
					</div>
					{/* <div
						className={clx(
							styles.menuBox,
							'd-flex align-items-center justify-content-center'
						)}>
						<span>{t('table.applicant')}</span>
						<span>{t('menu.agent')}</span>
						<span>{t('menu.business-partner')}</span>
						<span>{t('text.jobSearch')}</span>
						<span>{t('menu.internships')}</span>
						<span>{t('menu.event.key')}</span>
					</div> */}
				</nav>
			</Container>
		</header>
	);
};
