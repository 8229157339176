import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { UserCategory } from 'Types';
import styles from 'partials/job/preview/job-textbox-section.module.scss';

interface Props {
	title: string;
	content: string;
	className?: string;
	userType: UserCategory;
}

const StyledTitle = styled.h6<{ $userType: UserCategory }>`
	&:before {
		background-color: ${({ theme, $userType }) =>
			theme[$userType].primaryColor};
	}
`;

const Hr = styled.hr<{ $userType: UserCategory }>`
	background-color: ${({ theme, $userType }) =>
		theme[$userType].primaryColor};
`;

export const JobTextBoxSection: FC<Props> = ({
	title,
	className,
	content,
	userType
}) => {
	const { t } = useTranslation();

	return (
		<div className={className}>
			<StyledTitle
				$userType={userType}
				className={clx(
					styles.title,
					'fs-6 mb-3 d-flex gap-2 align-items-center'
				)}>
				<span className="flex-shrink-0">{t(title)}</span>
				<Hr
					$userType={userType}
					className="flex-grow-1 opacity-100 m-0"
				/>
			</StyledTitle>
			<div
				className={styles.content}
				dangerouslySetInnerHTML={{
					__html: content
				}}
			/>
		</div>
	);
};
