import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import type { SubmitHandler } from 'react-hook-form/dist/types';
import { names } from 'Constants';
import { UserFormData } from 'Types';
import { useCreateUser, usePrompt } from 'Hooks';
import {
	Card,
	DatePickerController,
	FormSuccess,
	SubmitButtonGroup
} from 'Elements';

export const CreateUser = () => {
	const { t } = useTranslation();
	const { users } = names;
	const { createUser } = useCreateUser();
	const [formSuccess, setFormSuccess] = useState(false);
	const required_message = 'validation.required';

	const schema = yup.object().shape(
		{
			emailAddress: yup
				.string()
				.email(t('validation.email.type'))
				.required(t('validation.email.required')),
			firstName: yup
				.string()
				.required(t('validation.firstName.required')),
			lastName: yup.string().required(t('validation.lastName.required')),
			dateOfBirth: yup
				.date()
				.nullable()
				.default(null)
				.notRequired()
				.transform((curr, orig) => (orig === '' ? null : curr))
				.when('dateOfBirth', {
					is: (value: string) => value?.length,
					then: yup.date().typeError(t('validation.date.type'))
				})
		},
		[['dateOfBirth', 'dateOfBirth']]
	);

	const defaultValues: Partial<UserFormData> = {
		firstName: '',
		lastName: '',
		emailAddress: '',
		dateOfBirth: '',
		gender: 0
	};

	const {
		reset,
		control,
		register,
		handleSubmit,
		getValues,
		setValue,
		formState: { errors, isDirty, isSubmitting }
	} = useForm<UserFormData>({
		resolver: yupResolver(schema)
	});

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const resetForm = () => {
		reset({
			...defaultValues,
			gender: 0
		});
	};

	const onSubmit: SubmitHandler<UserFormData> = async (data) => {
		await createUser(data, () => {
			setFormSuccess(true);
			resetForm();
		});
	};

	useEffect(() => {
		reset({ gender: "0" });
	}, [reset]);

	return (
		<Card>
			{!formSuccess && (
				<form onSubmit={handleSubmit(onSubmit)}>
					<Row>
						<Col md={8} xxl={6}>
							<FormGroup>
								<Label htmlFor="firstName">
									{t('forms.fname')}
									<small className="ms-1">
										({t(required_message)})
									</small>
								</Label>
								<input
									{...register('firstName')}
									type="text"
									id="firstName"
									aria-invalid={!!errors.firstName}
									className="inputbox w-100"
								/>
								{errors.firstName && (
									<div className="invalid-feedback d-block">
										{errors.firstName.message}
									</div>
								)}
							</FormGroup>
							<FormGroup>
								<Label htmlFor="lastName">
									{t('forms.lname')}
									<small className="ms-1">
										({t(required_message)})
									</small>
								</Label>
								<input
									{...register('lastName')}
									type="text"
									id="lastName"
									aria-invalid={!!errors.lastName}
									className="inputbox w-100"
								/>
								{errors.lastName && (
									<div className="invalid-feedback d-block">
										{errors.lastName.message}
									</div>
								)}
							</FormGroup>
							<FormGroup>
								<Label htmlFor="emailAddress">
									{t('forms.email')}
									<small className="ms-1">
										({t(required_message)})
									</small>
								</Label>
								<input
									{...register('emailAddress')}
									type="email"
									id="emailAddress"
									aria-invalid={!!errors.emailAddress}
									className="inputbox w-100"
									placeholder={t(
										'placeholder.email-as-username'
									)}
								/>
								{errors.emailAddress && (
									<div className="invalid-feedback d-block">
										{errors.emailAddress.message}
									</div>
								)}
							</FormGroup>
							<FormGroup>
								<Label htmlFor="dateOfBirth">
									{t('forms.date-of-birth')}
								</Label>
								<DatePickerController
									name="dateOfBirth"
									control={control}
									error={errors.dateOfBirth}
									placeholder={t('forms.select-date')}
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="mobileNumber">
									{t('forms.mobile-number')}
								</Label>
								<input
									{...register('mobileNumber')}
									type="tel"
									inputMode="tel"
									id="mobileNumber"
									className="inputbox w-100"
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="phoneNumber">
									{t('forms.phone-number')}
								</Label>
								<input
									{...register('phoneNumber')}
									type="tel"
									inputMode="tel"
									id="phoneNumber"
									className="inputbox w-100"
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="gender">
									{t('forms.gender.key')}
								</Label>
								<div className="d-flex align-items-center pt-2">
									<FormGroup check>
										<input
											{...register('gender')}
											type="radio"
											id="Male"
											value="1"
											aria-invalid={!!errors.gender}
											className="form-radio-input"
										/>
										<label
											htmlFor="Male"
											className="form-radio-label">
											{t('forms.gender.male')}
										</label>
									</FormGroup>
									<FormGroup check className="ms-3">
										<input
											{...register('gender')}
											type="radio"
											id="Female"
											value="2"
											className="form-radio-input"
											aria-invalid={!!errors.gender}
										/>
										<label
											htmlFor="Female"
											className="form-radio-label">
											{t('forms.gender.female')}
										</label>
									</FormGroup>
									<FormGroup check className="ms-3">
										<input
											{...register('gender')}
											type="radio"
											id="Unknown"
											value="0"
											className="form-radio-input"
											aria-invalid={!!errors.gender}
										/>
										<label
											htmlFor="Unknown"
											className="form-radio-label">
											{t('forms.gender.unknown')}
										</label>
									</FormGroup>
								</div>
							</FormGroup>
							{errors.gender && (
								<div className="invalid-feedback d-block">
									{errors.gender.message}
								</div>
							)}
							<SubmitButtonGroup
								isDisable={!isDirty}
								isSubmitting={isSubmitting}
							/>
						</Col>
					</Row>
				</form>
			)}
			{formSuccess && (
				<FormSuccess
					addButtonTitle="button.add-user"
					listTitle="button.user-list"
					successTitle="forms.user-created"
					className="align-self-center"
					listUrl={users.path}
					onClickAdd={() => {
						setFormSuccess(false);
						resetForm();
					}}
				/>
			)}
		</Card>
	);
};
