import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	useAxios,
	useModal,
	usePaymentMethodList,
	useSubscription
} from 'Hooks';
import { config } from 'src/helpers/Config';
import { api, currencyFormatter } from 'Utils';
import { Colors, SubscriptionCalculate, SubscriptionSate } from 'Types';
import { Button, FontAwesome, ModalBoxPaymentResult } from 'Elements';
import styles from 'partials/step/step-container.module.scss';

interface Props {
	fields: SubscriptionSate;
	onPrev: () => void;
	goToStep: (stepId: string) => void;
}

export const CreateSubscriptionPaymentMethod: FC<Props> = ({ fields }) => {
	const { t } = useTranslation();
	const { sendRequest: subscriptionPaymentsDetail } = useAxios<any>();
	const { sendRequest, isLoading } = useAxios<any>();
	const [searchParams, setSearchParams] = useSearchParams();
	const [loading, setLoading] = useState(true);
	const [subscriptionPaymentStatus, setSubscriptionPaymentStatus] =
		useState(-1);
	const [disabled, setDisabled] = useState(false);
	const [loadingLabel, setLoadingLabel] = useState(true);
	const [intervalDelay, setIntervalDelay] = useState(5000);
	// const [retryNumber, setRetryNumber] = useState(0);
	const [subscriptionCalculate, setSubscriptionCalculate] =
		useState<SubscriptionCalculate>();
	const [communityPartnerId, setCommunityPartnerId] = useState();
	const [currentSubscriptionPaymentId, setCurrentSubscriptionPaymentId] =
		useState('');
	const [subscriptionPlanId, setSubscriptionPlanId] = useState();
	const planType = searchParams.get('planType') || '';
	const showModal = searchParams.get('showModal') || 'false';
	const [openModal, toggleModal] = useModal();
	const subscriptionId =
		searchParams.get('SubscriptionId') ??
		fields?.selectedCommunityPartner?.id;

	const res: any = useSubscription(subscriptionId as string, {
		enabled: !!subscriptionId
	});

	const { data: paymentMethodList, isLoading: isLoadingPaymentMethodList } =
		usePaymentMethodList();

	useEffect(() => {
		setCommunityPartnerId(
			res?.data?.communityPartnerInfo?.communityPartnerId ??
				fields?.selectedCommunityPartner?.id
		);
		setCurrentSubscriptionPaymentId(
			res?.data?.currentSubscriptionPaymentId ??
				searchParams.get('subscriptionPaymentId')
		);
		setSubscriptionPlanId(
			res?.data?.subscriptionPlanInfo?.subscriptionPlanId ??
				fields?.selectedPlan?.id
		);
	}, [res]);

	useEffect(() => {
		if (communityPartnerId && subscriptionPlanId) {
			setLoadingLabel(true);
			setLoading(true);
			sendRequest(
				api.getSubscriptionCalculate(
					communityPartnerId as string,
					subscriptionPlanId as string,
					'',
					Number(planType) as number
				),
				{ method: 'GET' },
				(subscriptionCalculate) => {
					setSubscriptionCalculate(subscriptionCalculate);
					setLoadingLabel(false);
					setLoading(false);
				}
			);
		}
	}, [communityPartnerId, subscriptionPlanId]);

	const checkPayStatus = async () => {
		if (currentSubscriptionPaymentId) {
			await subscriptionPaymentsDetail(
				api.getSubscriptionPaymentsDetail(currentSubscriptionPaymentId),
				{ method: 'GET' },
				(data) => {
					if (data?.status) {
						setSubscriptionPaymentStatus(data?.status);
					}
				}
			);
		}
	};

	useEffect(() => {
		if (subscriptionPaymentStatus !== -1) {
			const intervalId = setInterval(() => {
				setIntervalDelay((prevDelay) => prevDelay + 2000);
				// setRetryNumber((prevDelay) => prevDelay + 1);
				checkPayStatus();
			}, intervalDelay);
			if (subscriptionPaymentStatus !== 3) {
				clearInterval(intervalId);
			}
			return () => clearInterval(intervalId);
		} else {
			checkPayStatus();
		}
	}, [
		subscriptionPaymentStatus,
		intervalDelay,
		currentSubscriptionPaymentId
	]);

	const onClickPayment = async (paymentMethodId) => {
		setDisabled(true);
		setSearchParams({
			showModal: 'true',
			step: 'step4',
			planType: planType,
			SubscriptionId: subscriptionId?.toString() as string
		});
		toggleModal();
		await sendRequest(
			api.subscriptionPay(currentSubscriptionPaymentId),
			{ data: { paymentMethodId: paymentMethodId } },
			async () => {
				setSubscriptionPaymentStatus(3);
				// checkPayStatus();
			}
		)
			.catch(() => {
				toggleModal();
			})
			.finally(() => {
				setDisabled(true);
			});
	};

	const onClickAddPayment = async () => {
		setDisabled(true);
		const returnUrl: any = `${config.redirectUrl}subscriptions/add?step=step4&SubscriptionId=${subscriptionId}&planType=${planType}&`;
		setDisabled(true);
		await sendRequest(
			api.addPaymentMethod,
			{ data: { returnUrl: returnUrl } },
			async (data: any) => {
				window.location = data;
				setDisabled(false);
			}
		);
	};

	return (
		<div className={styles.form}>
			<div className="d-flex flex-column px-3">
				<h2 className="fs-base fw-500 mb-4">
					{t('text.choose-your-payment-method')}
				</h2>
				<div className="d-flex flex-column gap-1">
					<span className="fs-sm fw-400">
						{t('text.total-price')}
					</span>
					<span className="fs-small fw-400">
						{t(
							'text.with-this-subscription-you-can-support-other-community-partners'
						)}
					</span>
				</div>
				<div className="my-4 pb-3 border-bottom">
					<div className="d-flex">
						<div className="d-flex flex-column text-end pe-3 me-3 border-end border-2 text-primary">
							{!loadingLabel && (
								<h5 className="h5 mb-0">
									{currencyFormatter(
										subscriptionCalculate?.totalAmount ?? 0
									)}
								</h5>
							)}
							{loadingLabel && (
								<div className={styles.labelSkeleton}></div>
							)}
							<span className="fw-300">
								{t('title.excluded-tax')}
							</span>
						</div>
						<div className="text-gray-3 text-start d-flex flex-column">
							<span className="fw-300 lh-base">
								{t('title.per')}
							</span>
							<span className="mt-auto fs-base">
								{`${
									subscriptionCalculate?.subscriptionDuration ??
									''
								} ${t('title.days')}`}
							</span>
						</div>
					</div>
				</div>
				<div className="d-flex flex-column gap-4">
					{!isLoadingPaymentMethodList &&
						paymentMethodList?.map((item) => {
							return (
								<div
									key={item.paymentMethodId}
									className={
										isLoading || disabled
											? styles.disabledCardPayment
											: styles.cardPayment
									}>
									<div className="d-flex align-items-center justify-content-between">
										<div className="d-flex flex-column gap-2">
											<div className="d-flex align-items-center gap-2">
												<FontAwesome
													icon={'credit-card'}
													className={
														isLoading || disabled
															? 'text-gray-2'
															: 'text-primary'
													}
													size="1x"
													swapOpacity
												/>
												<span
													className={`fs-5 fw-500 ${
														isLoading || disabled
															? 'text-gray-2'
															: 'text-gray-4'
													}`}>
													{`${item.title}  ${
														item.last4 ?? ''
													}`}
												</span>
											</div>
											<div className="mt-2">
												{item.expYear &&
													item.expYear !== '0' && (
														<span
															className={`fw-400 fs-sm 	${
																isLoading ||
																disabled
																	? 'text-gray-2'
																	: 'text-gray-3'
															} mx-3`}>
															{`Expired ${item.expMonth}/${item.expYear}`}
														</span>
													)}
												{item.expYear === '0' && (
													<span className="fw-400 fs-sm text-gray-3 mx-3">
														{item.email}
													</span>
												)}
											</div>
										</div>
										<div>
											<Button
												onClick={() => {
													if (
														!disabled &&
														!isLoading
													) {
														onClickPayment(
															item.paymentMethodId
														);
													}
												}}
												color={
													disabled || isLoading
														? Colors['gray-2']
														: Colors[
																'white-primary'
														  ]
												}>
												{t('button.pay-with-this')}
											</Button>
										</div>
									</div>
								</div>
							);
						})}
					{isLoadingPaymentMethodList &&
						[1, 2, 3].map((item) => {
							return (
								<div
									key={item}
									className={
										styles.cardPaymentSkeleton
									}></div>
							);
						})}
					{!loading && !isLoadingPaymentMethodList && (
						<div
							className={
								disabled || isLoading
									? styles.disabledCardPayment
									: styles.addCardPayment
							}
							onClick={onClickAddPayment}>
							<div className="d-flex align-items-center gap-2">
								<FontAwesome
									icon="plus"
									className={
										disabled || isLoading
											? 'text-gray-2'
											: 'text-primary'
									}
									size="1x"
									swapOpacity
								/>
								<span
									className={`fs-5 fw-500  ${
										disabled || isLoading
											? 'text-gray-2'
											: 'text-primary'
									}`}>
									{t('button.add-payment-method')}
								</span>
							</div>

							<div className="mt-2">
								<span
									className={`fw-400 fs-sm mx-3 ${
										disabled || isLoading
											? 'text-gray-2'
											: 'text-gray-3'
									}`}>
									{t('button.add-new-payment-method')}
								</span>
							</div>
						</div>
					)}
				</div>
			</div>
			<ModalBoxPaymentResult
				subscriptionId={subscriptionId}
				subscriptionCalculate={
					subscriptionCalculate as SubscriptionCalculate
				}
				subscriptionPaymentStatus={subscriptionPaymentStatus}
				isOpen={
					res?.data?.status === 1
						? true
						: showModal === 'true'
						? true
						: openModal
				}
				onClose={toggleModal}
			/>
		</div>
	);
};
