import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { featureIconList } from 'Data';
import { AgentPlan, ColorValues, Colors } from 'Types';
import { Button, FontAwesome, NoData, Card, SelectController } from 'Elements';
import styled from 'styled-components';

const CardBox = styled(Card)`
	min-height: 286px;
`;

export const SubscriptionPlanPremiumFeatures: FC = () => {
	const descriptionLimit = 270;
	const { t } = useTranslation();

	const {
		watch,
		control,
		getValues,
		register,
		formState: { errors }
	} = useFormContext<AgentPlan>();

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'features'
	});

	const appendFeature = () => {
		append({
			icon: undefined,
			title: '',
			description: '',
			sortOrder: 1
		});
	};

	const formatOptionLabel = ({
		label,
		icon
	}: {
		label: string;
		icon: any;
	}) => (
		<div className="d-flex align-items-center gap-2">
			<FontAwesome icon={icon} size="lg" color={ColorValues['gray-4']} />
			<span className="text-truncate text-capitalize">{label}</span>
		</div>
	);

	return (
		<CardBox className="h-100">
			<Row>
				<Col md={8} xxl={6}>
					{fields?.map((field, index) => {
						return (
							<div
								className="border-bottom pb-4 mb-4"
								key={field.id}>
								<FormGroup>
									<Label>
										{t('forms.plan-price.feature-title')}
										<small className="ms-1">
											({t('validation.required')})
										</small>
									</Label>
									<input
										{...register(`features.${index}.title`)}
										type="text"
										placeholder={t(
											'placeholder.enter-title'
										)}
										className="inputbox w-100"
									/>
									{errors?.features?.[index]?.title && (
										<div className="invalid-feedback d-block">
											{
												errors?.features?.[index]?.title
													?.message
											}
										</div>
									)}
								</FormGroup>
								<FormGroup className="position-relative">
									<Label htmlFor="featureDescription">
										{t(
											'forms.plan-price.feature-description'
										)}
									</Label>
									<textarea
										{...register(
											`features.${index}.description`,
											{
												maxLength: descriptionLimit
											}
										)}
										maxLength={descriptionLimit}
										id="featureDescription"
										className="inputbox w-100"
										cols={10}
										rows={5}
									/>
									<div className="position-absolute end-0">
										<small className="ms-auto mt-1">
											{watch(
												`features.${index}.description`
											)?.length || 0}
											/{descriptionLimit}
										</small>
									</div>
									{errors?.features?.[index]?.description && (
										<div className="invalid-feedback d-block">
											{
												errors?.features?.[index]
													?.description?.message
											}
										</div>
									)}
								</FormGroup>
								<div className="d-flex flex-column gap-3">
									<div className="flex-grow-1">
										<FormGroup>
											<Label htmlFor="icon">
												{t('forms.icon-code')}
											</Label>
											<input
												{...register(
													`features.${index}.icon`
												)}
												type="text"
												id="icon"
												className="inputbox w-100"
											/>
										</FormGroup>
									</div>
								</div>
								<div className="d-flex gap-1">
									<div
										style={{
											borderRadius: '8px'
										}}
										className="border p-4 bg-white justify-content-center align-items-center my-3 d-flex flex-column text-nowrap">
										{getValues()?.features && (
											<FontAwesome
												icon={
													watch(
														`features.${index}.icon`
													) as any
												}
												size="2x"
												className="text-primary flex-grow-1"
											/>
										)}
										<div className="flex-1">
											{t('forms.icon-preview')}
										</div>
									</div>
									<div
									style={{
										borderRadius: '8px'
									}}
									className="bg-gray-1 mt-3 p-3  my-3  align-items-center d-flex gap-2 w-100">
										<FontAwesome
											icon="info-circle"
											className="text-gray-3"
										/>
										<div className="d-flex flex-column gap-1">
											<span className="text-gray-3">
												{t('forms.feature-icon')}
											</span>
											<a
												href="https://fontawesome.com/"
												className="text-yellow fw-semibold"
												rel="noreferrer"
												target="_blank">
												https://fontawesome.com
											</a>
										</div>
									</div>
								</div>
								<div className="d-flex align-items-end">
									<Button
										size="sm"
										color={Colors['white-red']}
										className="ms-auto gap-2 flex-shrink-0"
										onClick={() => remove(index)}>
										<FontAwesome
											icon="trash-can"
											size="lg"
										/>
										{t('button.remove')}
									</Button>
								</div>
							</div>
						);
					})}
					{fields && fields?.length !== 0 && (
						<div className="ms-auto d-flex align-items-center gap-3">
							<span
								style={{
									borderRadius: '8px',
									boxShadow: '0px 4px 10px 0px #3E552333'
								}}
								role="button"
								className="gap-2 bg-white text-primary p-2 d-flex align-items-center"
								onClick={appendFeature}>
								<FontAwesome
									icon="plus"
									size="1x"
									swapOpacity
								/>
								{t('button.plan-price.add-new-feature')}
							</span>
						</div>
					)}
				</Col>
				{fields?.length === 0 && (
					<Col xs={12}>
						<NoData message="title.plan-price.no-agent-feature">
							<Button
								className="gap-2 mt-2"
								onClick={appendFeature}>
								<FontAwesome
									icon="plus"
									size="lg"
									swapOpacity
								/>
								{t('button.plan-price.add-new-feature')}
							</Button>
						</NoData>
					</Col>
				)}
			</Row>
		</CardBox>
	);
};

export default SubscriptionPlanPremiumFeatures;
