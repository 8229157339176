import { startTransition, useEffect, useState } from 'react';
import axios from 'Adapter';
import { useTranslation } from 'react-i18next';
import { containerVariants, names } from 'Constants';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Colors, Job, BadgeStatus } from 'Types';
import { motion } from 'framer-motion';
import { useProfileJobs, useModal, useUpdateJob, useSelector } from 'Hooks';
import { api, toastify, jobStatus, formatLocaleDateTime } from 'Utils';
import {
	Badge,
	Button,
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableOperation,
	DataTableRow,
	DataTableRowCell,
	EndOfList,
	FontAwesome,
	ListingPage,
	ModalBoxDelete,
	ModalBoxPublishJob,
	NoData,
	Pagination,
	SystemErrorAlert
} from 'Elements';
import styles from 'partials/shared/no-data.module.scss';

export const ListJob = () => {
	const [openModalPublish, toggleModalPublish] = useModal();
	const { assetConfig } = useSelector((state) => state.marketing);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { jobs } = names;
	const [openModal, toggleModal] = useModal();
	const [searchParams, setSearchParams] = useSearchParams();
	const [typePublish, setTypePublish] = useState<string>('publish');
	const [selectedJob, setSelectedJob] = useState<Job>();
	const [list, setList] = useState<any>([]);
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const statusParam = searchParams.get('status') || '';
	const kindParam = searchParams.get('kind') || '';

	const [state, setState] = useState<{
		pageSize: number;
		currentPage: string | number;
		keyword: string;
		filterBy: string;
		isRemoving: boolean;
		selectedJob: Job | null;
		publishing: boolean;
		kind: number | null;
	}>({
		pageSize: 10,
		currentPage: pageParam,
		keyword: queryParam,
		filterBy: statusParam,
		isRemoving: false,
		selectedJob: null,
		publishing: false,
		kind: kindParam ? Number(kindParam) : null
	});

	const getFilterByName = (filter_name: string) => {
		return jobStatus.find((filter) => filter.title === filter_name);
	};

	const { data, isError, isFetching, refetch } = useProfileJobs({
		status: getFilterByName(statusParam)?.value,
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword,
		kind: kindParam
	});

	const { publishJob, unPublishJob } = useUpdateJob();

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handleFilterBy = (selected_filter: Partial<BadgeStatus>) => {
		setSearchParams({
			...params,
			page: '1',
			status: selected_filter.title?.toString() ?? ''
		});
		setState((prev) => ({
			...prev,
			filterBy: selected_filter.label?.toString() ?? '',
			currentPage: 1
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	useEffect(() => {
		setList(data?.items);
	}, [data, state]);

	const getBadgeData = (job: Job) => {
		let badgeColor = Colors.warning;
		let badgeLabel = 'draft';

		if (!job) {
			return { color: badgeColor, label: t(badgeLabel) };
		}

		const { jobStatus, publishedVersion } = job;

		if (jobStatus === 0) {
			badgeLabel = 'badge.draft';
		} else if (jobStatus === 2) {
			badgeLabel = 'badge.unPublished';
			badgeColor = Colors.muted;
		}

		// if (isExpired) {
		// 	badgeLabel = 'badge.expired';
		// 	badgeColor = Colors.muted;
		// }

		if (publishedVersion) {
			badgeLabel = 'badge.published';
			badgeColor = Colors.green;
		}

		return { color: badgeColor, label: t(badgeLabel) };
	};

	const removeJob = async (id: string) => {
		await axios.delete(api.removeJob(id)).then((res) => {
			if (res.status === 200) {
				setState((prev) => ({
					...prev,
					isRemoving: false
				}));
				refetch?.();
				startTransition(() => {
					toastify('toastify.job-removed', {
						type: 'success'
					});
					toggleModal();
				});
			}
		});
	};

	const handleSelectRow = (row_id: string) => {
		void navigate(`${jobs.path}/${row_id}`, {
			state: { prevPath: jobs.path }
		});
	};

	const handlePublishJob = async () => {
		setState((prev) => ({
			...prev,
			publishing: true,
			selectedJob: selectedJob as Job
		}));
		try {
			if (canPublishJob(selectedJob as Job)) {
				await publishJob(selectedJob?.id as '');
			} else {
				await unPublishJob(selectedJob?.id as '');
			}
		} catch {
			setState((prev) => ({
				...prev,
				publishing: false,
				selectedJob: null
			}));
			refetch();
		} finally {
			toggleModalPublish();
			setState((prev) => ({
				...prev,
				publishing: false,
				selectedJob: null
			}));
			const newList = list;
			newList?.forEach((item) => {
				if (item.id === (selectedJob?.id as '')) {
					if (canPublishJob(selectedJob as Job)) {
						item.publishedVersion = 2;
						item.jobStatus.statusTypes = 1;
						item.publishedVersion = selectedJob?.version;
					} else {
						item.publishedVersion = null;
						item.jobStatus.statusTypes = 2;
						item.publishedInfo.publishedVersion = 0;
					}
				}
			});
			setList(newList);
		}
	};

	const handleRemoveJob = async () => {
		setState((prev) => ({
			...prev,
			isRemoving: true
		}));
		try {
			if (state.selectedJob) {
				await removeJob(state.selectedJob?.id);
			}
		} finally {
			setState((prev) => ({
				...prev,
				isRemoving: false,
				selectedJob: null
			}));
		}
	};

	// const onRemoveJob = async (job: Job) => {
	// 	setState((prev) => ({
	// 		...prev,
	// 		selectedJob: job
	// 	}));
	// 	toggleModal();
	// };

	const canPublishJob = (job: Job) => {
		return !job.publishedVersion;
		// && !job.isAutoPublish
	};

	const onFilterKind = (kind) => {
		setSearchParams({ ...params, kind: kind as any });
		setState((prev) => ({
			...prev,
			currentPage: 1,
			kind: kind
		}));
	};

	return (
		<ListingPage
			totalItems={data?.totalItems}
			pageSizes={[5, 10, 20]}
			selectedPageSize={state.pageSize}
			onPageSizeChange={onChangePageSize}
			filterOptions={jobStatus}
			selectedFilter={t(
				getFilterByName(statusParam)?.label ?? 'dropdown.all'
			)}
			onFilterChange={handleFilterBy}
			onSearchChange={handleSearch}>
			<div className="d-flex gap-2 mb-4">
				<Button
					onClick={() => onFilterKind('')}
					className={
						state.kind === null || state.kind === ('' as any)
							? `bg-primary text-white`
							: `bg-white text-gray-3`
					}>
					{t('button.all')}
				</Button>
				<Button
					onClick={() => onFilterKind(0)}
					className={
						state.kind === 0
							? `bg-primary text-white`
							: `bg-white text-gray-3`
					}>
					{t('menu.jobs')}
				</Button>
				<Button
					onClick={() => onFilterKind(1)}
					className={
						state.kind === 1
							? `bg-primary text-white`
							: `bg-white text-gray-3`
					}>
					{t('text.internship')}
				</Button>
			</div>
			<DataTable>
				{data && data?.totalItems > 0 && (
					<DataTableHead>
						<DataTableRowCell
							cellText="table.title"
							cellClassName="w-100 w-sm-20"
						/>
						<DataTableRowCell
							cellText="table.type"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.modification"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.status"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.desc"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.operation"
							cellClassName="w-100 w-sm-20"
						/>
					</DataTableHead>
				)}
				<DataTableBody>
					{!isFetching && (
						<motion.div
							variants={containerVariants}
							initial="hidden"
							animate="show">
							{list?.map((job) => {
								return (
									<DataTableRow key={job.id}>
										<DataTableRowCell
											cellClassName="w-100 w-sm-20"
											cellHead="table.title">
											<div className="fw-500 py-1 ps-2 ps-sm-0 text-end text-sm-start">
												<Link
													to={`${jobs.path}/${job.id}`}
													state={{
														prevPath: `/${jobs.path}`
													}}
													title={job.title}
													className="d-flex">
													{job.title}
												</Link>
												<p className="text-gray-3 fs-small fw-light mb-0">
													{job?.code}
												</p>
											</div>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.status"
											boxClassName="d-flex align-items-center flex-wrap gap-2">
											<Badge
												color={
													job.kind === 0
														? Colors.job
														: Colors.association
												}>
												{job.kind === 0
													? 'job'
													: 'internship'}
											</Badge>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.modification"
											cellText={formatLocaleDateTime(
												job?.auditInfo?.createdAt,
												false
											)}
										/>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.status"
											boxClassName="d-flex align-items-center flex-wrap gap-2">
											<Badge
												color={getBadgeData(job).color}>
												{getBadgeData(job).label}
											</Badge>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											boxClassName="d-flex align-items-center"
											cellHead="table.desc">
											{job.publishedVersion > 0 &&
												canPublishJob(job) && (
													<span
														className="opacityPulse infinite text-yellow lh-sm fs-small d-inline-flex align-items-center"
														title={t(
															'text.publish-new-changes'
														)}>
														<FontAwesome
															icon="triangle-exclamation"
															size="lg"
															className="me-2 flex-shrink-0"
														/>
														{t('badge.need-action')}
													</span>
												)}
											{/* {job?.isAutoPublish && (
												<span className="text-gray-3 lh-sm fs-small d-inline-flex align-items-center gap-2">
													<FontAwesome
														icon="timer"
														size="lg"
													/>
													{t('badge.auto-publish')}
												</span>
											)} */}
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-20"
											boxClassName="d-flex align-items-center"
											cellHead="table.operation">
											<DataTableOperation
												// onDeleteRow={
												// 	job?.publishedVersion ===
												// 	null
												// 		? () => onRemoveJob(job)
												// 		: false
												// }
												onEditRow={() =>
													handleSelectRow(job.id)
												}>
												<Button
													disabled={
														state.publishing &&
														state.selectedJob
															?.id === job.id
													}
													size="sm"
													color={
														canPublishJob(job)
															? Colors[
																	'white-primary'
															  ]
															: Colors[
																	'white-red'
															  ]
													}
													label={
														canPublishJob(job)
															? (t(
																	'button.publish'
															  ) as string)
															: (t(
																	'button.unPublish'
															  ) as string)
													}
													onClick={
														() => {
															setTypePublish(
																canPublishJob(
																	job
																)
																	? 'publish'
																	: 'unPublish'
															);
															setSelectedJob(job);
															toggleModalPublish();
														}

														// handlePublishJob(
														// 	job,
														// 	canPublishJob(job)
														// )
													}
												/>
											</DataTableOperation>
										</DataTableRowCell>
									</DataTableRow>
								);
							})}
						</motion.div>
					)}
					{isFetching && (
						<DataTableLoading
							widths={[20, 15, 15, 15, 15, 20]}
							count={state.pageSize}
						/>
					)}
				</DataTableBody>
				{isError && <SystemErrorAlert />}
				{!isFetching && data?.totalItems === 0 && (
					<NoData
						message="title.no-job"
						icon={
							<img
								src="/assets/img/chair.svg"
								alt=""
								className={styles.icon}
							/>
						}>
						<Button
							as="a"
							to={`${jobs.add?.path}`}
							state={{
								prevPath: jobs.path
							}}
							className="flex-fill gap-2">
							<FontAwesome icon="plus" size="lg" swapOpacity />
							{t('button.add-job')}
						</Button>
					</NoData>
				)}
			</DataTable>
			{!isFetching && <EndOfList data={data} />}
			{!isFetching && data && data?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={data.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
			<ModalBoxDelete
				isOpen={openModal}
				onClose={toggleModal}
				onRemove={handleRemoveJob}
				isRemoving={state.isRemoving}
				title={state.selectedJob?.title ?? '---'}
			/>
			<ModalBoxPublishJob
				userType={
					assetConfig?.partnerType === 2
						? 'businessPartner'
						: 'communityPartner'
				}
				refetch={refetch}
				partnerStatus={assetConfig?.partnerStatus}
				type={typePublish as 'publish'}
				isOpen={openModalPublish}
				onSubmit={handlePublishJob}
				onClose={toggleModalPublish}
			/>
		</ListingPage>
	);
};
