import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Card, LoadingContent } from 'Elements';

import {
	SubscriptionDetails,
	SubscriptionPayment
} from 'Types';
import { useSubscriptionPlanDetails } from 'src/hooks/useSubscriptionPlans';

interface Props {
	subscription?: SubscriptionDetails;
	subscriptionPayments?: SubscriptionPayment;
	isLoading: boolean;
}

const CardBox = styled(Card)`
	min-height: auto;
`;

export const SubscriptionDescription: FC<Props> = ({
	subscription,
	isLoading
}) => {
	const { t } = useTranslation();

	const { data: subscriptionPlanDetails } = useSubscriptionPlanDetails(
		subscription?.subscriptionPlanInfo?.subscriptionPlanId as string,
		{
			enabled: !!subscription?.subscriptionPlanInfo?.subscriptionPlanId
		}
	);

	return (
		<div>
			<CardBox className="h-100 mt-3">
				<h6 className="text-gray-3 mb-3 fs-6 fw-400">
					{t('title.desc')}
				</h6>

				<div
					className="lh-md"
					style={{ whiteSpace: 'pre-wrap' }}
					dangerouslySetInnerHTML={{
						__html: subscriptionPlanDetails?.shortDescription as string
					}}
				/>
				{/* <Row className="gap-3 mt-4">
					{subscriptionPlanDetails?.features?.map((item, index) => {
						return (
							<Col
								key={index}
								style={{
									border: '1px solid #D5D5DB',
									borderRadius: '8px',
									padding: '8px 16px'
								}}>
								<div className="d-flex gap-2 align-items-start">
									<FontAwesome
										size="lg"
										icon={item.icon}
										className="mt-1"
									/>
									<div className="d-flex flex-column gap-2">
										<span className="fs-5 text-gray-4 fw-400">
											{item.title}
										</span>
										<span className="fs-small fw-400 text-gary-2">
											{item.description}
										</span>
									</div>
								</div>
							</Col>
						);
					})}
				</Row> */}

				{isLoading && <LoadingContent />}
			</CardBox>
		</div>
	);
};
