import { memo, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useUpdateJob } from 'Hooks';
import { ColorValues, Colors, ModalProps } from 'Types';
import {
	Button,
	ModalBox,
	ModalBoxFooter,
	SubmitButton,
	FontAwesome
} from 'Elements';

export const ModalBoxUnPublishJob: FC<ModalProps> = ({
	isOpen,
	onClose,
	type
}) => {
	const { t } = useTranslation();
	const { job_id } = useParams() as { job_id: string };
	const { refetch, unPublishJob } = useUpdateJob(job_id);
	const [unPublishing, setUnPublish] = useState(false);

	const handleUnPublishJob = async () => {
		try {
			setUnPublish(true);
			await unPublishJob(job_id, refetch);
			onClose && onClose();
		} finally {
			setUnPublish(false);
		}
	};

	return (
		<ModalBox isOpen={isOpen} onClose={onClose}>
			{type === 'businessPartner' ? (
				<header className="d-flex flex-column gap-2">
					<h2 className="fs-base fw-500">{t("button.unPublish")}</h2>
					<span className="fs-small fw-400">
						{t('text.header-unpublish-job-bp')}
					</span>
					<div
						className="d-flex d-flex align-items-center gap-3 bg-yellow_o-2 text-yellow p-2 mt-3"
						style={{ borderRadius: '8px' }}>
						<FontAwesome icon="circle-info" size="xl" />
						<span className="fs-sm fw-500">
							{t('text.massage-unpublish-job-bp')}
						</span>
					</div>
				</header>
			) : (
				<header className="d-flex flex-column gap-2">
					<h2 className="fs-base fw-500">{t("button.unPublish")}</h2>
					<span className="fs-base fw-400">
						{t('text.header-unpublish-job-cp')}
					</span>
				</header>
			)}
			<ModalBoxFooter className="justify-content-between pt-3">
				<Button color={Colors['white-gray']} onClick={onClose}>
					{t('button.cancel')}
				</Button>
				<SubmitButton
					icon={false}
					color="white-red"
					isSubmitting={unPublishing}
					label="button.unPublish"
					savingLabel="button.unPublishing"
					onClick={handleUnPublishJob}
				/>
			</ModalBoxFooter>
		</ModalBox>
	);
};
