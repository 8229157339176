import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSubscription, useTransaction } from 'Hooks';
import {
	SubscriptionDetails,
	SubscriptionPayment,
	TransactionCustomer,
	TransactionDetails,
	TransactionStatus,
	TransactionType
} from 'Types';
import {
	Card,
	CompanyTransactionInvoice,
	CompanyTransactionInvoicePayment,
	CompanyTransactionPaymentProvider,
	CompanyTransactionPayment,
	LoadingContent,
	DetailsSubscription
} from 'Elements';
import { FC } from 'react';

const TransactionCard = styled(Card)`
	height: 100%;
	min-height: 250px;
`;

interface Props {
	transaction?: SubscriptionPayment;
}

export const ViewInvoice: FC<Props> = ({ transaction }) => {
	const { t } = useTranslation();
	const { invoice_id } = useParams();

	const { data: subscription, isLoading } = useSubscription(
		transaction?.subscriptionId as string,
		{
			enabled: !!transaction?.subscriptionId
		}
	);

	const status = transaction?.status;

	return (
		<>
			<Row className="mb-4">
				<Col md={6}>
					<TransactionCard>
						<CompanyTransactionInvoice
							transaction={transaction as SubscriptionPayment}
						/>
					</TransactionCard>
				</Col>
				<Col md={6} className="mt-4 mt-md-0">
					<TransactionCard>
						<CompanyTransactionInvoicePayment
							transaction={transaction as SubscriptionPayment}
							baseUrl="invoices"
						/>
						{/* <CompanyTransactionPaymentProvider
							transaction={transaction as SubscriptionPayment}
						/> */}
					</TransactionCard>
				</Col>
			</Row>
			<Card>
				<h6 className="text-gray-3 mb-3 fs-6 fw-400">
					{t('title.product')}
				</h6>
				<DetailsSubscription
					showPrice={false}
					subscription={subscription as SubscriptionDetails}
					subscriptionId={subscription?.id as string}
					communityPartnerInfo={subscription?.communityPartnerInfo}
					isLoading={isLoading}
					canAutoRenew={false}
				/>
			</Card>
		</>
	);
};
