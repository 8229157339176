import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useModal, useUpdateBusinessPartner } from 'Hooks';
import {
	Address,
	businessPartnerLogo,
	BusinessPartnerMe,
	BusinessPartnerProfile,
	BusinessPartnerProfileInitialData,
	Colors,
	Media,
	Tab
} from 'Types';
import {
	ProfileDecorationBox,
	Card,
	Button,
	FontAwesome,
	ModalBoxPublish,
	LoadingSpinner
} from 'Elements';
import { useTranslation } from 'react-i18next';
import { formatLocaleDateTime } from 'src/helpers/DateTime';

interface Props {
	businessPartnerProfile: BusinessPartnerProfile;
	businessPartnerMe: BusinessPartnerMe;
	refetchBusinessPartnerProfile?: () => void;
	refetchBusinessPartnerMe?: () => void;
}

export const BusinessPartnerDecorationBox: FC<Props> = ({
	businessPartnerProfile,
	businessPartnerMe,
	refetchBusinessPartnerProfile
}) => {
	const { t } = useTranslation();
	const { reset } = useForm();
	const { pathname } = useLocation();
	const [openModalPublish, toggleModalPublish] = useModal();
	const [openModalUnPublish, toggleModalUnPublish] = useModal();
	const {
		data: businessPartner,
		address,
		isLoading,
		refetch,
		updateBusinessPartnerLogo
	} = useUpdateBusinessPartner();

	const tabs: Tab[] = [
		{
			id: 0,
			title: 'tab.general',
			path: 'general',
			isActive:
				pathname === '/my-business-partner' ||
				pathname === '/my-business-partner/general'
		},
		{
			id: 1,
			title: 'tab.address',
			path: 'address',
			isActive: pathname === '/my-business-partner/address'
		}
	];

	const handleUploadImage = async (image_data: Partial<Media>) => {
		if (image_data) {
			const data = {
				logo: {
					url: image_data.url,
					thumbnail: image_data.thumbnailUrl
				}
			};
			await updateBusinessPartnerLogo(
				data as Partial<businessPartnerLogo>
			);
		}
	};

	const handleResetLogo = async () => {
		const data = {
			logo: {
				url: '',
				thumbnail: ''
			}
		};
		await updateBusinessPartnerLogo(data as Partial<businessPartnerLogo>);
	};

	useEffect(() => {
		reset(businessPartner);
	}, [reset, businessPartner]);

	return (
		<>
			<Card>
				{isLoading && <LoadingSpinner title="forms.loading-content" />}
				{!isLoading && (
					<div>
						<div
							className={`d-flex flex-column flex-lg-row align-items-lg-center ${
								businessPartnerProfile?.auditInfo
									? 'justify-content-lg-between'
									: 'justify-content-lg-end'
							}`}>
							{businessPartnerProfile?.auditInfo && (
								<div className="d-flex flex-column flex-lg-row gap-1 gap-lg-4 align-items-lg-center">
									<div className="d-flex gap-1 ">
										<FontAwesome
											icon="code-branch"
											color={Colors.primary}
											size="sm"
										/>
										<span className="fw-400 fs-sm">
											{t('text.current-profile-version')}:
										</span>
										<span>
											{businessPartnerProfile?.version}
										</span>
									</div>
									{businessPartnerMe?.publishInfo
										?.version && (
										<div className="d-flex gap-1 flex-wrap">
											<FontAwesome
												icon="code-branch"
												color={Colors.primary}
												size="sm"
											/>
											<span className="fw-400 fs-sm">
												{' '}
												{t('text.published-version')}:
											</span>
											<span>
												{
													businessPartnerMe
														?.publishInfo?.version
												}
											</span>
											<span>
												{businessPartnerMe?.publishInfo
													?.lastUpdatedAt
													? `(${t('text.at')} 
											${formatLocaleDateTime(businessPartnerMe?.publishInfo?.lastUpdatedAt)})`
													: ''}
											</span>
										</div>
									)}
									<div className="d-flex gap-1 flex-wrap">
										<FontAwesome
											icon="calendar"
											color={Colors.primary}
											size="sm"
										/>
										<span className="fw-400 fs-sm">
											{' '}
											{t('text.last-updated-at')}:
										</span>
										<span>
											{businessPartnerProfile?.auditInfo
												?.updatedAt
												? formatLocaleDateTime(
														businessPartnerProfile
															?.auditInfo
															?.updatedAt
												  )
												: ''}
										</span>
									</div>
								</div>
							)}
							{/* {type === 'communityPartner' && (
							<DecorationDropDown>
								<DropdownItem
									className="d-flex align-items-center gap-2 text-red"
									onClick={toggleModalDelete}>
									<FontAwesome
										icon="triangle-exclamation"
										size="lg"
									/>
									{t('dropdown.delete-account')}
								</DropdownItem>
							</DecorationDropDown>
						)} */}
							<div className="d-flex flex-column flex-lg-row align-items-end align-items-lg-center gap-2">
								{businessPartner?.publishInfo?.status === 1 && (
									<Button
										size="sm"
										color={Colors['white-red']}
										onClick={toggleModalUnPublish}>
										<FontAwesome
											icon={'triangle-exclamation'}
											size="1x"
											className="me-2"
										/>
										{t('button.unPublish')}
									</Button>
								)}
								{businessPartnerProfile?.version !==
									businessPartnerMe?.publishInfo?.version && (
									<Button
										size="sm"
										color={Colors['white-primary']}
										onClick={toggleModalPublish}>
										<FontAwesome
											icon={'code-branch'}
											size="1x"
											className="me-2"
										/>
										{t('button.publish')}
									</Button>
								)}
							</div>
						</div>
						{localStorage.getItem('showPm') === 'false' &&
							businessPartnerMe?.publishInfo?.version &&
							businessPartnerProfile?.version !==
								businessPartnerMe?.publishInfo?.version && (
								<Card className="w-100 d-flex my-3 py-2 rounded-3 align-items-center shadow-none infinite gap-1 bg-yellow_o-2 text-yellow">
									<FontAwesome
										icon="info-circle"
										size="1x"
										className="me-2"
									/>
									{t(
										'text.current-version-and-the-last-published-version-different'
									)}
								</Card>
							)}
					</div>
				)}
			</Card>
			<ProfileDecorationBox
				tabs={tabs}
				type="businessPartner"
				profile={businessPartner as BusinessPartnerProfileInitialData}
				isLoading={isLoading}
				title={businessPartner?.businessName ?? '---'}
				logo={businessPartner?.logo?.url ?? ''}
				thumbnailLogo={businessPartner?.logo?.thumbnail ?? ''}
				address={address as Address}
				onResetLogo={handleResetLogo}
				onChangeLogo={handleUploadImage}
				refetchPartnerProfile={refetchBusinessPartnerProfile}
			/>
			<ModalBoxPublish
				userType="businessPartner"
				profileId={businessPartner?.businessPartnerProfileId}
				status={businessPartner?.status as number}
				refetch={refetch}
				type={'publish'}
				isOpen={openModalPublish}
				onClose={toggleModalPublish}
			/>
			<ModalBoxPublish
				userType="businessPartner"
				profileId={businessPartner?.businessPartnerProfileId}
				status={businessPartner?.status as number}
				refetch={refetch}
				type={'unPublish'}
				isOpen={openModalUnPublish}
				onClose={toggleModalUnPublish}
			/>
		</>
	);
};
