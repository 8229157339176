import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { names } from 'Constants';
import { Media, Department, AspectRatio, Logo } from 'Types';
import {
	useDelayUnmount,
	useModal,
	usePrompt,
	useUpdateDepartment
} from 'Hooks';
import {
	Card,
	SubmitButtonGroup,
	SwitchController,
	AvatarController,
	LoadingContent,
	ModalBoxDelete
} from 'Elements';

export const UpdateDepartment = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { departments } = names;
	const { department_id } = useParams();
	const [image, setImage] = useState({ url: '', thumbnail: '' });
	const [openModal, toggleModal] = useModal();
	const [isRemoving, setIsRemoving] = useState(false);
	const {
		data: fields,
		isFetching,
		updateDepartment,
		removeDepartment
	} = useUpdateDepartment(department_id as string);
	const shouldRenderModal = useDelayUnmount(openModal, 350);

	const schema = yup.object().shape({
		title: yup.string().required(t('validation.title.required'))
	});

	const {
		reset,
		register,
		control,
		handleSubmit,
		setValue,
		formState: { errors, isDirty, isSubmitting }
	} = useForm<Partial<Department>>({
		resolver: yupResolver(schema)
	});

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const handleRemoveImage = async () => {
		setImage({
			url: '',
			thumbnail: ''
		});
		setValue(
			'image',
			{
				url: '',
				thumbnail: ''
			},
			{ shouldDirty: true }
		);
	};

	const handleRemoveUser = async () => {
		try {
			setIsRemoving(true);
			await removeDepartment(() => {
				setIsRemoving(false);
				navigate(departments.path);
			});
		} finally {
			setIsRemoving(false);
		}
	};

	const handleUploadImage = async (image_data: Media) => {
		if (image_data) {
			setImage({
				url: image_data.url,
				thumbnail: image_data.thumbnailUrl
			});
			setValue(
				'image',
				{
					url: image_data.url,
					thumbnail: image_data.thumbnailUrl
				},
				{ shouldDirty: true }
			);
		}
	};

	const onSubmit: SubmitHandler<Partial<Department>> = async (data) => {
		await updateDepartment(data);
	};

	useEffect(() => {
		reset({
			...fields
		});
		setImage(fields?.image as Logo);
	}, [reset, fields]);

	return (
		<Card>
			<Row>
				<Col md={6} xxl={5}>
					<FormGroup>
						<AvatarController
							name="image"
							aspectRatio={AspectRatio['16/9']}
							control={control}
							image={image?.url}
							onRemove={handleRemoveImage}
							onUpload={handleUploadImage}
							defaultImage="/assets/img/business-partner-default.png"
						/>
					</FormGroup>
				</Col>
				<Col md={6} xxl={5}>
					<form onSubmit={handleSubmit(onSubmit)} noValidate>
						<Row>
							<Col>
								<FormGroup>
									<Label htmlFor="title">
										{t('forms.title')}
										<small className="ms-1">
											({t('validation.required')})
										</small>
									</Label>
									<input
										{...register('title')}
										type="text"
										id="title"
										aria-invalid={!!errors.title}
										className="inputbox w-100"
									/>
									{errors.title && (
										<div className="invalid-feedback d-block">
											{errors.title.message}
										</div>
									)}
								</FormGroup>
								<FormGroup className="position-relative">
									<Label htmlFor="description">
										{t('forms.agent.department-desc')}
									</Label>
									<textarea
										{...register('description')}
										id="description"
										className="inputbox w-100"
										cols={10}
										rows={5}
										aria-invalid={!!errors.description}
									/>
								</FormGroup>
								<FormGroup className="position-relative">
									<Label htmlFor="sortOrder">
										{t('forms.sort-order')}
									</Label>
									<input
										{...register('sortOrder')}
										type="text"
										id="sortOrder"
										placeholder={t(
											'placeholder.sort-order'
										)}
										className="inputbox w-100"
									/>
								</FormGroup>
								<FormGroup>
									<SwitchController
										name="isActive"
										control={control}
										label="forms.show-in-home"
									/>
								</FormGroup>
								<SubmitButtonGroup
									isDisable={!isDirty}
									isSubmitting={isSubmitting}
									label="button.update"
									savingLabel=""
									removeTitle="button.remove-department"
									onRemove={toggleModal}
								/>
							</Col>
						</Row>
					</form>
				</Col>
			</Row>
			{shouldRenderModal && (
				<ModalBoxDelete
					isOpen={openModal}
					onClose={toggleModal}
					onRemove={handleRemoveUser}
					isRemoving={isRemoving}
					title={fields?.title ?? ''}
				/>
			)}
			{isFetching && <LoadingContent />}
		</Card>
	);
};
