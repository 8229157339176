import { Container } from 'reactstrap';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { CustomNavigationClient, authInstance, clx } from 'Utils';
import {
	setInputAssetData,
	setLogo,
	setOriginalLogo,
	toggleSidebar
} from 'Actions';
import {
	Sidebar,
	Header,
	ScrollTop,
	FontAwesome,
	Button,
	LoadingContent
} from 'Elements';
import {
	useDelayUnmount,
	useDispatch,
	useSelector,
	useBusinessPartnerMe,
	useGetB2bUserProfileQuery,
	useCommunityPartnerMe
} from 'Hooks';
import styles from 'partials/shared/page-frame.module.scss';
import { Colors, ColorValues } from 'src/types/Color';
import { useTranslation } from 'react-i18next';
import { AssetConfigInputType } from 'src/types/Marketing';
import { Suspense } from 'react';

export const PageLayout = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const location = useLocation();
	const sidebar = useSelector((state) => state.sidebar);
	const shouldRenderSidebar = useDelayUnmount(sidebar.isOpen, 400);
	const navigate = useNavigate();
	const navigationClient = new CustomNavigationClient(navigate);
	authInstance.setNavigationClient(navigationClient);

	const closeSidebar = () => {
		dispatch(toggleSidebar(false));
	};
	const userRole = localStorage.getItem('userRole');

	const { data: user, isLoading: isLoadingGetB2bUser } =
		useGetB2bUserProfileQuery();

	let userType = '';
	if (user?.partnerType === 2) {
		userType = 'businessPartner';
	}
	if (user?.partnerType === 1) {
		userType = 'communityPartner';
	}

	const { data: businessPartnerMe, isLoading: isLoadingBusinessPartnerMe } =
		useBusinessPartnerMe({
			cacheTime: 0,
			enabled: !isLoadingGetB2bUser && user?.partnerType === 2,
			onSuccess(data) {
				const simpleLogoElement = data?.logo?.thumbnail ?? '';
				const basic_Profile_data = {
					address: data?.address ?? '',
					partnerType: user?.partnerType,
					providerCustomerId: data?.providerCustomerId,
					partnerStatus: data?.status,
					regionCode: data?.regionCode ?? '',
					nameElement: data?.businessName ?? '',
					mainEmailAddress: data?.mainEmailAddress ?? '',
					phoneElement: data.contact?.tel ?? ''
				};
				for (const key in basic_Profile_data) {
					dispatch(
						setInputAssetData({
							name: key as AssetConfigInputType,
							value: basic_Profile_data[key]
						})
					);
				}
				dispatch(setLogo(simpleLogoElement));
				dispatch(setOriginalLogo(simpleLogoElement));
				localStorage.setItem(
					'showPm',
					data?.status === 2 ? 'false' : 'true'
				);
			}
		});

	const { data: communityPartnerMe } = useCommunityPartnerMe({
		enabled: !isLoadingGetB2bUser && user?.partnerType === 1,
		onSuccess(data) {
			const simpleLogoElement = data?.logo?.thumbnail ?? '';
			const basic_Profile_data = {
				nameElement: data?.businessName ?? '',
				emailElement: data?.mainEmailAddress ?? '',
				communityPartnerId: data?.id ?? '',
				commissionGroupId: data?.commissionGroupId ?? '',
				address: data?.address ?? '',
				partnerType: user?.partnerType,
				partnerStatus: data?.status,
				regionCode: data?.regionCode ?? '',
				mainEmailAddress: data?.mainEmailAddress ?? ''
			};
			for (const key in basic_Profile_data) {
				dispatch(
					setInputAssetData({
						name: key as AssetConfigInputType,
						value: basic_Profile_data[key]
					})
				);
			}
			dispatch(setLogo(simpleLogoElement));
			dispatch(setOriginalLogo(simpleLogoElement));
		}
	});
	return (
		<>
			<ScrollTop />
			<div className={styles.wrapper}>
				<Header
					userType={userType}
					businessPartnerProfile={businessPartnerMe}
					communityPartnerMe={communityPartnerMe}
				/>
				<Sidebar isOpen={sidebar.isOpen} />
				<main className={styles.content_wrapper}>
					{!isLoadingGetB2bUser &&
						user &&
						!isLoadingBusinessPartnerMe &&
						userRole === 'businessPartner' &&
						localStorage.getItem('showPm') &&
						localStorage.getItem('showPm') === 'true' &&
						!location.pathname.includes('subscriptions') && (
							<Container fluid className={styles.container}>
								<div className={styles.content}>
									<div
										className={`${styles.boxCompleteRegistration} flex-wrap mt-5`}>
										<div className="d-flex gap-2">
											<FontAwesome
												icon="info-circle"
												size="lg"
												color={ColorValues.yellow}
											/>
											<span>
												{t(
													'text.please-informed-publish-jobs-company-you-must-complete-registration-selecting-subscription-plans'
												)}
											</span>
										</div>
										<Button
											size="sm"
											color={Colors['secondary']}
											onClick={() =>
												navigate('/subscriptions')
											}>
											{t(
												'button.payment.pay-create-account'
											)}
										</Button>
									</div>
								</div>
							</Container>
						)}
					<Suspense fallback={<LoadingContent title="" />}>
						<Container fluid className={styles.container}>
							<div className={styles.content}>
								<Outlet />
							</div>
						</Container>
					</Suspense>
				</main>
			</div>
			{shouldRenderSidebar && (
				<div
					className={clx(
						'overlay',
						sidebar.isOpen && 'show',
						!sidebar.isOpen && 'hiding'
					)}
					onClick={closeSidebar}
				/>
			)}
		</>
	);
};

PageLayout.displayName = 'PageLayout';
