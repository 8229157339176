import { memo, FC, Dispatch, SetStateAction } from 'react';
import { Col, Row } from 'reactstrap';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { clx, getCurrentLocale } from 'Utils';
import { StepNavigation } from 'Elements';
import { InternshipForm } from 'Types';
import styles from 'partials/step/step-container.module.scss';
import coverStyles from 'partials/control/cover-controller.module.scss';
import { de, enUS } from 'date-fns/locale';

interface Props {
	fields: InternshipForm;
	onNext: () => void;
	onPrev: () => void;
	goToStep: (stepId: string) => void;
	setInternshipId: Dispatch<SetStateAction<string>>;
}

export const CreateInternshipPreviewSubmit: FC<Props> = memo(
	({ fields, onNext }) => {
		const { t } = useTranslation();
		const {
			handleSubmit,
			formState: { isSubmitting }
		} = useForm();

		const onSubmit = async () => {
			onNext?.();
		};

		const getLocalFormat = () => {
			const currentLocale = getCurrentLocale().code;
			if (currentLocale === 'de') {
				return 'dd.MM.yyyy';
			}
			return 'yyyy-MM-dd';
		};

		return (
			<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
				<Row>
					<Col sm={5} md={12} xl={5} xxl={4} className="mb-4">
						<img
							src={
								fields.step2.featuredPhotoUrl ||
								'/assets/img/default-image.png'
							}
							alt={fields.step1.title}
							className={clx(coverStyles.cover, 'h-auto')}
						/>
					</Col>
					<Col sm={7} md={12} xl={7} xxl={8} className="mb-4">
						<div className="d-flex flex-column gap-3 mb-2">
							<h4 className="fw-500 fs-base mb-0 lh-base">
								{fields.step1.title}
							</h4>
							<span className="text-gray-3 fs-small d-flex align-items-center">
								{t('forms.code')}:&nbsp;
								{fields.step1.code || '---'}
							</span>
						</div>
						<p className="text-gray-3 mb-0 lh-base">
							{fields.step1.shortDescription || '---'}
						</p>
					</Col>
				</Row>
				{/* <div className="my-4 border-bottom position-relative">
					<Button
						color={Colors['white-gray']}
						className={styles.edit}
						onClick={() => goToStep('step1')}>
						<FontAwesome icon="pen" />
					</Button>
				</div> */}
				{/* <section className="mb-3 pb-2">
					<h4 className="fs-sm d-flex align-items-center text-primary mb-3">
						{t('wizard.step-internship-2')}
					</h4>
					<Row></Row> */}
				{/* <div className="my-4 border-bottom position-relative">
						<Button
							color={Colors['white-gray']}
							className={styles.edit}
							onClick={() => goToStep('step2')}>
							<FontAwesome icon="pen" />
						</Button>
					</div> */}
				{/* </section> */}
				<section className="mb-3 pb-2">
					<h4 className="fs-sm d-flex align-items-center text-primary mb-3">
						{t('wizard.step-internship-3')}
					</h4>
					<div className="mb-4">
						<h4 className="fw-500 fs-sm">
							{t('forms.internship.desc')}
						</h4>
						<div
							className="text-gray-3"
							dangerouslySetInnerHTML={{
								__html: fields.step4.description || '---'
							}}
						/>
					</div>
					<div className="mb-4">
						<h4 className="fw-500 fs-sm">
							{t('forms.internship.requirements')}
						</h4>
						<div
							className="text-gray-3"
							dangerouslySetInnerHTML={{
								__html: fields.step4.requirements || '---'
							}}
						/>
					</div>
					<div className="mb-4">
						<h4 className="fw-500 fs-sm">
							{t('forms.internship.responsibilities')}
						</h4>
						<div
							className="text-gray-3"
							dangerouslySetInnerHTML={{
								__html: fields.step4.responsibilities || '---'
							}}
						/>
					</div>
					<div className="mb-4">
						<h4 className="fw-500 fs-sm">
							{t('forms.internship.benefits')}
						</h4>
						<div
							className="text-gray-3"
							dangerouslySetInnerHTML={{
								__html: fields.step4.benefits || '---'
							}}
						/>
					</div>

					{/* <div className="my-4 border-bottom position-relative">
						<Button
							color={Colors['white-gray']}
							className={styles.edit}
							onClick={() => goToStep('step3')}>
							<FontAwesome icon="pen" />
						</Button>
					</div> */}
				</section>
				<section className="mb-3 pb-2">
					<h4 className="fs-sm d-flex align-items-center text-primary mb-3">
						{t('wizard.step-internship-4')}
					</h4>
					{fields?.step5?.map((term: any, index: any) => {
						return (
							<div
								key={index}
								className="border border-gray-2 mb-4"
								style={{
									borderRadius: '8px',
									padding: '24px'
								}}>
								<div>
									<div className="gap-3">
										<Row key={index} className="w-100">
											<h3 className="fs-sm">
												{term.title
													? term.title
													: `${t('forms.term')} ${
															index + 1
													  }`}
											</h3>
											{term?.sessions?.map(
												(
													session: any,
													indexSession: any
												) => {
													const startDate: any =
														new Date(
															Date.parse(
																session.sessionStartDate
															)
														);
													const endDate: any =
														new Date(
															Date.parse(
																session.sessionEndDate
															)
														);

													return (
														<Col
															sm={3}
															key={indexSession}
															className="mb-2">
															<h3 className="fs-small">
																{`${t(
																	'forms.session'
																)} ${
																	indexSession +
																	1
																}`}
															</h3>
															<div className="d-flex flex-wrap align-items-center">
																<div
																	className="d-flex"
																	style={{
																		flexWrap:
																			'nowrap'
																	}}>
																	{`${format(
																		new Date(
																			session.sessionStartDate
																		),
																		`EEEE ${getLocalFormat()}`,
																		{
																			locale:
																				getCurrentLocale()
																					.code ===
																				'de'
																					? de
																					: enUS
																		}
																	)}`}
																</div>
																<div
																	className="d-flex ms-2"
																	style={{
																		flexWrap:
																			'nowrap'
																	}}>
																	{' '}
																	{`${startDate.toLocaleTimeString(
																		[],
																		{
																			hour: '2-digit',
																			minute: '2-digit',
																			hour12: false
																		}
																	)} - ${endDate.toLocaleTimeString(
																		[],
																		{
																			hour: '2-digit',
																			minute: '2-digit',
																			hour12: false
																		}
																	)}`}
																</div>
															</div>
														</Col>
													);
												}
											)}
										</Row>
									</div>
								</div>
							</div>
						);
					})}
				</section>
				<StepNavigation
					type="submit"
					label="button.internship.create-internship"
					savingLabel=""
					isSubmitting={isSubmitting}
				/>
			</form>
		);
	}
);

CreateInternshipPreviewSubmit.displayName = 'CreateInternshipPreviewSubmit';
