import { startTransition } from 'react';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import axios from 'Adapter';
import { queryKey } from 'Constants';
import { api, fetch, toastify } from 'Utils';
import {
	AgentProfile,
	DataList,
	DataQueryParams,
	Subscription,
	SubscriptionCalculate,
	SubscriptionCommissionGroups,
	SubscriptionDetails,
	SubscriptionPayment,
	SubscriptionPlansFeature
} from 'Types';

const config = (options = {}) => {
	return {
		...options,
		keepPreviousData: false
	};
};

export const useSubscriptions = <T = DataList<Subscription>>(
	{
		pageSize,
		pageNumber,
		keyword = '',
		subscriptionPlanType = '',
		subscriptionStatus = ''
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<Subscription>>
): UseQueryResult<DataList<Subscription>, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.subscriptions,
			{
				pageSize,
				pageNumber,
				keyword,
				subscriptionPlanType,
				subscriptionStatus
			}
		],
		queryFn: () =>
			fetch.companySubscriptionList(
				pageSize,
				pageNumber,
				keyword,
				subscriptionPlanType,
				subscriptionStatus
			),
		...config(options)
	});
};

export const useSubscription = <T = SubscriptionDetails>(
	subscriptionId: string,
	options?: UseQueryOptions<T, any, SubscriptionDetails>
): UseQueryResult<SubscriptionDetails, unknown> => {
	return useQuery({
		queryKey: [queryKey.subscription, { subscriptionId }],
		queryFn: () => fetch.subscriptionDetails(subscriptionId),
		...config(options)
	});
};

export const useSubscriptionPayments = <T = SubscriptionPayment>(
	currentSubscriptionPaymentId: string,
	options?: UseQueryOptions<T, any, SubscriptionPayment>
): UseQueryResult<SubscriptionPayment, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.subscriptionPayments,
			{ currentSubscriptionPaymentId }
		],
		queryFn: () =>
			fetch.subscriptionPaymentsDetails(currentSubscriptionPaymentId),
		...config(options)
	});
};

export const useSubscriptionPaymentsList = <T = DataList<SubscriptionPayment>>(
	subscriptionPaymentId: string,
	pageNumber: number,
	pageSize: number,
	options?: UseQueryOptions<T, any, DataList<SubscriptionPayment>>
): UseQueryResult<DataList<SubscriptionPayment>, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.subscriptionPaymentsList,
			{ subscriptionPaymentId, pageNumber, pageSize }
		],
		queryFn: () =>
			fetch.subscriptionPayments(
				subscriptionPaymentId,
				pageNumber,
				pageSize
			),
		...config(options)
	});
};

export const useSubscriptionCalculate = <T = SubscriptionCalculate>(
	CommunityPartnerId: string,
	SubscriptionPlanId: string,
	VoucherCode?: string,
	SubscriptionPlanType?: number,
	options?: UseQueryOptions<T, any, SubscriptionCalculate>
): UseQueryResult<SubscriptionCalculate, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.subscriptionCalculate,
			{
				CommunityPartnerId,
				SubscriptionPlanId,
				VoucherCode,
				SubscriptionPlanType
			}
		],
		queryFn: () =>
			fetch.subscriptionCalculate(
				CommunityPartnerId,
				SubscriptionPlanId,
				VoucherCode,
				SubscriptionPlanType
			),
		...config(options)
	});
};

export const useSubscriptionPlansFeature = <T = SubscriptionPlansFeature>(
	subscriptionPlanFeatureId: string,
	SubscriptionPlanId: string,
	options?: UseQueryOptions<T, any, SubscriptionPlansFeature>
): UseQueryResult<SubscriptionPlansFeature, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.subscriptionPlansFeature,
			{ subscriptionPlanFeatureId, SubscriptionPlanId }
		],
		queryFn: () =>
			fetch.subscriptionPlansFeature(
				subscriptionPlanFeatureId,
				SubscriptionPlanId
			),
		...config(options)
	});
};

export const useSubscriptionPlansCommissionGroups = <
	T = SubscriptionCommissionGroups
>(
	commissionGroupId: string,
	options?: UseQueryOptions<T, any, SubscriptionCommissionGroups>
): UseQueryResult<SubscriptionCommissionGroups, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.subscriptionPlansCommissionGroups,
			{ commissionGroupId }
		],
		queryFn: () =>
			fetch.subscriptionPlansCommissionGroups(commissionGroupId),
		...config(options)
	});
};

export const useAgentToSwitch = <T = AgentProfile>(
	{
		pageSize,
		pageNumber,
		keyword = '',
		companySubscription
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<AgentProfile>>
): UseQueryResult<DataList<AgentProfile>, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.agentToSwitch,
			{ pageSize, pageNumber, keyword, companySubscription }
		],
		queryFn: () =>
			fetch.agentsToSwitch(
				pageSize,
				pageNumber,
				keyword,
				companySubscription
			),
		...config(options)
	});
};

export const useUpdateSubscription = (
	subscriptionId: string,
	options?: UseQueryOptions<SubscriptionDetails>
) => {
	const query = useSubscription(subscriptionId, options);

	const updateSubscription: (
		field: boolean,
		onFinal?: () => void,
		onCatch?: () => void
	) => Promise<void> = async (field, onFinal, onCatch) => {
		if (!field) {
			await axios
				.put(api.disableSubscriptionRenewal(subscriptionId), {})
				.then((res) => {
					if (res.status === 200) {
						query.refetch();
						startTransition(() => {
							toastify('toastify.auto-renewal-canceled', {
								type: 'success'
							});
						});
						onFinal?.();
					}
				})
				.catch(() => onCatch?.());
		} else {
			await axios
				.put(api.enableSubscriptionRenewal(subscriptionId), {})
				.then((res) => {
					if (res.status === 200) {
						query.refetch();
						startTransition(() => {
							toastify(
								'toastify.auto-renewal-activated',
								{
									type: 'success'
								}
							);
						});
						onFinal?.();
					}
				})
				.catch(() => onCatch?.());
		}
	};
	return { ...query, updateSubscription };
};
