import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useJobTags, useTheme } from 'Hooks';
import { FontAwesome, LoadingMask } from 'Elements';
import { Job, UserCategory } from 'Types';
import { clx, getCurrentLocale } from 'Utils';
import styles from 'partials/job/job.module.scss';

interface Props {
	job: Job;
	userType: UserCategory;
}

export const JobInfoSection: FC<Props> = ({ job, userType }) => {
	const { theme } = useTheme();
	const { t } = useTranslation();

	const { data: dataJobsTags, isFetching: isFetchingJobTags } = useJobTags({
		pageSize: 500,
		pageNumber: 1,
		keyword: '',
		jobTagCategoryId: '',
		noAssignedCategory: false,
		isActive: true
	});

	// const { data: dataActivityTags, isFetching: isFetchingDataActivityTags } =
	// 	useActivityTags({
	// 		pageSize: 500,
	// 		pageNumber: 1,
	// 		keyword: '',
	// 		isActive: ''
	// 	});

	const getTitle = (id: any, array: any) => {
		let title = '';
		array?.items?.forEach((element) => {
			if (id === element.id) {
				title = element.title;
				element.translateTitles.find((item) => {
					if (
						item?.language.toLowerCase() ===
						getCurrentLocale().code.toLowerCase()
					) {
						title = item.value
							? item.value
							: element.translateTitles[0].value ?? element.title;
					}
				});
				if (title === '') {
					title = element.title;
				}
			}
		});
		return title;
	};

	const getActiveTag = (id: any, array: any) => {
		let active = false;
		array?.items?.forEach((element) => {
			if (id === element.id) {
				active = element?.isActive;
			}
		});
		return active;
	};

	return (
		<div className="d-flex flex-column gap-3 pt-lg-3 pb-3">
			<h2 className="fs-3 fw-bold lh-sm text-gray-6">{job.title}</h2>
			<div
				className={clx(
					'gap-3 d-flex align-items-center flex-wrap gap-3'
				)}>
				{isFetchingJobTags &&
					[1, 2, 3].map((item) => {
						return (
							<div key={item}>
								<LoadingMask className={styles.titleJobTags} />
							</div>
						);
					})}
				{!isFetchingJobTags && (
					<>
						<div className="d-flex align-items-center gap-2">
							{job.jobTags?.map((item) => {
								return (
									getActiveTag(
										item.jobTagId,
										dataJobsTags
									) && (
										<div
											className="d-flex align-items-center"
											style={{
												borderRadius: '8px',
												padding: '8px 16px',
												background:
													'linear-gradient(180deg, #EFEFF2 0%, #F8F8F9 100%)'
											}}
											key={item?.jobTagId}>
											<span className="text-gray-3 text-base fw-500">
												{getTitle(
													item.jobTagId,
													dataJobsTags
												)}
											</span>
										</div>
									)
								);
							})}
						</div>
						{/* <div className="d-flex align-items-center gap-2">
							{job.jobActivities?.map((activity) => {
								return (
									<div
										className="d-flex align-items-center"
										style={{
											borderRadius: '8px',
											padding: '8px 16px',
											background:
												'linear-gradient(180deg, #EFEFF2 0%, #F8F8F9 100%)'
										}}
										key={activity}>
										<span className="text-gray-3 text-base fw-500">
											{getTitle(
												activity,
												dataActivityTags
											)}
										</span>
									</div>
								);
							})}
						</div> */}
					</>
				)}
				{job?.code && (
					<div className="d-flex align-items-center justify-content-center gap-2 fs-sm lh-base px-2">
						<FontAwesome
							icon="barcode-read"
							color={theme[userType].primaryColor}
							size="lg"
						/>
						<div className="lh-1">
							<span className="fw-300 me-2">
								{t('title.job.code')}:
							</span>
							{job.code}
						</div>
					</div>
				)}
			</div>
			{job.shortDescription && (
				<div className="text-gray-3 text">{job.shortDescription}</div>
			)}
		</div>
	);
};
