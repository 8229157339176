import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	BusinessPartnerProfileStatus,
	Colors,
	CommunityPartnerProfileStatus,
	ModalProps
} from 'Types';

import { Button, ModalBox, ModalBoxFooter, FontAwesome } from 'Elements';

import { useNavigate } from 'react-router-dom';

interface Props extends ModalProps {
	type: 'publish' | 'unPublish';
	profileId?: string;
	status?: number;
	partnerStatus?: number;
	userType: 'communityPartner' | 'businessPartner';
	onSubmit: () => void;
	refetch?: () => void;
	refetchPartnerMe?: () => void;
}
export const ModalBoxPublishJob: FC<Props> = ({
	isOpen,
	onClose,
	partnerStatus,
	type,
	onSubmit,
	userType
}) => {
	const { t } = useTranslation();
	const [disabled, setDisabled] = useState<boolean>(false);
	const navigate = useNavigate();

	useEffect(() => {
		setDisabled(false);
	}, [isOpen]);

	return (
		<ModalBox isOpen={isOpen} onClose={onClose}>
			{userType === 'businessPartner' ? (
				localStorage.getItem('showPm') === 'true' ? (
					<>
						<header className="d-flex flex-column align-items-center text-center">
							<>
								<FontAwesome
									icon={'lock'}
									size="2x"
									className="mb-4"
								/>
								<h4 className="h6 lh-base">
									{t(`text.you-can-not-publish-job-session`)}
								</h4>
								<p className="text-gray-3 lh-md">
									{t(`text.not-publish-job-sub-text`)}
								</p>
							</>
						</header>
						<ModalBoxFooter className="justify-content-between pt-3">
							<Button
								color={Colors['white-gray']}
								onClick={onClose}>
								{t('button.cancel')}
							</Button>
							<Button
								color={Colors['white-primary']}
								onClick={() => navigate('/subscriptions')}>
								{t('button.start-registration-process')}
							</Button>
						</ModalBoxFooter>
					</>
				) : (
					<>
						<header className="d-flex flex-column gap-2">
							<h2 className="fs-base fw-500">
								{type === 'publish'
									? t('button.publish')
									: t('button.unPublish')}
							</h2>
							<span className="fs-small fw-400">
								{type === 'publish'
									? t('text.header-publish-job-bp')
									: t('text.header-unpublish-job-bp')}
								?
							</span>
							<div
								className="d-flex d-flex align-items-center gap-3 bg-yellow_o-2 text-yellow p-2 mt-3"
								style={{ borderRadius: '8px' }}>
								<FontAwesome icon="circle-info" size="xl" />
								<span className="fs-sm fw-500">
									{type === 'publish'
										? t('text.massage-publish-job-bp')
										: t('text.massage-unpublish-job-bp')}
								</span>
							</div>
						</header>
						<ModalBoxFooter className="justify-content-between pt-3">
							<Button
								color={Colors['white-red']}
								onClick={onClose}>
								{t('button.cancel')}
							</Button>
							<Button
								disabled={disabled}
								className="gap-2"
								color={
									type === 'publish'
										? Colors.primary
										: Colors.red
								}
								onClick={() => {
									setDisabled(true);
									onSubmit();
								}}>
								{disabled ? (
									<>
										<FontAwesome
											spin
											spinPulse
											icon="spinner"
											size="lg"
										/>
										{type === 'publish'
											? t('button.publish')
											: t('button.unPublish')}
									</>
								) : type === 'publish' ? (
									t('button.publish')
								) : (
									t('button.unPublish')
								)}
							</Button>
						</ModalBoxFooter>
					</>
				)
			) : partnerStatus !== CommunityPartnerProfileStatus.Active ? (
				<>
					<header className="d-flex flex-column align-items-center text-center">
						<>
							<FontAwesome
								icon={'lock'}
								size="2x"
								className="mb-4"
							/>
							<h4 className="h6 lh-base">
								{t(`text.you-can-not-publish-job-session`)}
							</h4>
							<p className="text-gray-3 lh-md">
								{t(`text.not-publish-job-cp-text`)}
							</p>
						</>
					</header>
					<ModalBoxFooter className="justify-content-between pt-3">
						<Button color={Colors['white-gray']} onClick={onClose}>
							{t('button.cancel')}
						</Button>
					</ModalBoxFooter>
				</>
			) : (
				<>
					<header className="d-flex flex-column gap-2">
						<h2 className="fs-base fw-500">
							{type === 'publish'
								? t('button.publish')
								: t('button.unPublish')}
						</h2>
						<span className="fs-base fw-400">
							{type === 'publish'
								? t('text.header-publish-job-cp')
								: t('text.header-unpublish-job-cp')}
							?
						</span>
					</header>
					<ModalBoxFooter className="justify-content-between pt-3">
						<Button color={Colors['white-red']} onClick={onClose}>
							{t('button.cancel')}
						</Button>
						<Button
							disabled={disabled}
							color={
								type === 'publish' ? Colors.primary : Colors.red
							}
							onClick={() => {
								setDisabled(true);
								onSubmit();
							}}>
							{type === 'publish'
								? t('button.publish')
								: t('button.unPublish')}
						</Button>
					</ModalBoxFooter>
				</>
			)}
		</ModalBox>
	);
};
