import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorValues, Colors, ModalProps } from 'Types';
import {
	Button,
	ModalBox,
	ModalBoxFooter,
	SubmitButton,
	FontAwesome
} from 'Elements';

interface Props extends ModalProps {
	title: string;
	isRemoving: boolean;
	onRemove: () => void;
}

export const ModalBoxDelete: FC<Props> = memo(
	({ title, isOpen, isRemoving, onRemove, onClose }) => {
		const { t } = useTranslation();
		return (
			<ModalBox isOpen={isOpen} onClose={onClose}>
				<header className="d-flex flex-column align-items-center text-center">
					<FontAwesome
						icon="triangle-exclamation"
						size="8x"
						color={ColorValues.red}
						className="mb-3"
					/>
					<p>{t('text.are-you-sure-to-remove')}</p>
					<h6 className="h6 lh-base">{title}</h6>
					<p className="text-gray-3 mt-2">
						{t('text.this-action-can-not-be-undone')}
					</p>
				</header>
				<ModalBoxFooter className="justify-content-between pt-3">
					<Button
						color={Colors['white-gray']}
						label={t('button.no-thanks') as string}
						onClick={onClose}
					/>
					<SubmitButton
						icon="trash-xmark"
						color={Colors['white-red']}
						isSubmitting={isRemoving}
						label="button.yes-i-am-sure"
						savingLabel="button.removing"
						onClick={onRemove}
					/>
				</ModalBoxFooter>
			</ModalBox>
		);
	}
);

ModalBoxDelete.displayName = 'ModalBoxDelete';
