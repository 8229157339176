import { useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSubscriptionPlans } from 'Hooks';
import { containerVariants, names } from 'Constants';
import {
	Badge,
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableOperation,
	DataTableRow,
	DataTableRowCell,
	EndOfList,
	ListingPage,
	NoData,
	Pagination,
	SystemErrorAlert
} from 'Elements';
import { Colors } from 'src/types/Color';
import { currencyFormatter } from 'src/helpers/Utils';

export const ListSubscriptionPlans = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { subscriptionPlans } = names;
	// const { sendRequest, isLoading } = useAxios<string>();
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const [state, setState] = useState({
		pageSize: 10,
		currentPage: pageParam,
		keyword: queryParam
	});

	const { data, isFetching, isError } = useSubscriptionPlans({
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword,
		planType: ''
	});

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const handleSelectRow = (row_id: string) => {
		return navigate(`${subscriptionPlans.path}/${row_id}`, {
			state: { prevPath: subscriptionPlans.path }
		});
	};

	// const handleDeleteRow = async (row_id: string) => {
	// 	await sendRequest(
	// 		api.deleteInvitation(row_id),
	// 		{
	// 			method: 'delete'
	// 		},
	// 		() => {
	// 			refetch();
	// 		}
	// 	);
	// };

	return (
		<ListingPage
			totalItems={data?.totalItems}
			pageSizes={[5, 10, 20]}
			selectedPageSize={state.pageSize}
			onPageSizeChange={onChangePageSize}
			onSearchChange={handleSearch}>
			<DataTable>
				{data && data?.totalItems > 0 && (
					<DataTableHead>
						<DataTableRowCell
							cellText="table.title"
							cellClassName="w-100 w-sm-30"
						/>
						<DataTableRowCell
							cellText="table.price"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.duration"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.status"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.operation"
							cellClassName="w-100 w-sm-25"
						/>
					</DataTableHead>
				)}
				<DataTableBody>
					{!isFetching && (
						<motion.div
							variants={containerVariants}
							initial="hidden"
							animate="show">
							{data?.items?.map((subscriptionPlan) => {
								return (
									<DataTableRow key={subscriptionPlan.id}>
										<DataTableRowCell
											cellClassName="w-100 w-sm-30"
											cellHead="table.title">
											<div className="d-flex flex-column text-end text-sm-start">
												<h4
													className="fs-sm mb-1"
													title={
														subscriptionPlan.name
													}></h4>
												<span className="fs-sm fw-500 lh-base">
													{subscriptionPlan.name}
												</span>
												<span className="text-gray-3 lh-base">
													{subscriptionPlan.code}
												</span>
											</div>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.price">
											{currencyFormatter(
												subscriptionPlan?.price
											)}
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.duration">
											{`${subscriptionPlan.durationDays} ${t('label.days')}` ||
												'---'}
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.status">
											<Badge
												color={
													subscriptionPlan.active
														? Colors.green
														: Colors['gray-3']
												}>
												{t(
													subscriptionPlan.active
														? 'badge.active'
														: 'badge.inactive'
												)}
											</Badge>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-25"
											cellHead="table.operation">
											<DataTableOperation
											onEditRow={() =>
												handleSelectRow(
													subscriptionPlan.id
												)
											}
											></DataTableOperation>
										</DataTableRowCell>
									</DataTableRow>
								);
							})}
						</motion.div>
					)}
					{isFetching && (
						<DataTableLoading
							widths={[30, 15, 15, 15, 25]}
							count={state.pageSize}
						/>
					)}
				</DataTableBody>
				{!isFetching && data?.totalItems === 0 && (
					<NoData message="title.no-invitation-sent" />
				)}
			</DataTable>
			{!isFetching && <EndOfList data={data} />}
			{isError && <SystemErrorAlert />}
			{!isFetching && data && data?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={data?.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
		</ListingPage>
	);
};
