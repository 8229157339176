import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from 'react-modern-drawer';
import { Button, FontAwesome, LoadingContent } from 'src/containers/Elements';
import { useCompanyCommunityPartner } from 'src/hooks/useBusinessPartner';
import { Colors } from 'src/types/Color';
import styles from './find-partner.module.scss';
import PartnerCard from './PartnerCard';
import { useDebounce } from 'src/hooks/useDebounce';

interface Props {
	isOpen: boolean;
	toggleDrawer: () => void;
	isPremium: boolean;
}

const FindPartners: FC<Props> = ({
	isOpen,
	toggleDrawer,
	isPremium = false
}) => {
	const { t } = useTranslation();
	const [keyword, setKeyword] = useState('');
	const debouncedSearchTerm = useDebounce(keyword, 1000);

	//Todo: Implement infinite scroll
	const { data: communityPartner, isFetching } = useCompanyCommunityPartner(
		{
			pageSize: 200,
			pageNumber: 1,
			keyword: debouncedSearchTerm,
			regionCode: '',
			IsPremium: isPremium
		},
		{
			enabled: isOpen
		}
	);

	const handelKeywordChange = (e) => {
		setKeyword(e.target.value);
	};

	return (
		<Drawer
			style={{
				overflowY: 'auto'
			}}
			open={isOpen}
			onClose={toggleDrawer}
			lockBackgroundScroll
			zIndex={200}
			direction="right"
			size="28rem">
			<div className="border-bottom border-1 border-gray-2">
				<div className="d-flex justify-content-between gap-2 p-3 align-items-center">
					<span className="fs-6 fw-semibold">
						{t('Find community Partners')}
					</span>
					<div>
						<Button
							color={Colors['gray-7']}
							size="md"
							onClick={toggleDrawer}>
							<FontAwesome
								icon="close"
								size="lg"
								className="text-gray-3"
							/>
							<div className="mx-2 text-gray-3">{t('Close')}</div>
						</Button>
					</div>
				</div>
			</div>

			<div className="border-bottom border-1 border-gray-2 p-2">
				<form noValidate className="d-flex w-100 gap-2 ">
					<input
						onChange={handelKeywordChange}
						type="text"
						placeholder={t('Search  community partners')}
						className="inputbox w-100"
					/>
				</form>
			</div>

			<div className={styles['drawer-body']}>
				{communityPartner?.items.map((partner) => {
					return (
						<PartnerCard
							toggleDrawer={toggleDrawer}
							partner={partner}
							key={partner.id}
							isPremium={isPremium}
						/>
					);
				})}
			</div>

			{isFetching && <LoadingContent title="loading..." />}
		</Drawer>
	);
};

export default FindPartners;
