import { ButtonHTMLAttributes, FC, memo, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { clx } from 'Utils';
import { Colors, XOR } from 'Types';
import styles from 'partials/shared/button/button.module.scss';

export interface BaseProps {
	disabledClassName?: string;
	size?: 'sm' | 'md' | 'lg';
	color?: Colors;
}

export type TButtonProps = {
	as?: 'button';
} & ButtonHTMLAttributes<HTMLButtonElement>;

export type TLinkProps = {
	as: 'a';
} & LinkProps;

export type ButtonProps = XOR<{ children: ReactNode }, { label: string }> &
	BaseProps &
	(TButtonProps | TLinkProps);

const colors = {
	'border-white': styles.btn_border_white,
	'white-primary': styles.btn_white_primary,
	'white-secondary': styles.btn_white_secondary,
	'white-tertiary': styles.btn_white_tertiary,
	'white-gray': styles.btn_white_gray,
	'white-red': styles.btn_white_red,
	'white-green': styles.btn_white_green,
	'white-yellow': styles.btn_white_yellow,
	primary: styles.btn_primary,
	'primary-light': styles.btn_primary_light,
	'primary-dark': styles.btn_primary_dark,
	'border-primary': styles.btn_border_primary,
	'outline-primary': styles.btn_border_primary,
	secondary: styles.btn_secondary,
	'secondary-light': styles.btn_secondary_light,
	'secondary-dark': styles.btn_secondary_dark,
	'border-secondary': styles.btn_border_secondary,
	'outline-secondary': styles.btn_border_secondary,
	tertiary: styles.btn_tertiary,
	'tertiary-light': styles.btn_tertiary_light,
	'tertiary-dark': styles.btn_tertiary_dark,
	'border-tertiary': styles.btn_border_tertiary,
	'outline-tertiary': styles.btn_border_tertiary,
	yellow: styles.btn_yellow,
	'border-yellow': styles.btn_border_yellow,
	'outline-yellow': styles.btn_border_yellow,
	red: styles.btn_red,
	'border-red': styles.btn_border_red,
	'outline-red': styles.btn_border_red,
	green: styles.btn_green,
	'border-green': styles.btn_border_green,
	'outline-green': styles.btn_border_green,
	gray: styles.btn_gray_3,
	'border-gray': styles.btn_border_gray_3,
	'outline-gray': styles.btn_border_gray_3,
	'gray-1': styles.btn_gray_1,
	'border-gray-1': styles.btn_border_gray_1,
	'outline-gray-1': styles.btn_border_gray_1,
	'gray-2': styles.btn_gray_2,
	'border-gray-2': styles.btn_border_gray_2,
	'outline-gray-2': styles.btn_border_gray_2,
	'gray-3': styles.btn_gray_3,
	'border-gray-3': styles.btn_border_gray_3,
	'outline-gray-3': styles.btn_border_gray_3,
	'gray-4': styles.btn_gray_4,
	'border-gray-4': styles.btn_border_gray_4,
	'outline-gray-4': styles.btn_border_gray_4,
	'gray-5': styles.btn_gray_5,
	'border-gray-5': styles.btn_border_gray_5,
	'outline-gray-5': styles.btn_border_gray_5,
	'gray-6': styles.btn_gray_6,
	'gray-7': styles.btn_gray_7,
	'border-gray-6': styles.btn_border_gray_6,
	'outline-gray-6': styles.btn_border_gray_6,
	muted: styles.btn_gray_2,
	success: styles.btn_green,
	warning: styles.btn_yellow,
	error: styles.btn_red,
	danger: styles.btn_red,
	empty: styles.btn_empty
};

export const Button: FC<ButtonProps> = memo(
	({
		className,
		disabledClassName,
		label,
		size = 'md',
		color = Colors.primary,
		...props
	}) => {
		const getClassNameBySize = () => {
			const sizes = {
				sm: styles.btn_sm,
				md: styles.btn_md,
				lg: styles.btn_lg
			};
			return sizes[size];
		};

		const getClassNameByColor = () => {
			return colors[color];
		};

		const btnClass = clx(
			styles.btn,
			className,
			getClassNameBySize(),
			getClassNameByColor(),
			disabledClassName
		);

		if (props.as === 'a') {
			const { ...rest } = props;
			return (
				<Link className={btnClass} {...rest}>
					{label || props.children}
				</Link>
			);
		}

		const { ...rest } = props;
		return (
			<button
				type="button"
				className={btnClass}
				aria-disabled={rest.disabled}
				title={props.title}
				{...rest}>
				{label || props.children}
			</button>
		);
	}
);
Button.displayName = 'Button';
