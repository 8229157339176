import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { AvatarController, SwitchController, Card } from 'Elements';
import {
	SubscriptionPlanContactPersonDetails,
	SubscriptionPlanDetails
} from 'Types';
import styled from 'styled-components';

const CardBox = styled(Card)`
	min-height: 286px;
`;

export const SubscriptionPlanContactPerson: FC = () => {
	const descriptionLimit = 480;
	const { t } = useTranslation();
	const fields = useOutletContext<SubscriptionPlanDetails>();
	const {
		control,
		register,
		watch,
		setValue,
		formState: { errors }
	} = useFormContext<{
		contactPerson: SubscriptionPlanContactPersonDetails;
	}>();

	const watchImage =
		watch('contactPerson.avatarUrl') ??
		fields?.contactPerson?.avatarUrl ??
		'';

	const setImageValue = (value: string) => {
		setValue('contactPerson.avatarUrl', value, { shouldDirty: true });
	};

	return (
		<CardBox className="h-100">
			<Row>
				<Col sm={5} xl={4}>
					<FormGroup>
						<AvatarController
							name="contactPerson.avatarUrl"
							control={control}
							image={watchImage}
							onRemove={() => setImageValue('')}
							onUpload={(image_data) =>
								setImageValue(image_data.url)
							}
						/>
					</FormGroup>
				</Col>
				<Col sm={7} xl={8}>
					<Row>
						<Col xl={9} xxl={7}>
							<FormGroup>
								<Label htmlFor="fullName">
									{t('forms.plan-price.fullName')}
								</Label>
								<input
									{...register('contactPerson.fullName')}
									type="text"
									id="fullName"
									aria-invalid={
										!!errors.contactPerson?.fullName
									}
									className="inputbox w-100"
								/>
								{errors.contactPerson?.fullName && (
									<div className="invalid-feedback d-block">
										{errors.contactPerson.fullName.message?.toString()}
									</div>
								)}
							</FormGroup>
							<FormGroup className="position-relative">
								<Label htmlFor="description">
									{t('forms.plan-price.description')}
								</Label>
								<textarea
									{...register('contactPerson.description', {
										maxLength: descriptionLimit
									})}
									id="description"
									className="inputbox w-100"
									cols={10}
									rows={5}
									maxLength={descriptionLimit}
								/>
								<div className="position-absolute end-0">
									<small className="ms-auto mt-1">
										{watch('contactPerson.description')
											?.length || 0}
										/{descriptionLimit}
									</small>
								</div>
							</FormGroup>
							<FormGroup>
								<SwitchController
									name="contactPerson.published"
									control={control}
									defaultChecked={
										fields?.contactPerson?.published ??
										false
									}
									label="forms.plan-price.show-in-public"
									boxClassName="py-2"
								/>
							</FormGroup>
						</Col>
					</Row>
				</Col>
			</Row>
		</CardBox>
	);
};
