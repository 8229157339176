import { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { featureIconList } from 'Data';
import { AgentPlan, ColorValues, Colors, SubscriptionPlanDetails } from 'Types';
import {
	Button,
	FontAwesome,
	NoData,
	Card,
	SelectController,
	LoadingSpinner
} from 'Elements';
import styled from 'styled-components';
import { useSubscriptionPlansFeature } from 'src/hooks/useSubscription';
import { usePrompt } from 'src/hooks/usePrompt';

const CardBox = styled(Card)`
	min-height: 286px;
`;

export const SubscriptionPlanFeatures: FC = () => {
	const { t } = useTranslation();
	const {
		register,
		watch,
		formState: { errors }
	} = useFormContext<SubscriptionPlanDetails>();

	// usePrompt(t('forms.leave-screen-massage'), isDirty);

	const { data: subscriptionPlansFeature, isLoading: isLoadingPlansFeature } =
		useSubscriptionPlansFeature(
			watch('subscriptionPlanFeatureId') as string,
			watch('id') as string,
			{
				enabled: !!watch('subscriptionPlanFeatureId')
			}
		);

	return (
		<CardBox className="h-100">
			<Row className="border-bottom pb-3">
				<Col>
					<FormGroup>
						<Label htmlFor="publishedJobs">
							{t('forms.max-job-capacity')}
							<small className="ms-1">
								({t('validation.required')})
							</small>
						</Label>
						<input
							{...register('publishedJobs')}
							type="text"
							id="max-job-capacity"
							aria-invalid={!!errors.publishedJobs}
							className="inputbox w-100"
						/>
						{errors.publishedJobs && (
							<div className="invalid-feedback d-block">
								{errors.publishedJobs.message}
							</div>
						)}
					</FormGroup>
					<FormGroup>
						<Label htmlFor="publishedInternships">
							{t('forms.max-internship-capacity')}
							<small className="ms-1">
								({t('validation.required')})
							</small>
						</Label>
						<input
							{...register('publishedInternships')}
							type="text"
							id="publishedInternships"
							aria-invalid={!!errors.publishedInternships}
							className="inputbox w-100"
						/>
						{errors.publishedInternships && (
							<div className="invalid-feedback d-block">
								{errors.publishedInternships.message}
							</div>
						)}
					</FormGroup>
				</Col>
				<Col></Col>
			</Row>
			<Row className="mt-4">
				{isLoadingPlansFeature && <LoadingSpinner />}
				{!isLoadingPlansFeature && subscriptionPlansFeature && (
					<>
						<span className="fs-sm fw-500 mb-3">
							{t("text.other-features")}
						</span>
						<div
							className="border p-4"
							style={{ borderRadius: '8px' }}>
							<Row>
								{subscriptionPlansFeature?.eventSetting
									?.eventsEnabled && (
									<Col sm={12} xl={6}>
										<div className="d-flex align-items-center gap-2 mb-3">
											<FontAwesome
												icon="square-check"
												size="lg"
												className="flex-shrink-0 text-secondary"
											/>
											<div>
												{t(
													'text.feature.events-enabled'
												)}
												:
											</div>
											<div>
												{subscriptionPlansFeature
													?.eventSetting
													?.eventsEnabled
													? t('label.yes')
													: t('label.no')}
											</div>
										</div>
									</Col>
								)}

								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t(
												'text.feature.max-publish-events'
											)}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.eventSetting
												?.maxPublishEvents ?? 0}
										</div>
									</div>
								</Col>

								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t(
												'text.feature.publish-events-enabled'
											)}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.eventSetting
												?.publishEventsEnabled
												? t('label.yes')
												: t('label.no')}
										</div>
									</div>
								</Col>

								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t(
												'text.feature.internship-enabled'
											)}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.internshipSetting
												?.internshipEnabled
												? t('label.yes')
												: t('label.no')}
										</div>
									</div>
								</Col>

								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t(
												'text.feature.internship-manager-enabled'
											)}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.internshipSetting
												?.internshipManagerEnabled
												? t('label.yes')
												: t('label.no')}
										</div>
									</div>
								</Col>

								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t(
												'text.feature.max-publish-internships'
											)}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.internshipSetting
												?.maxPublishInternships ?? 0}
										</div>
									</div>
								</Col>

								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t(
												'text.feature.publish-internship-enabled'
											)}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.internshipSetting
												?.publishInternshipEnabled
												? t('label.yes')
												: t('label.no')}
										</div>
									</div>
								</Col>
								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t('text.feature.job-enabled')}:
										</div>
										<div>
											{subscriptionPlansFeature
												?.jobSetting?.jobEnabled
												? t('label.yes')
												: t('label.no')}
										</div>
									</div>
								</Col>
								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t(
												'text.feature.jobs-google-index'
											)}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.jobSetting?.jobsGoogleIndex
												? t('label.yes')
												: t('label.no')}
										</div>
									</div>
								</Col>
								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t(
												'text.feature.limit-job-visibility'
											)}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.jobSetting?.limitJobVisibility
												? t('label.yes')
												: t('label.no')}
										</div>
									</div>
								</Col>
								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t('text.feature.max-publish-jobs')}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.jobSetting?.maxPublishJobs ??
												0}
										</div>
									</div>
								</Col>
								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t(
												'text.feature.publish-job-enabled'
											)}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.jobSetting?.publishJobEnabled
												? t('label.yes')
												: t('label.no')}
										</div>
									</div>
								</Col>
								<Col sm={12} xl={6}>
									<div className="d-flex align-items-center gap-2 mb-3">
										<FontAwesome
											icon="square-check"
											size="lg"
											className="flex-shrink-0 text-secondary"
										/>
										<div>
											{t(
												'text.feature.max-user-accounts'
											)}
											:
										</div>
										<div>
											{subscriptionPlansFeature
												?.userAccountSetting
												?.maxUserAccounts ?? 0}
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</>
				)}
			</Row>
		</CardBox>
	);
};

export default SubscriptionPlanFeatures;
