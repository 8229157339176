import { FC, useEffect, useState } from 'react';
import { setSubscriptionPlan } from 'Actions';
import { Colors, SubscriptionCommunityPartner, SubscriptionPlan } from 'Types';
import { useAxios, useDispatch } from 'Hooks';
import {
	FontAwesome,
	NoData,
	Button,
	StepNavigation,
	SubscriptionPlanCard,
	SubscriptionCommunityPartnerCard,
	SkeletonWrapper,
	LoadingMask
} from 'Elements';
import 'swiper/css';
import 'swiper/css/navigation';
import styles from 'partials/step/step-container.module.scss';
import { useTranslation } from 'react-i18next';
import { SwiperSlide, Swiper } from 'swiper/react';
import '../../../../../../assets/css/plan-sub.css';

// import required modules
import { Navigation } from 'swiper/modules';
import { api } from 'src/helpers/API';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { clx } from 'src/helpers/Utils';
import { Row } from 'reactstrap';

interface Props {
	fields: SubscriptionPlan | null;
	selectedCommunityPartner?: SubscriptionCommunityPartner | null;
	onPrev: () => void;
	onNext: () => void;
	goToStep: (stepId: string) => void;
}

export const CreateSubscriptionPlan: FC<Props> = ({
	fields,
	selectedCommunityPartner,
	onPrev,
	onNext,
	goToStep
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { sendRequest } = useAxios<any>();
	const [loading, setLoading] = useState(true);
	const [loadingPlansFeature, setLoadingPlansFeature] = useState(false);
	const [selectedCP, setSelectedCP] =
		useState<SubscriptionCommunityPartner>();
	const [searchParams] = useSearchParams();
	const communityPartnerId = searchParams.get('communityPartnerId') || '';
	const handleSelectPlan = (plan: SubscriptionPlan) => {
		dispatch(setSubscriptionPlan(plan));
		onNext();
		navigate(
			`/subscriptions/add?step=step3&communityPartnerId=${selectedCP?.id}&planId=${plan?.id}&planType=${plan?.type}`
		);
	};

	const navigationUrl = '';
	// `${config.publicUrl}/agent/${agent.agentId}/${agent.slugUrl}`;

	const breakpoints = {
		640: {
			slidesPerView: 1,
			spaceBetween: 20
		},
		768: {
			slidesPerView: 1,
			spaceBetween: 40
		},
		900: {
			slidesPerView: 2,
			spaceBetween: 50
		}
	};

	const getCommunityPartner = () => {
		sendRequest(
			api.getCommunityPartner(communityPartnerId),
			{ method: 'GET' },
			(data) => {
				getPlanData(data);
			}
		);
	};

	const Skeleton = () => {
		return (
			<div
				className={clx(
					'd-flex flex-column gap-3',
					styles.cardCommunityPartner
				)}>
				<LoadingMask />
				<div className="d-flex gap-2">
					<div className={styles.logoCommunityPartner}></div>
					<div className="py-2 d-flex flex-column gap-3">
						<div className={styles.labelHeder}></div>
						<div className={styles.badgeSkeleton}></div>
						<div className={styles.labelText}></div>
						<div className={styles.labelText}></div>
					</div>
				</div>
				<div className={styles.labelText}></div>
				<div className={styles.labelDescription}></div>
				<div className={styles.labelDescription}></div>
				<div className={styles.labelDescription}></div>
			</div>
		);
	};

	const SkeletonPlans = () => {
		return (
			<div
				className={clx(
					'd-flex flex-column justify-content-between',
					styles.cardPlanSkeleton
				)}>
				<div className="d-flex flex-column align-items-start gap-3 pt-0">
					<LoadingMask />
					<div className={styles.labelHeder}></div>
					<div className={styles.labelprice}></div>
					<div className={styles.labelText}></div>
					<div className={styles.labelDescription}></div>
					<div className={styles.labelDescription}></div>
					<div className={styles.labelDescription}></div>
					<div className={styles.labelDescription}></div>
					<div className={styles.labelDescription}></div>
				</div>
				<div className={styles.labelButton}></div>
			</div>
		);
	};

	const getPlanData = (communityPartner) => {
		const planData: any = [];
		if (communityPartner) {
			sendRequest(
				api.getPlansCommunityPartner(communityPartner?.id),
				{ method: 'GET' },
				(data) => {
					const urls = data?.map(
						(plan: any) =>
							plan.active &&
							sendRequest(
								api.getSubscriptionPlansFeature(
									plan?.subscriptionPlanFeatureId,
									plan?.id
								),
								{ method: 'GET' },
								(featureData) =>
									planData.push({ featureData, ...plan })
							)
					);
					Promise.all(urls).finally(() => {
						setSelectedCP({ ...communityPartner, planData });
						setLoadingPlansFeature(false);
						setLoading(false);
					});
				}
			);
		}
	};

	useEffect(() => {
		if (!selectedCommunityPartner) {
			getCommunityPartner();
		} else {
			getPlanData(selectedCommunityPartner);
		}
	}, [selectedCommunityPartner]);

	return (
		<div className={styles.form}>
			<div className="d-flex align-items-center justify-content-center">
				<h2 className="fs-6 fw-500">{t('title.selected-agent')}</h2>
			</div>
			<div className="position-relative">
				{!loading && (
					<>
						<SubscriptionCommunityPartnerCard
							key={selectedCP?.id}
							communityPartner={
								selectedCP as SubscriptionCommunityPartner
							}
							selected={false}
						/>
						<Button
							color={Colors['white-primary']}
							className={styles.edit}
							onClick={() => {
								navigate(`/subscriptions/add?step=step1`);
								goToStep('step1');
							}}>
							<FontAwesome icon="edit" />
							{t('text.change')}
						</Button>
					</>
				)}
				{loading && (
					<Row className="position-relative">
						<SkeletonWrapper
							skeleton={Skeleton}
							count={1}
							wrapperClassName="row"
						/>
					</Row>
				)}
			</div>
			<div className="text-center gap-2 mt-4">
				<h3 className="fw-500 fs-base text-gray-4">
					{t('text.technikerschcule-grundig-akademie-plans')}
				</h3>
				<p className="fs-small fw-400 text-gray-4">
					{t('text.description-choose-plan')}
				</p>
			</div>
			{!loading && (
				<div className={styles.boxSwiper}>
					{selectedCP?.planData && selectedCP?.planData?.length > 0 && (
						<div>
							<Swiper
								breakpoints={breakpoints}
								navigation={true}
								modules={[Navigation]}
								className={styles.mySwiper}>
								{selectedCP?.planData?.map((plan) => (
									<SwiperSlide
										key={plan.id}
										className={clx(
											styles.swiperSlide,
											plan?.isRecommended &&
												styles.selected,
											selectedCP?.planData?.length ===
												1 && styles.width100
										)}>
										<SubscriptionPlanCard
											key={plan.id}
											card={plan}
											selectedCommunityPartnerId={
												selectedCP?.id
											}
											loadingPlansFeature={
												loadingPlansFeature
											}
											selected={fields?.id === plan.id}
											onSelect={handleSelectPlan}
										/>
									</SwiperSlide>
								))}
							</Swiper>
						</div>
					)}
				</div>
			)}
			{!loading && selectedCP?.planData?.length === 0 && (
				<NoData message="title.no-subscription-plan" />
			)}
			{loading && (
				<Row className="position-relative">
					<div className="d-flex align-items-center gap-5 w-100 px-5">
						{[1, 2, 3].map((item) => {
							return (
								<SkeletonWrapper
									key={item}
									count={1}
									wrapperClassName="col"
									skeleton={SkeletonPlans}
								/>
							);
						})}
					</div>
				</Row>
			)}
			<h2 className="fw-500 fs-6 mt-4 text-gray-4">
				{t('text.upgrade-rules')}:
			</h2>
			<p className="fw-400 fs-small mb-2 text-gray-4">
				{t('text.can-upgraded-any-time')}
			</p>
			<p className="fw-400 fs-small mb-4 text-gray-4">
				{t('text.description-upgrade-rules')}
			</p>
			<h2 className="fw-500 fs-6 text-gray-4">
				{t('text.upgrade-and-switch-CP')}
			</h2>
			<p className="fw-400 fs-small text-gray-4">
				{t('text.description-upgrade-and-switch-CP')}
			</p>
			<p className="fw-500 fs-small text-gray-4">
				{t('text.description-upgrade-and-switch-CP-2')}
			</p>
			<StepNavigation onPrev={onPrev} showNext={false} />
		</div>
	);
};
