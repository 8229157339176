import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
	ColorValues,
	Colors,
	ModalProps,
	SubscriptionCalculate,
	SubscriptionPaymentStatus
} from 'Types';
import { Button, ModalBox, ModalBoxFooter, FontAwesome } from 'Elements';
import { useNavigate } from 'react-router-dom';
import styles from 'partials/step/step-container.module.scss';
import { currencyFormatter } from 'src/helpers/Utils';

interface Props extends ModalProps {
	subscriptionCalculate: SubscriptionCalculate | any;
	subscriptionId?: string;
	subscriptionPaymentStatus?: SubscriptionPaymentStatus;
}

export const ModalBoxPaymentResult: FC<Props> = ({
	isOpen,
	subscriptionCalculate,
	subscriptionPaymentStatus,
	subscriptionId,
	onClose
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const getStatus = (status: SubscriptionPaymentStatus) => {
		switch (status) {
			case 0:
				return {
					title: t('badge.pending'),
					icon: 'loader',
					description: t('text.payment.pending-description'),
					color: ColorValues.yellow,
					bgColor: '#ff8c004d'
				};
			case 1: {
				return {
					title: t('badge.payment-successfully'),
					icon: 'check',
					description: t(
						'text.payment.payment-successfully-description'
					),
					color: ColorValues.primary,
					bgColor: '#DBEFDC'
				};
			}
			case 2:
				return {
					title: t('badge.payment-not-successfully'),
					icon: 'close',
					description: t(
						'text.payment.payment-not-successfully-description'
					),
					color: ColorValues.red,
					bgColor: '#FDD9D7'
				};
			case 3:
				return {
					title: t('badge.processing'),
					icon: 'loader',
					description: t('text.payment.processing-description'),
					color: ColorValues['gray-3'],
					bgColor: 'linear-gradient(180deg, #EFEFF2 0%, #F8F8F9 100%)'
				};
			case 4:
				return {
					title: t('badge.payment-canceled'),
					icon: 'close',
					description: t('text.payment.payment-canceled-description'),
					color: ColorValues.red,
					bgColor: '#FDD9D7'
				};
			case 5:
				return {
					title: t('badge.payment-successfully'),
					icon: 'check',
					description: t(
						'text.payment.payment-successfully-PendingSepa-description'
					),
					color: ColorValues.primary,
					bgColor: '#DBEFDC'
				};
			case 6:
				return {
					title: t(
						'badge.request-subscription-submitted-successfully'
					),
					icon: 'check',
					description: (
						<div className="d-flex gap-2 fs-6 fw-500 align-items-center">
							<FontAwesome
								icon="clock"
								size="lg"
								className="text-gray-3"
							/>
							<div>
								{t(
									'text.payment.request-subscription-submitted-successfully-description'
								)}
							</div>
						</div>
					),
					color: ColorValues.primary,
					bgColor: '#DBEFDC'
				};
			case 7:
				return {
					title: t('badge.payment-voucher100'),
					icon: 'check',
					description: t(
						'text.payment.payment-voucher100-description'
					),
					color: ColorValues.primary,
					bgColor: '#DBEFDC'
				};
			default:
				return {
					title: t('badge.processing'),
					icon: 'loader',
					description: t('text.payment.processing-description'),
					color: ColorValues['gray-3'],
					check: 'processing',
					bgColor: 'linear-gradient(180deg, #EFEFF2 0%, #F8F8F9 100%)'
				};
		}
	};

	return (
		<ModalBox isOpen={isOpen}>
			<div className="d-flex align-items-center gap-2 mb-4">
				<div>
					<FontAwesome
						icon={'credit-card'}
						className={'text-primary'}
						size="2x"
						swapOpacity
					/>
				</div>
				<div>
					<span className="fw-500 fs-base">
						{t('text.payment-confirmation')}
					</span>
				</div>
			</div>
			<div
				style={
					subscriptionPaymentStatus === 6
						? {
								padding: '10px',
								borderRadius: '8px',
								background:
									'linear-gradient(180deg, #EFEFF2 0%, #F8F8F9 100%)'
						  }
						: {}
				}>
				<div
					className={`d-flex align-items-center gap-2 ${styles.boxPaymentResult}`}
					style={{
						background: getStatus(
							subscriptionPaymentStatus as SubscriptionPaymentStatus
						).bgColor
					}}>
					<FontAwesome
						icon={
							getStatus(
								subscriptionPaymentStatus as SubscriptionPaymentStatus
							).icon
						}
						size="2x"
						color={
							getStatus(
								subscriptionPaymentStatus as SubscriptionPaymentStatus
							).color
						}
						className={
							subscriptionPaymentStatus === 3 ||
							subscriptionPaymentStatus === -1
								? 'fa-spin'
								: ''
						}
					/>
					<h1
						className={`fw-500 fs-5 pt-2`}
						style={{
							color: getStatus(
								subscriptionPaymentStatus as SubscriptionPaymentStatus
							).color
						}}>
						{
							getStatus(
								subscriptionPaymentStatus as SubscriptionPaymentStatus
							).title
						}
					</h1>
				</div>
				<div className="px-2">
					<p className="fs-small fw-400 text-gray-3">
						{
							getStatus(
								subscriptionPaymentStatus as SubscriptionPaymentStatus
							).description
						}
					</p>
				</div>
			</div>
			<div className="my-4 pb-3">
				<div className="d-flex">
					<div className="d-flex flex-column text-end pe-3 me-3 border-end border-2 text-gray-3">
						<h5 className="h5 mb-0">
							{currencyFormatter(
								subscriptionCalculate?.totalAmount ?? 0
							)}
						</h5>
						<span className="fw-300">
							{t('title.excluded-tax')}
						</span>
					</div>
					<div className="text-gray-3 text-start d-flex flex-column">
						<span className="fw-300 lh-base">{t('title.per')}</span>
						<span className="mt-auto fs-base">
							{`${
								subscriptionCalculate?.subscriptionDuration ??
								''
							} ${t('title.days')}`}
						</span>
					</div>
				</div>
			</div>
			<ModalBoxFooter className="pt-3">
				{subscriptionPaymentStatus === 3 ||
				subscriptionPaymentStatus === -1 ? (
					<span className="mx-auto fs-5 fw-500">
						{t('text.please-wait')}
					</span>
				) : (
					<Button
						color={Colors['secondary']}
						label={t('button.go-to-subscription-plans') as string}
						onClick={() => {
							onClose && onClose();
							navigate(`/subscriptions/${subscriptionId}`);
						}}
						className="w-100"
					/>
				)}
			</ModalBoxFooter>
		</ModalBox>
	);
};
