import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatLocaleDateTime } from 'Utils';
import { ShimmerImage } from 'Elements';
import { InvitationRegistrant } from 'Types';
import styles from 'partials/decoration/profile-decoration-box.module.scss';

interface Props {
	registrants: InvitationRegistrant[];
}

export const Registrant: FC<Props> = memo(({ registrants }) => {
	const { t } = useTranslation();
	const defaultImage = '/assets/img/business-partner-default.png';
	
	return (
		<div>
			{registrants?.map((partner) => {
				return (
					<div
						key={partner.signedUpCompanyIdentifier}
						className="d-flex align-items-center">
						<div className={styles.logo}>
							<div className={styles.logoBox}>
								<ShimmerImage
									width={160}
									height={160}
									src={
										partner.signedUpCompanyLogo ||
										defaultImage
									}
									defaultImage={defaultImage}
									alt={partner.signedUpCompanyName}
									className={styles.shimmer}
								/>
							</div>
						</div>
						<div className="flex-grow-1">
							<h3 className="fs-5 fw-400">
								{partner.signedUpCompanyName}
							</h3>
							<p className="text-gray-3">
								{partner.signedUpCompanyEmail}
							</p>
							<p className="d-flex align-items-center mb-0">
								<span>{t('label.signup-date')}:</span>
								<time
									title={formatLocaleDateTime(
										partner.signUpDate
									)}
									dateTime={formatLocaleDateTime(
										partner.signUpDate
									)}
									className="ms-2">
									{formatLocaleDateTime(partner.signUpDate)}
								</time>
							</p>
						</div>
					</div>
				);
			})}
		</div>
	);
});

Registrant.displayName = 'Registrant';
