import { FC, memo } from 'react';
import { Col } from 'reactstrap';
import { AgentPartner } from 'Types';
import { ShimmerImage } from 'Elements';
import styles from 'partials/homepage/card/partner-card.module.scss';

interface Props {
	partner: AgentPartner;
}

export const AgentPartnerCard: FC<Props> = memo(({ partner }) => {
	const companyName = partner?.company?.name;
	const setting = partner?.company;
	const defaultLogo = '/assets/img/business-partner-default.png';

	return (
		<Col sm={6} md={4} lg={3} className="mb-4">
			<article className={styles.card}>
				<div className={styles.logoWrapper}>
					<ShimmerImage
						width={500}
						height={200}
						src={setting?.logo || defaultLogo}
						defaultImage={defaultLogo}
						alt={companyName}
						className={styles.logo}
					/>
				</div>
				<div className="position-relative d-flex flex-column align-items-center text-center px-2 pb-4 gap-2 w-100 mt-auto">
					<h4 className="fs-6 fw-bold lh-base mb-0">{companyName}</h4>
					{setting?.branch && (
						<span className="fw-light">{setting?.branch}</span>
					)}
				</div>
			</article>
		</Col>
	);
});

AgentPartnerCard.displayName = 'AgentPartnerCard';
