import { memo, FC } from 'react';
import styled from 'styled-components';
import { XOR } from 'Types';
import { clx } from 'Utils';
import { IconPlayer } from 'Elements';
import styles from 'partials/feature/video-thumb.module.scss';

interface SharedProps {
	imageUrl?: string;
	className?: string;
	onPlay?: () => void;
}

type Props = SharedProps & XOR<{ videoId: string }, { videoUrl: string }>;

const Background = styled.div<{ $url: string }>`
	${({ $url }) => `
		background-image: url(${$url});
	`}
`;

export const VideoThumb: FC<Props> = memo(
	({
		videoId,
		videoUrl,
		imageUrl = '/assets/img/default-image.png',
		className,
		onPlay
	}) => {
		const hasVideo = videoId || videoUrl;

		const handlePlay = () => {
			if (hasVideo) {
				onPlay?.();
			}
		};

		return (
			<div
				className={clx(
					styles.container,
					hasVideo && 'cursor-pointer',
					className
				)}
				onClick={handlePlay}>
				<Background $url={imageUrl} className={styles.image} />
				{hasVideo && (
					<div className={styles.player_icon}>
						<IconPlayer />
					</div>
				)}
			</div>
		);
	}
);

VideoThumb.displayName = 'VideoThumb';
