import { useState, startTransition, useEffect } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { AspectRatio, EmployeeForm, Media } from 'Types';
import { useModal, usePrompt, useUpdateEmployee } from 'Hooks';
import {
	Card,
	SubmitButtonGroup,
	SwitchController,
	AvatarController,
	LoadingContent,
	ModalBoxDelete
} from 'Elements';

export const UpdateEmployee = () => {
	const descriptionLimit = 270;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { employee_id } = useParams();
	const [openModal, toggleModal] = useModal();
	const [image, setImage] = useState({ url: '', thumbnail: '' });
	const [isRemoving, setIsRemoving] = useState(false);

	const {
		isFetching,
		employee: fields,
		updateEmployee,
		removeEmployee
	} = useUpdateEmployee(employee_id as string);

	const schema = yup.object({
		fullName: yup.string().required(t('validation.employee-name.required'))
	});

	const {
		register,
		control,
		reset,
		watch,
		handleSubmit,
		setValue,
		formState: { errors, isDirty, isSubmitting }
	} = useForm<EmployeeForm>({
		resolver: yupResolver(schema)
	});

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const handleRemoveImage = async () => {
		setImage({
			url: '',
			thumbnail: ''
		});
		setValue(
			'image',
			{
				url: '',
				thumbnail: ''
			},
			{ shouldDirty: true }
		);
	};

	const handleRemoveUser = async () => {
		try {
			setIsRemoving(true);
			await removeEmployee(() => {
				startTransition(() => {
					setIsRemoving(false);
					void navigate('/employees');
				});
			});
		} finally {
			setIsRemoving(false);
		}
	};

	const handleUploadImage = async (image_data: Media) => {
		if (image_data) {
			setImage({
				url: image_data.url,
				thumbnail: image_data.thumbnailUrl
			});
			setValue(
				'image',
				{
					url: image_data.url,
					thumbnail: image_data.thumbnailUrl
				},
				{ shouldDirty: true }
			);
		}
	};

	const onSubmit: SubmitHandler<EmployeeForm> = async (data) => {
		await updateEmployee({
			...data,
			image: !image?.url ? null : image
		});
	};

	useEffect(() => {
		reset({
			...fields,
			description: fields?.description.substring(0, descriptionLimit)
		});
		setImage({
			url: fields?.image?.url as string,
			thumbnail: fields?.image?.thumbnail as string
		});
	}, [reset, fields]);

	return (
		<Card>
			<Row>
				<Col sm={5} xl={4}>
					<FormGroup>
						<AvatarController
							name="image"
							aspectRatio={AspectRatio['3/4']}
							control={control}
							image={image.url ?? fields?.image?.url}
							onRemove={handleRemoveImage}
							onUpload={handleUploadImage}
						/>
					</FormGroup>
				</Col>
				<Col sm={7} xl={8}>
					<form onSubmit={handleSubmit(onSubmit)} noValidate>
						<Row>
							<Col xl={9} xxl={7}>
								<FormGroup>
									<Label htmlFor="fullName">
										{t('forms.employee-name')}
										<small className="ms-1">
											({t('validation.required')})
										</small>
									</Label>
									<input
										{...register('fullName')}
										type="text"
										id="fullName"
										aria-invalid={!!errors.fullName}
										className="inputbox w-100"
									/>
									{errors.fullName && (
										<div className="invalid-feedback d-block">
											{errors.fullName.message?.toString()}
										</div>
									)}
								</FormGroup>
								<FormGroup>
									<Label htmlFor="jobPosition">
										{t('forms.job-title')}
									</Label>
									<input
										{...register('jobPosition')}
										type="text"
										id="jobPosition"
										className="inputbox w-100"
									/>
								</FormGroup>
								<FormGroup className="position-relative">
									<Label htmlFor="description">
										{t(
											'forms.business-partner.employee-desc'
										)}
									</Label>
									<textarea
										{...register('description', {
											maxLength: descriptionLimit
										})}
										id="description"
										className="inputbox w-100"
										cols={10}
										rows={5}
										aria-invalid={!!errors.description}
										maxLength={descriptionLimit}
									/>
									<div className="position-absolute end-0">
										<small className="ms-auto mt-1">
											{watch('description')?.length || 0}/
											{descriptionLimit}
										</small>
									</div>
								</FormGroup>
								<FormGroup>
									<SwitchController
										name="showInHomePage"
										control={control}
										label="forms.show-in-home"
									/>
								</FormGroup>
								<SubmitButtonGroup
									isDisable={!isDirty}
									isSubmitting={isSubmitting}
									onRemove={toggleModal}
									removeTitle="button.remove"
									isRemoving={isRemoving}
								/>
							</Col>
						</Row>
					</form>
				</Col>
			</Row>
			<ModalBoxDelete
				isOpen={openModal}
				onClose={toggleModal}
				onRemove={handleRemoveUser}
				isRemoving={isRemoving}
				title={fields?.fullName ?? ''}
			/>
			{isFetching && <LoadingContent />}
		</Card>
	);
};
