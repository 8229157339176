import { startTransition } from 'react';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import axios from 'Adapter';
import { useAxios } from 'Hooks';
import { queryKey } from 'Constants';
import { api, fetch, toastify } from 'Utils';
import {
	businessPartnerLogo,
	BusinessPartnerProfile,
	BusinessPartnerProfileFormData,
	BusinessPartnerProfileInitialData,
	CompanySetting,
	DataList,
	DataQueryParams,
	LinkedinConfig,
	PaymentMethod,
	SubscriptionAgent,
	SubscriptionCommunityPartner
} from 'Types';

const config = (options = {}) => {
	return {
		...options,
		staleTime: Infinity
	};
};

export const useBusinessPartnerMe = <T = BusinessPartnerProfileInitialData>(
	options?: UseQueryOptions<T, any, BusinessPartnerProfileInitialData>
): UseQueryResult<BusinessPartnerProfileInitialData, unknown> => {
	return useQuery({
		queryKey: [queryKey.businessPartnerMe],
		queryFn: () => fetch.businessPartnerMe(),
		...config(options)
	});
};

export const useBusinessPartnerProfile = <T = BusinessPartnerProfile>(
	businessPartnerProfileId: string,
	options?: UseQueryOptions<T, any, BusinessPartnerProfile>
): UseQueryResult<BusinessPartnerProfile, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.businessPartnerProfile,
			{ id: businessPartnerProfileId }
		],
		queryFn: () => fetch.businessPartnerProfile(businessPartnerProfileId),
		...config(options)
	});
};

export const useCompanyAgents = <T = SubscriptionAgent>(
	options?: UseQueryOptions<T, any, SubscriptionAgent>
): UseQueryResult<SubscriptionAgent, unknown> => {
	return useQuery({
		queryKey: [queryKey.companyAgents],
		queryFn: () => fetch.companyAgents(),
		...config(options)
	});
};

export const useCompanyCommunityPartner = <
	T = DataList<SubscriptionCommunityPartner>
>(
	{
		pageSize = 12,
		pageNumber = 1,
		keyword = '',
		regionCode,
		IsPremium
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<SubscriptionCommunityPartner>>
): UseQueryResult<DataList<SubscriptionCommunityPartner>, unknown> => {
	return useQuery({
		queryKey: [
			queryKey.communityPartners,
			{ pageSize, pageNumber, keyword, regionCode, IsPremium }
		],
		queryFn: () =>
			fetch.communityPartners(
				pageSize,
				pageNumber,
				keyword,
				regionCode,
				IsPremium
			),
		...config(options)
	});
};

export const useUpdateBusinessPartner = (
	options?: UseQueryOptions<BusinessPartnerProfileInitialData>
) => {
	const query = useBusinessPartnerMe(options);
	const queryProfile = useBusinessPartnerProfile(
		query?.data?.businessPartnerProfileId as string,
		{
			enabled: !!query?.data?.businessPartnerProfileId
		}
	);
	const businessPartner = query.data;
	const setting = businessPartner?.companySetting;
	const address = businessPartner?.address;
	const { sendRequest } = useAxios();

	const updateBusinessPartner: (
		fields: Partial<BusinessPartnerProfileFormData>,
		onFinal?: () => void,
		onCatch?: () => void
	) => Promise<void> = async (fields, onFinal, onCatch) => {
		const data = {
			...businessPartner,
			...fields
		};
		await axios
			.put(api.updateBusinessPartnerProfile, data)
			.then((res) => {
				if (res.status === 200) {
					query.refetch();
					queryProfile.refetch();
					startTransition(() => {
						toastify('toastify.business-partner-updated', {
							type: 'success'
						});
					});
					onFinal?.();
				}
			})
			.catch(() => onCatch?.());
	};

	const updateCompanySetting: (
		field: Partial<CompanySetting>,
		onFinal?: () => void
	) => Promise<void> = async (field, onFinal) => {
		const data = {
			companySetting: {
				...setting,
				...field
			}
		};
		await sendRequest(
			api.updateCompanySetting,
			{ data, method: 'PATCH' },
			() => {
				query.refetch();
				startTransition(() => {
					toastify('toastify.business-partner-updated', {
						type: 'success'
					});
				});
				onFinal?.();
			}
		);
	};

	const updateBusinessPartnerLogo: (
		field: Partial<businessPartnerLogo>,
		onFinal?: () => void
	) => Promise<void> = async (field, onFinal) => {
		const data = field;
		await sendRequest(
			api.updateBusinessPartnersLogo,
			{ data, method: 'PATCH' },
			() => {
				query.refetch();
				startTransition(() => {
					toastify('toastify.business-partner-updated', {
						type: 'success'
					});
				});
				onFinal?.();
			}
		);
	};

	const updateCompanyLinkedin: (
		field: LinkedinConfig,
		// returnUrl?: string,
		onFinal?: () => void
	) => Promise<void> = async (field, onFinal) => {
		await sendRequest(
			api.updateCompanyLinkedinConfig,
			{ data: field, method: 'PATCH' },
			() => {
				onFinal?.();
			}
		);
		// await axios
		// 	.patch(api.updateCompanyLinkedinConfig, field)
		// 	.then(async (res) => {
		// 		if (res.status === 200) {
		// 			await getLinkedinToken(() => {
		// 			});
		// 		}
		// 	});
	};

	return {
		...query,
		businessPartner,
		setting,
		address,
		updateBusinessPartner,
		updateCompanySetting,
		updateBusinessPartnerLogo,
		updateCompanyLinkedin
	};
};

export const updateBusinessPartnerEditProfile: (
	businessPartnerProfileId: string,
	fields: BusinessPartnerProfile,
	onFinal?: () => void,
	onCatch?: () => void
) => Promise<void> = async (
	businessPartnerProfileId,
	fields,
	onFinal,
	onCatch
) => {
	const data = fields;
	await axios
		.put(
			api.updateBusinessPartnerEditProfile(businessPartnerProfileId),
			data
		)
		.then((res) => {
			if (res.status === 200) {
				startTransition(() => {
					toastify('toastify.business-partner-updated', {
						type: 'success'
					});
				});
				onFinal?.();
			}
		})
		.catch(() => onCatch?.());
};

export const usePaymentMethodList = <T = PaymentMethod[]>(
	options?: UseQueryOptions<T, any, PaymentMethod[]>
): UseQueryResult<PaymentMethod[], unknown> => {
	return useQuery({
		queryKey: [queryKey.paymentMethods],
		queryFn: () => fetch.PaymentMethods(),
		...config(options)
	});
};
