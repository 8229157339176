import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ShimmerImage } from 'Elements';
import { VoucherUsageInfo } from 'Types';
import { formatLocaleDateTime } from 'Utils';
import styles from 'partials/card/voucher-card.module.scss';

interface Props {
	companies: VoucherUsageInfo[] | null;
}

export const VoucherCompanies: FC<Props> = memo(({ companies }) => {
	const defaultImage = '/assets/img/business-partner-default.png';
	const { t } = useTranslation();
	return (
		<div className="h-100 d-flex flex-column">
			<h5 className="text-gray-3 mb-3 fs-5 fw-400">
				{t('title.companies-list')}
			</h5>
			<div className="flex-grow-1">
				{companies?.map((company) => (
					<div key={company.companyId} className={styles.company}>
						<div className={styles.companyLogoWrapper}>
							<ShimmerImage
								src={company.logo}
								alt={company.name}
								defaultImage={defaultImage}
								width={50}
								height={50}
								className={styles.companyLogo}
							/>
						</div>
						<div className="d-flex flex-column flex-grow-1">
							<h6 className="fw-500 lh-base mb-1">
								{company.name ?? '---'}
							</h6>
							<time
								className="text-gray-3"
								title={formatLocaleDateTime(
									company.usageDateTime,
									false
								)}
								dateTime={formatLocaleDateTime(
									company.usageDateTime,
									false
								)}>
								{`${t(
									'label.usage-date'
								)}: ${formatLocaleDateTime(
									company.usageDateTime,
									false
								)}`}
							</time>
						</div>
					</div>
				))}
				{companies?.length === 0 && (
					<div className="d-flex align-items-center justify-content-center text-gray-3 text-center">
						{t('title.no-companies-use-voucher')}
					</div>
				)}
			</div>
		</div>
	);
});

VoucherCompanies.displayName = 'VoucherCompanies';
