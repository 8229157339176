import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormGroup, Label } from 'reactstrap';
import {
	AgentPartnerApprovalStatus,
	ColorValues,
	Colors,
	ModalProps,
	PartnershipRequestStatus
} from 'Types';
import {
	Button,
	ModalBox,
	ModalBoxFooter,
	SubmitButton,
	FontAwesome
} from 'Elements';

interface Props extends ModalProps {
	status?: PartnershipRequestStatus;
	partnerName: string;
	onSubmit: (description: string) => Promise<void>;
}

export const ModalBoxPartnerApproval: FC<Props> = ({
	isOpen,
	onClose,
	status = PartnershipRequestStatus.Approved,
	partnerName,
	onSubmit
}) => {
	const { t } = useTranslation();
	const isApprove = status === PartnershipRequestStatus.Approved;

	const {
		register,
		handleSubmit,
		formState: { isSubmitting }
	} = useForm({
		defaultValues: {
			description: ''
		}
	});

	const submitHandler: SubmitHandler<{
		description: string;
	}> = async (data) => {
		await onSubmit(data.description);
	};

	return (
		<ModalBox
			isOpen={isOpen}
			onClose={onClose}
			title={t('title.partner-approval-rejection')}>
			<form onSubmit={handleSubmit(submitHandler)}>
				<div className="d-flex flex-column gap-3">
					<FontAwesome
						icon={
							isApprove
								? 'building-circle-check'
								: 'triangle-exclamation'
						}
						size="8x"
						color={isApprove ? ColorValues.primary : Colors.red}
					/>
					<div
						className="lh-md text-center"
						dangerouslySetInnerHTML={{
							__html: t(
								`text.confirm-partner-${
									isApprove ? 'approval' : 'rejection'
								}`,
								{
									partner: partnerName
								}
							)
						}}
					/>
					{/* <FormGroup>
							<Label>
								{t(
									`forms.any-reason-to-${
										isApprove ? 'approve' : 'reject'
									}-company`,
									{
										company: partnerName
									}
								)}
								&nbsp;(
								{t('placeholder.optional')})
							</Label>
							<textarea
								{...register('description')}
								id="description"
								placeholder={t(
									`placeholder.${
										isApprove ? 'approve' : 'reject'
									}-business-partner-explanation`
								)}
								className="inputbox w-100"
								cols={10}
								rows={5}
							/>
						</FormGroup> */}
				</div>
				<ModalBoxFooter className="justify-content-between mt-4">
					<Button
						color={Colors['white-gray']}
						label={t('button.cancel') as string}
						onClick={onClose}
					/>
					<SubmitButton
						icon={isApprove ? 'check' : false}
						color={isApprove ? Colors.primary : Colors['white-red']}
						label={
							isApprove
								? 'button.approve-partner'
								: 'button.reject-partner'
						}
						savingLabel=""
						isSubmitting={isSubmitting}
						onClick={handleSubmit}
					/>
				</ModalBoxFooter>
			</form>
		</ModalBox>
	);
};
