import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Button,
	Card,
	FontAwesome,
	LoadingContent,
	NoData
} from 'src/containers/Elements';
import styles from './job-partner.module.scss';
import { Colors } from 'src/types/Color';
import FindPartners from '../components/FindPartners';
import { useSubscriptions } from 'src/hooks/useSubscription';
import {
	SubscriptionPlanType,
	SubscriptionStatus
} from 'src/types/Subscription';

import PageBadgeFilter from 'src/components/elements/_shared/heading/PageBadgeFilter';
import { subscriptionStatusFilter } from 'src/helpers/FilterBy';
import PartnerCard from './PartnerCard';
import { getFilterByValue } from 'src/helpers/Utils';
import { useJobUsage } from 'src/hooks/useJob';

function JobPartners() {
	const { t } = useTranslation();
	const [findPartner, setFindPartner] = useState(false);
	const [statusSearchParams, setSearchParams] = useState('');

	const { data: jobUsage } = useJobUsage();

	const { data: subscriptions, isFetching } = useSubscriptions(
		{
			pageSize: 200,
			pageNumber: 1,
			keyword: '',
			subscriptionPlanType: SubscriptionPlanType.PremiumPartner,
			subscriptionStatus: statusSearchParams
		},
		{
			staleTime: 1000 * 60 * 1
		}
	);

	const approveStatusFilter = getFilterByValue(
		subscriptionStatusFilter,
		statusSearchParams ?? ''
	);

	const handleFilterBy = (filterName: string, selected_filter: string) => {
		setSearchParams(selected_filter as string);
	};
	return (
		<Card
			style={{
				flex: 1,
				margin: 0
			}}>
			<div className="d-flex justify-content-start align-items-start flex-column gap-4 border-bottom border-1 pb-3 border-gray-7">
				<span className={styles.title}>{t('Premium Partners')}</span>
				<div className={styles['find-partner']}>
					<span>
						{t(
							'You have no special subscription with premium  community partner... you can buy subscription with premium partner to be able to publish and boost your job to be visible globaly.'
						)}
					</span>
					<div>
						<Button
							color={Colors['white-primary']}
							onClick={() => {
								setFindPartner(true);
							}}>
							<div className="d-flex gap-2">
								<FontAwesome icon="search" size="lg" />
								{t('Find premium Partners')}
							</div>
						</Button>
					</div>
				</div>
			</div>
			<div className="mt-2 fw-semibold">
				{t(
					'In order to publish this job in  the following premium partners, please select or deselect those you want to publish your job. The jobs will be shown on the job platform of the selected partners.'
				)}
			</div>
			<div className="d-flex align-items-center flex-wrap my-3 gap-3">
				<div>{t('Filter by community partner type:')}</div>
				<div>
					<PageBadgeFilter
						selectedFilter={t(approveStatusFilter?.title ?? 'all')}
						filters={subscriptionStatusFilter}
						onChange={(selected) => {
							handleFilterBy(
								'status',
								selected.value?.toString() ?? ''
							);
						}}
					/>
				</div>
			</div>
			<div className={styles['premium-card-wrapper']}>
				{subscriptions?.items.length == 0 && (
					<NoData message="title.no-subscription" />
				)}
				{subscriptions?.items
					.filter((s) => {
						return (
							s.status === SubscriptionStatus.active ||
							s.status === SubscriptionStatus.expired
						);
					})
					.map((subscription) => {
						return (
							<PartnerCard
								subscription={subscription}
								jobUsage={jobUsage}
								key={subscription.id}
							/>
						);
					})}
			</div>
			{isFetching && <LoadingContent title="loading..." />}

			<FindPartners
				isPremium={true}
				isOpen={findPartner}
				toggleDrawer={() => {
					setFindPartner((prev) => !prev);
				}}
			/>
		</Card>
	);
}

export default JobPartners;
