import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCommunityPartnerCredits } from 'Hooks';
import { BadgeStatus, Colors, TransactionStatus, TransactionType } from 'Types';
import { containerVariants } from 'Constants';
import {
	clx,
	currencyFormatter,
	formatLocaleDateTime,
	agentSettlementStatus,
	transactionType,
	unslug
} from 'Utils';
import {
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableRow,
	DataTableRowCell,
	NoData,
	Pagination,
	ListingPage,
	Button,
	PaymentBadgeStatus,
	EndOfList,
	SystemErrorAlert,
	FontAwesome
} from 'Elements';
import styles from 'partials/page/transaction.module.scss';

export const AgentListTransaction = memo(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const statusParam = unslug(searchParams.get('status') ?? '') ?? '';
	const typeParam = unslug(searchParams.get('type') ?? '') ?? '';

	const [state, setState] = useState({
		pageSize: 20,
		totalItems: 0,
		currentPage: pageParam,
		keyword: queryParam,
		filter: {
			status: statusParam,
			type: typeParam
		}
	});

	const getFilterByName = (filter_name: string) => {
		return agentSettlementStatus.find(
			(filter) =>
				filter.title?.toLowerCase() === filter_name?.toLowerCase()
		);
	};

	const getTypeByValue = (filter_name: TransactionType) => {
		return transactionType.find((filter) => filter.value === filter_name);
	};

	const { data, isError, isFetching } = useCommunityPartnerCredits(
		{
			status: getFilterByName(statusParam)?.value,
			pageSize: state.pageSize,
			pageNumber: state.currentPage,
			keyword: state.keyword
		},
		{
			onSuccess: (data) => {
				setState((prev) => ({
					...prev,
					totalItems: data.totalItems
				}));
			}
		}
	);

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handleFilterBy = (selected_filter: Partial<BadgeStatus>) => {
		setSearchParams({
			...params,
			page: '1',
			status: selected_filter.title?.toString() ?? ''
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			filter: {
				...prev.filter,
				status: selected_filter.label?.toString() ?? ''
			}
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const redirectTo = (id: string) => {
		void navigate(`/transactions/${id}`, {
			state: { prevPath: '/transactions' }
		});
	};

	const cellClassName = (col: string) => {
		const cols: { [x: string]: string } = {
			'transaction-number': 'w-sm-15',
			company: 'w-sm-25 fw-500',
			'created-at': 'w-sm-15',
			type: 'w-sm-10',
			amount: 'w-sm-10',
			status: 'w-sm-10',
			operation: 'w-sm-15'
		};
		return clx('w-100', cols[col.toLowerCase()]);
	};

	return (
		<ListingPage
			totalItems={data?.totalItems}
			pageSizes={[10, 15, 20, 50]}
			selectedPageSize={state.pageSize}
			filterOptions={agentSettlementStatus}
			selectedFilter={t(
				getFilterByName(statusParam)?.label ?? 'dropdown.all'
			)}
			onFilterChange={handleFilterBy}
			onSearchChange={handleSearch}
			onPageSizeChange={onChangePageSize}>
			<DataTable>
				{state?.totalItems > 0 && (
					<DataTableHead>
						<DataTableRowCell
							cellText="table.transaction-number"
							cellClassName={cellClassName('transaction-number')}
						/>
						<DataTableRowCell
							cellText="table.company"
							cellClassName={cellClassName('businessPartner')}
						/>
						<DataTableRowCell
							cellText="table.created-at"
							cellClassName={cellClassName('created-at')}
						/>
						<DataTableRowCell
							cellText="table.type"
							cellClassName={cellClassName('type')}
						/>
						<DataTableRowCell
							cellText="table.amount"
							cellClassName={cellClassName('amount')}
						/>
						<DataTableRowCell
							cellText="table.status"
							cellClassName={cellClassName('status')}
						/>
						<DataTableRowCell
							cellText="table.operation"
							cellClassName={cellClassName('operation')}
						/>
					</DataTableHead>
				)}
				<DataTableBody>
					{!isFetching && (
						<motion.div
							variants={containerVariants}
							initial="hidden"
							animate="show">
							{data?.items?.map((transaction) => {
								return (
									<DataTableRow key={transaction.id}>
										<DataTableRowCell
											cellClassName={cellClassName(
												'transaction-number'
											)}
											cellHead="table.transaction-number"
											cellText={
												transaction.transactionCode
											}
										/>
										<DataTableRowCell
											cellClassName={cellClassName(
												'businessPartner'
											)}
											cellHead="table.company">
											{transaction.type !==
												TransactionType.payment && (
												<span>
													{transaction.company
														?.name || '---'}
												</span>
											)}
											{transaction.type ===
												TransactionType.payment && (
												<span className="fw-300">
													[...]
												</span>
											)}
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName(
												'created-at'
											)}
											cellHead="table.created-at">
											<time
												title={formatLocaleDateTime(
													transaction.createdAt
												)}
												dateTime={formatLocaleDateTime(
													transaction.createdAt
												)}>
												{formatLocaleDateTime(
													transaction.createdAt
												)}
											</time>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName(
												'type'
											)}
											cellHead="table.type"
											cellText={
												getTypeByValue(transaction.type)
													?.label ?? '---'
											}
										/>
										<DataTableRowCell
											cellClassName={cellClassName(
												'amount'
											)}
											cellHead="table.amount">
											<div className="d-flex flex-column text-end text-sm-start">
												<strong
													className="fs-base"
													title={currencyFormatter(
														transaction.totalAmount,
														{
															currency:
																transaction.currency
														}
													)}>
													{currencyFormatter(
														transaction.totalAmount,
														{
															currency:
																transaction.currency
														}
													)}
												</strong>
												<span className="text-gray-3 lh-base fs-small">
													{transaction.taxIncluded
														? t(
																'label.tax-included'
														  )
														: t('label.no-tax')}
												</span>
											</div>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName={cellClassName(
												'status'
											)}
											cellHead="table.status"
											boxClassName="d-flex align-items-center flex-wrap gap-2">
											{transaction.donate && (
												<PaymentBadgeStatus
													list={[
														TransactionStatus.Donated
													]}
													title={
														TransactionStatus.Donated
													}
													color={Colors.tertiary}
												/>
											)}
											{!transaction.donate && (
												<PaymentBadgeStatus
													list={[
														TransactionStatus.UnPaid
													]}
													title={transaction.status}
													color={Colors.muted}
												/>
											)}
											<PaymentBadgeStatus
												list={[TransactionStatus.Done]}
												title={transaction.status}
												color={Colors.primary}
											/>
											{!transaction?.donate && (
												<PaymentBadgeStatus
													list={[
														TransactionStatus.Pending
													]}
													title={transaction.status}
													color={Colors.warning}
												/>
											)}
											<PaymentBadgeStatus
												list={[TransactionStatus.Paid]}
												title={transaction.status}
												color={Colors.success}
											/>
											<PaymentBadgeStatus
												list={[
													TransactionStatus.Failed
												]}
												title={transaction.status}
												color={Colors.error}
											/>
										</DataTableRowCell>
										<DataTableRowCell
											cellHead="table.operation"
											cellClassName={cellClassName(
												'operation'
											)}
											boxClassName="d-flex flex-wrap gap-2">
											<Button
												size="sm"
												color={Colors['white-primary']}
												label={
													t(
														'button.details'
													) as string
												}
												onClick={() =>
													redirectTo(transaction.id)
												}
											/>
											{transaction?.downloadUrl && (
												<a
													href={
														transaction?.downloadUrl
													}
													rel="noreferrer"
													target="_blank"
													className={styles.button}
													download={`jobbiplace-invoice_${transaction?.transactionCode}.pdf`}>
													<FontAwesome
														icon="download"
														size="lg"
													/>
												</a>
											)}
										</DataTableRowCell>
									</DataTableRow>
								);
							})}
						</motion.div>
					)}
					{isFetching && (
						<DataTableLoading
							widths={[15, 25, 15, 10, 10, 10, 15]}
							count={state.pageSize}
						/>
					)}
				</DataTableBody>
				{isError && <SystemErrorAlert />}
				{!isFetching && data?.totalItems === 0 && (
					<NoData message="title.no-transaction" />
				)}
			</DataTable>
			{!isFetching && <EndOfList data={data} />}
			{!isFetching && data && data?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={data?.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
		</ListingPage>
	);
});

AgentListTransaction.displayName = 'AgentListTransaction';
