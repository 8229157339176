import { Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCommunityPartnerCredit } from 'Hooks';
import { Colors, TransactionType } from 'Types';
import {
	clx,
	CommunityPartnerCreditStatus,
	currencyFormatter,
	formatLocaleDateTime
} from 'Utils';
import { Card, LoadingContent, Badge } from 'Elements';
import styles from 'partials/card/subscription-card.module.scss';

export const ViewCredit = () => {
	const { t } = useTranslation();
	const defaultImage = '/assets/img/business-partner-default.png';
	const { credit_id } = useParams();
	const { data: credit, isLoading } = useCommunityPartnerCredit(
		credit_id as string
	);

	const getStatusData = (status) => {
		return CommunityPartnerCreditStatus.find(
			(elem) => elem.value === status
		);
	};

	return (
		<Card>
			<h6 className={clx(styles.row, 'text-gray-3 fs-6 fw-400')}>
				<span className={styles.rowLabel}>{t('title.credit')}</span>
				<div className="d-flex align-items-center">
					<Badge
						color={
							getStatusData(credit?.creditStatus)?.color ??
							Colors.primary
						}>
						{t(`${getStatusData(credit?.creditStatus)?.label}`)}
					</Badge>
				</div>
			</h6>
			<Row>
				<Col sm={12}>
					<div className={styles.row}>
						<span className={styles.rowLabel}>
							{t('label.credit-number')}
						</span>
						<span className="fs-6">
							{credit?.creditNumber ?? '---'}
						</span>
					</div>
					{/* <div className={styles.row}>
						<span className={styles.rowLabel}>
							{t('label.credit-number')}
						</span>
						<span className="fs-6">
							{credit?.creditNumber ?? '---'}
						</span>
					</div> */}
					<div className={styles.row}>
						<span className={styles.rowLabel}>
							{t('label.created-at')}:
						</span>
						<span>
							{credit?.createdAt
								? formatLocaleDateTime(credit?.createdAt)
								: '---'}
						</span>
					</div>
					<div className={styles.row}>
						<span className={styles.rowLabel}>
							{t('label.tax')}
							<span className="ms-1 text-gray-3 fs-small">
								({credit?.taxPercent ?? 0}%)
							</span>
							:
						</span>
						<span>
							{currencyFormatter(
								credit?.taxAmount && credit.taxAmount > 0
									? credit.taxAmount
									: 0
							)}
						</span>
					</div>
					<div className={styles.row}>
						<span className={clx(styles.rowLabel, 'fw-500')}>
							{t('label.amount')}
						</span>
						<div className="d-flex align-items-center text-end">
							<span className="fs-6 fw-500">
								{currencyFormatter(
									credit?.totalCreditAmount ?? 0
								)}
							</span>
							<span className="text-gray-3 ms-2 fs-small">
								(
								{credit?.includedTax
									? t('label.tax-included')
									: t('label.no-tax')}
								)
							</span>
						</div>
					</div>
					<div className={styles.row}>
						<span className={styles.rowLabel}>
							{t('title.business-partner')}:
						</span>
						<div className="d-flex align-items-center gap-2">
							<img
								src={
									credit?.businessPartnerInfo
										?.thumbnailLogo || defaultImage
								}
								alt={
									credit?.businessPartnerInfo
										?.organizationName ?? '---'
								}
								className={clx(styles.logo, styles.logoSm)}
							/>
							<span className="fs-sm fw-400">
								{credit?.businessPartnerInfo
									?.organizationName ?? '---'}
							</span>
						</div>
					</div>
				</Col>
			</Row>
			{isLoading && <LoadingContent />}
		</Card>
	);
};
