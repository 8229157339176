import { FC, memo, useState } from 'react';
import wNumb from 'wnumb';
import { PipsMode } from 'nouislider';
import { useTranslation } from 'react-i18next';
import {
	useCommunityPartnerCommissionLevelMe,
	useCommunityPartnerMe,
	useSelector,
	useSubscriptionPlansCommissionGroups
} from 'Hooks';
import {
	AgentCommissionLevel,
	CommunityPartnerCommissionLevelMe,
	SubscriptionCommissionGroups
} from 'Types';
import { clx, currencyFormatter } from 'Utils';
import {
	AgentCommissionLevelBox,
	AgentCommissionLevelBoxLoading,
	FormInlineTip,
	RangeSlider
} from 'Elements';
import styles from 'partials/page/agent-plan-price.module.scss';

interface Props {
	className?: string;
	additionalPlanPrice?: number;
}

export const AgentAboCalculator: FC<Props> = ({
	additionalPlanPrice = 0,
	className
}) => {
	const { t } = useTranslation();
	const { assetConfig } = useSelector((state) => state.marketing);
	const { data: commissionLevelMe } = useCommunityPartnerCommissionLevelMe();
	const { data: communityPartner, isLoading: isLoadingProfile } =
		useCommunityPartnerMe();
	const [partnerNumber, setPartnerNumber] = useState(30);
	const [activeCommissionLevel, setActiveCommissionLevel] = useState<
		CommunityPartnerCommissionLevelMe | undefined
	>(commissionLevelMe);

	const { data: subscriptionPlansCommissionGroups } =
		useSubscriptionPlansCommissionGroups(
			assetConfig?.commissionGroupId as string,
			{
				enabled: !!assetConfig?.commissionGroupId
			}
		);

	const basePlanPrice = 1000;
	const commissionLevels: any = subscriptionPlansCommissionGroups?.commissionLevels;
	const agentCommissionLevel =
		commissionLevelMe?.CommissionLevelInfo?.CommissionPercent ?? 0;

	const currentAgentPayByPartner =
		(agentCommissionLevel / 100) * basePlanPrice * partnerNumber;

	const additionalAgentPayByPartner = partnerNumber * additionalPlanPrice;
	const totalAgentPrice = currencyFormatter(
		currentAgentPayByPartner + additionalAgentPayByPartner
	);

	const handleActiveLevel = (
		active_level: CommunityPartnerCommissionLevelMe
	) => {
		setActiveCommissionLevel(active_level);
	};

	const handleChange = (value: (string | number)[]) => {
		const number = Number(value[0]);
		setPartnerNumber(+number.toFixed(0));
	};

	return (
		<div className={className}>
			<h4 className="mb-3 lh-base fs-6">
				{t('title.plan-price.calculation')}
			</h4>
			<div className="d-flex align-items-center lh-base mb-3">
				<span>{t('label.plan-price.number-of-partners')}</span>
				<span className="ms-auto fw-500">{partnerNumber}</span>
			</div>
			<div className="pb-4">
				<span className="d-flex mb-2">
					{t('label.plan-price.commission')}:
				</span>
				<div className="d-flex flex-wrap gap-2">
					{!isLoadingProfile &&
						commissionLevels?.map((level, idx) => {
							return (
								<AgentCommissionLevelBox
									key={level.id}
									commissionLevel={level}
									current={
										level.commissionPercent ===
										activeCommissionLevel
											?.CommissionLevelInfo
											?.CommissionPercent
									}
									active={
										partnerNumber <=
											level.maxActiveMainAbo &&
										partnerNumber >= level.minActiveMainAbo
									}
									last={commissionLevels.length - 1 === idx}
									onLevelChange={handleActiveLevel}
								/>
							);
						})}
					{isLoadingProfile && (
						<AgentCommissionLevelBoxLoading count={4} />
					)}
				</div>
			</div>
			<RangeSlider
				className={clx(styles.slider, 'mb-5')}
				range={{
					min: [0],
					'20%': [100],
					'40%': [200],
					'60%': [300],
					'80%': [400],
					max: [500]
				}}
				start={partnerNumber}
				tooltips={wNumb({ decimals: 0 })}
				step={1}
				pips={{
					mode: PipsMode.Range,
					density: 4,
					format: wNumb({ decimals: 0 })
				}}
				onUpdate={handleChange}
			/>
			<FormInlineTip
				tip={t('label.plan-price.agent-price-example', {
					numberOfPartner: partnerNumber,
					agentPercentPrice:
						(agentCommissionLevel / 100) * basePlanPrice,
					agentPrice: additionalPlanPrice
				})}
			/>
			<div className="d-flex align-items-center lh-base border-top mt-3 pt-3">
				<span className="fw-500 fs-base">
					{t('title.plan-price.total-agent-earning')}
				</span>
				<div className="d-flex flex-column ms-auto gap-1">
					<span className="fs-6 fw-700 text-gray-5 lh-base text-end">
						{totalAgentPrice}
					</span>
					<span className="text-gray-3 lh-1 fw-400 fs-small text-end">
						{t('label.excluded-tax')}
					</span>
				</div>
			</div>
		</div>
	);
};
