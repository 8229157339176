import { Col, FormGroup, Label, Row } from 'reactstrap';
import {
	Button,
	DatePicker,
	FontAwesome,
	ReactSwitch
} from 'src/containers/Elements';
import { getCurrentLocale } from 'src/helpers/Utils';
import styles from 'partials/step/step-container.module.scss';
import { useTranslation } from 'react-i18next';
import { Colors } from 'src/types/Color';

interface Props {
	listPeriod: any;
	onChangeHandler: (index, key, value) => void;
	setListPeriod: (data) => void;
	type?: 'create' | 'update';
}

const getLocalFormat = () => {
	const currentLocale = getCurrentLocale().code;
	if (currentLocale === 'de') {
		return 'dd.MM.yyyy';
	}
	return 'yyyy-MM-dd';
};

function CardInternship({
	listPeriod,
	setListPeriod,
	onChangeHandler,
	type = 'create'
}: Props) {
	const { t } = useTranslation();
	return listPeriod?.map((period, index) => {
		return (
			<div
				key={index}
				className="border p-4"
				style={{
					borderRadius: '8px'
				}}>
				{listPeriod.length > 1 || type == 'update' ? (
					<div className="d-flex justify-content-end">
						<Button
							color={Colors['white-red']}
							onClick={() => {
								const list: any = [];
								listPeriod.forEach((item, i) => {
									if (index !== i) {
										list.push(item);
									}
								});

								setListPeriod(list);
							}}>
							<FontAwesome icon="trash-can" size="lg" />
						</Button>
					</div>
				) : null}
				<Row>
					<Col>
						<FormGroup>
							<Label htmlFor="title">
								{t('forms.title')}
								<small className="ms-1">
									({t('validation.required')})
								</small>
							</Label>
							<input
								type="text"
								className="inputbox w-80"
								value={period.title}
								onChange={(e) => {
									onChangeHandler(
										index,
										'title',
										e.target.value
									);
								}}
							/>
							{!period.title && (
								<small className="invalid-feedback d-block">
									{t('validation.internship.title')}
								</small>
							)}
						</FormGroup>
					</Col>
					<Row>
						<Col>
							<FormGroup>
								<Label htmlFor="from">
									{t('forms.from')}
									<small className="ms-1">
										({t('validation.required')})
									</small>
								</Label>
								<DatePicker
									minDate={new Date()}
									dateFormat={getLocalFormat()}
									locale={getCurrentLocale().lang}
									value={period.from}
									className={'inputbox w-100'}
									onChange={(val: Date) => {
										onChangeHandler(index, 'from', val);
									}}
								/>
								{!period.from && (
									<small className="invalid-feedback d-block">
										{t('validation.internship.from')}
									</small>
								)}
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label htmlFor="to">
									{t('forms.to')}
									<small className="ms-1">
										({t('validation.required')})
									</small>
								</Label>
								<DatePicker
									minDate={new Date(period.from)}
									dateFormat={getLocalFormat()}
									locale={getCurrentLocale().lang}
									value={period.to}
									className={'inputbox w-100'}
									onChange={(val: Date) => {
										onChangeHandler(index, 'to', val);
									}}
								/>
								{!period.to && (
									<small className="invalid-feedback d-block">
										{t('validation.internship.to')}
									</small>
								)}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<div className="d-flex align-items-center gap-2 mb-2">
							<ReactSwitch
								id="test"
								size="sm"
								checked={period?.includeTimeRange}
								onChange={() => {
									onChangeHandler(
										index,
										'includeTimeRange',
										!period?.includeTimeRange
									);
								}}
							/>
							<span className={styles.rowLabel}>
								{t('forms.including-time-range')}
							</span>
						</div>
					</Row>
					{period?.includeTimeRange && (
						<Row>
							<Col>
								<FormGroup className="w-100">
									<Label htmlFor="startTime">
										{t('forms.startTime')}
										<small className="ms-1">
											({t('validation.required')})
										</small>
									</Label>
									<DatePicker
										value={period.startTime}
										onChange={(time: any) => {
											onChangeHandler(
												index,
												'startTime',
												time
											);
										}}
										className={'inputbox w-100'}
										showTimeSelect
										showTimeSelectOnly
										timeFormat="HH:mm"
										dateFormat="HH:mm"
									/>
									{!period.startTime && (
										<small className="invalid-feedback d-block">
											{t(
												'validation.internship.startTime'
											)}
										</small>
									)}
								</FormGroup>
							</Col>
							<Col>
								<FormGroup className="w-100">
									<Label htmlFor="endTime">
										{t('forms.endTime')}
										<small className="ms-1">
											({t('validation.required')})
										</small>
									</Label>
									<DatePicker
										minTime={
											period?.startTime
												? new Date(
														period?.startTime
												  ).getTime()
												: new Date().setHours(0, 0, 0)
										}
										maxTime={new Date().setHours(23, 30, 0)}
										value={period.endTime}
										onChange={(time: any) => {
											onChangeHandler(
												index,
												'endTime',
												time
											);
										}}
										className={'inputbox w-100'}
										showTimeSelect
										showTimeSelectOnly
										timeFormat="HH:mm"
										dateFormat="HH:mm"
									/>
									{!period.endTime && (
										<small className="invalid-feedback d-block">
											{t('validation.internship.endTime')}
										</small>
									)}
								</FormGroup>
							</Col>
						</Row>
					)}
				</Row>
			</div>
		);
	});
}

export default CardInternship;
