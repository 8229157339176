import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Badge,
	Button,
	FontAwesome,
	LoadingMask,
	LoadingSpinner
} from 'Elements';
import { currencyFormatter } from 'Utils';
import { Colors, SubscriptionPlan } from 'Types';
import styles from 'partials/card/subscription-plan-card.module.scss';
import { useSubscriptionCalculate } from 'src/hooks/useSubscription';

interface Props {
	card: SubscriptionPlan;
	selectedCommunityPartnerId?: string;
	selected: boolean;
	loadingPlansFeature: boolean;
	onSelect: (plan: SubscriptionPlan) => void;
}

export const SubscriptionPlanCard: FC<Props> = ({
	card,
	loadingPlansFeature,
	selectedCommunityPartnerId,
	onSelect
}) => {
	const { t } = useTranslation();

	const handleSelectPlan = () => {
		onSelect(card);
	};

	const { data: subscriptionCalculate, isLoading } = useSubscriptionCalculate(
		selectedCommunityPartnerId as string,
		card.id as string,
		'',
		card.type as number,
		{
			enabled: !!selectedCommunityPartnerId
		}
	);

	return (
		<>
			<div className={styles.cardPlan}>
				<div className="d-flex align-items-center justify-content-between">
					<h1 className="h4 fs-5 text-gray-4">{card.name}</h1>
					{card?.isRecommended && (
						<Badge color={Colors.primary}>
							{t('forms.recommended')}
						</Badge>
					)}
				</div>
				<h4 className="fw-500 fs-5">{currencyFormatter(card.price)}</h4>
				<span className="text-gray-3 fw-500">
					{`${t('title.per')} ${card.durationDays} ${t(
						'title.days'
					)}`}
				</span>
				<div
					className="pt-2 lh-base my-3"
					dangerouslySetInnerHTML={{
						__html: card.shortDescription
					}}
				/>
				<div
					className="lh-base mb-4"
					dangerouslySetInnerHTML={{
						__html: card.description
					}}
				/>
				{loadingPlansFeature && <LoadingSpinner />}
				{!loadingPlansFeature && card?.featureData && (
					<div className="px-2 pt-3 pb-2">
						{card?.featureData?.eventSetting?.eventsEnabled && (
							<div className="d-flex align-items-center gap-2 mb-3">
								<FontAwesome
									icon="square-check"
									size="lg"
									className="flex-shrink-0 text-secondary"
								/>
								<div>{t('text.feature.events-enabled')}:</div>
								<div>
									{card?.featureData?.eventSetting
										?.eventsEnabled
										? t('label.yes')
										: t('label.no')}
								</div>
							</div>
						)}

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>{t('text.feature.max-publish-events')}:</div>
							<div>
								{card?.featureData?.eventSetting
									?.maxPublishEvents ?? 0}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>
								{t('text.feature.publish-events-enabled')}:
							</div>
							<div>
								{card?.featureData?.eventSetting
									?.publishEventsEnabled
									? t('label.yes')
									: t('label.no')}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>{t('text.feature.internship-enabled')}:</div>
							<div>
								{card?.featureData?.internshipSetting
									?.internshipEnabled
									? t('label.yes')
									: t('label.no')}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>
								{t('text.feature.internship-manager-enabled')}:
							</div>
							<div>
								{card?.featureData?.internshipSetting
									?.internshipManagerEnabled
									? t('label.yes')
									: t('label.no')}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>
								{t('text.feature.max-publish-internships')}:
							</div>
							<div>
								{card?.featureData?.internshipSetting
									?.maxPublishInternships ?? 0}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>
								{t('text.feature.publish-internship-enabled')}:
							</div>
							<div>
								{card?.featureData?.internshipSetting
									?.publishInternshipEnabled
									? t('label.yes')
									: t('label.no')}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>{t('text.feature.job-enabled')}:</div>
							<div>
								{card?.featureData?.jobSetting?.jobEnabled
									? t('label.yes')
									: t('label.no')}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>{t('text.feature.jobs-google-index')}:</div>
							<div>
								{card?.featureData?.jobSetting?.jobsGoogleIndex
									? t('label.yes')
									: t('label.no')}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>{t('text.feature.limit-job-visibility')}:</div>
							<div>
								{card?.featureData?.jobSetting
									?.limitJobVisibility
									? t('label.yes')
									: t('label.no')}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>{t('text.feature.max-publish-jobs')}:</div>
							<div>
								{card?.featureData?.jobSetting
									?.maxPublishJobs ?? 0}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>{t('text.feature.publish-job-enabled')}:</div>
							<div>
								{card?.featureData?.jobSetting
									?.publishJobEnabled
									? t('label.yes')
									: t('label.no')}
							</div>
						</div>

						<div className="d-flex align-items-center gap-2 mb-3">
							<FontAwesome
								icon="square-check"
								size="lg"
								className="flex-shrink-0 text-secondary"
							/>
							<div>{t('text.feature.max-user-accounts')}:</div>
							<div>
								{card?.featureData?.userAccountSetting
									?.maxUserAccounts ?? 0}
							</div>
						</div>
					</div>
				)}
			</div>
			<div>
				{card.price !== 0 && subscriptionCalculate?.totalAmount !== 0 && (
					<>
						{!isLoading && (
							<div className="mt-4 mb-3">
								<div className="d-flex flex-column">
									<div className="d-flex gap-2 pe-3 me-3 text-primary">
										<h5 className="h5 mb-0">
											{currencyFormatter(
												subscriptionCalculate?.totalAmount ??
													0
											)}
										</h5>
										<span className="fw-300">
											{t('title.excluded-tax')}
										</span>
									</div>
									<div className="text-gray-3 text-start d-flex gap-2 text-primary">
										<span className="fw-300 lh-base text-primary">
											{t('title.per')}
										</span>
										<span className="mt-auto fs-base text-primary">
											{`${
												subscriptionCalculate?.subscriptionDuration ??
												''
											} ${t('title.days')}`}
										</span>
									</div>
								</div>
							</div>
						)}
						{isLoading && (
							<div className="d-flex flex-column gap-2 mt-4 mb-2">
								<div
									className="bg-gray-2"
									style={{
										width: '150px',
										height: '17px'
									}}></div>
								<div
									className="bg-gray-2"
									style={{
										width: '100px',
										height: '15px'
									}}></div>
							</div>
						)}
					</>
				)}
				<Button
					color={Colors.primary}
					className="w-100"
					onClick={handleSelectPlan}>
					{t('button.select')}
				</Button>
			</div>
		</>
	);
};
