import { Col } from 'reactstrap';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useSubscription, useSubscriptionPayments } from 'Hooks';
import { SubscriptionDetails, Tab } from 'Types';
import {
	Card,
	DetailsSubscription,
	LoadingContent,
	TabNavigation
} from 'Elements';
import pageStyles from 'partials/shared/page.module.scss';
import { Suspense, useEffect } from 'react';

export const ViewSubscription = () => {
	const { subscription_id } = useParams();
	const { pathname } = useLocation();
	const { data: subscription, isFetching } = useSubscription(
		subscription_id as string
	);

	const { data: subscriptionPayments } = useSubscriptionPayments(
		subscription?.currentSubscriptionPaymentId ?? '',
		{
			enabled: !!subscription?.currentSubscriptionPaymentId
		}
	);

	useEffect(() => {
		if (subscription && subscription.status === 1) {
			localStorage.setItem('showPm', 'false');
		}
	}, [subscription]);

	const tabs: Tab[] = [
		{
			id: 0,
			title: 'tab.current-price-info',
			path: 'current-price-info',
			isActive:
				pathname === `/subscriptions/${subscription_id}` ||
				pathname ===
					`/subscriptions/${subscription_id}/current-price-info`
		},
		{
			id: 1,
			title: 'tab.history',
			path: 'history',
			isActive: pathname === `/subscriptions/${subscription_id}/history`
		},
		{
			id: 2,
			title: 'tab.assigned-payment-method',
			path: 'assigned-payment-method',
			isActive:
				pathname ===
				`/subscriptions/${subscription_id}/assigned-payment-method`
		},
		{
			id: 3,
			title: 'tab.features',
			path: 'features',
			isActive: pathname === `/subscriptions/${subscription_id}/features`
		}
	];

	return (
		<>
			<Card className="pb-0">
				<DetailsSubscription
					subscriptionPayments={subscriptionPayments as any}
					subscription={subscription as SubscriptionDetails}
					subscriptionId={subscription_id as string}
					communityPartnerInfo={subscription?.communityPartnerInfo}
					isLoading={isFetching}
				/>
				<div className={pageStyles.tab}>
					{tabs?.map((tab) => {
						return (
							<TabNavigation
								key={tab.id}
								to={tab.path}
								title={tab.title}
								isActive={tab.isActive}
							/>
						);
					})}
				</div>
			</Card>
			<Col xs={12}>
				<Suspense fallback={<LoadingContent />}>
					<Outlet />
				</Suspense>
			</Col>
		</>
	);
};
