import { FC, Dispatch, SetStateAction } from 'react';
import { Col, Row } from 'reactstrap';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useActivityTags, useCreateJob, useJobTags, useSelector } from 'Hooks';
import { clx, formatLocaleDateTime, getCurrentLocale } from 'Utils';
import { Button, StepNavigation, FontAwesome } from 'Elements';
import { Colors, JobForm, JobFormData } from 'Types';
import styles from 'partials/step/step-container.module.scss';
import coverStyles from 'partials/control/cover-controller.module.scss';
import { de, enUS } from 'date-fns/locale';

interface Props {
	fields: JobForm;
	onNext: () => void;
	onPrev: () => void;
	goToStep: (stepId: string) => void;
	setJobId: Dispatch<SetStateAction<string>>;
}

export const CreateJobPreviewSubmit: FC<Props> = ({
	fields,
	onPrev,
	onNext,
	goToStep
}) => {
	const { t } = useTranslation();
	const { assetConfig } = useSelector((state) => state.marketing);
	const { submitRequest } = useCreateJob();
	const {
		handleSubmit,
		formState: { isSubmitting }
	} = useForm();

	const { data: dataJobsTags } = useJobTags({
		pageSize: 500,
		pageNumber: 1,
		keyword: '',
		jobTagCategoryId: '',
		noAssignedCategory: false,
		isActive: ''
	});

	const { data: dataActivityTags } = useActivityTags({
		pageSize: 500,
		pageNumber: 1,
		keyword: '',
		isActive: ''
	});

	const onSubmit = async () => {
		if (fields.step3.jobTags && fields.step3.jobActivities) {
			if (
				fields?.step0?.kind === 1 &&
				fields?.step5?.length !== 0 &&
				fields?.step5[0].title !== ''
			) {
				fields?.step5?.forEach(async (item) => {
					if (item?.includeTimeRange) {
						const setTime = (date: Date, time: Date) => {
							date.setHours(time.getHours(), time.getMinutes());
							return new Date(date);
						};
						item.from = setTime(
							new Date(item.from),
							new Date(item.startTime as any)
						) as any;
						item.to = setTime(
							new Date(item.to),
							new Date(item.endTime as any)
						) as any;
					}
				});
			}
			const api_data: JobFormData = {
				...fields.step1,
				...fields.step2,
				...fields.step4,
				jobPeriods:
					fields?.step5?.length !== 0
						? fields?.step5.every(
								(item) =>
									item.title !== '' &&
									item.from !== '' &&
									item.to !== ''
						  )
							? fields?.step5
							: []
						: [],
				contactPerson: null,
				kind: fields?.step0?.kind as number,
				partnerType: assetConfig?.partnerType === 2 ? 0 : 1,
				featuredPhoto: fields?.step2?.featuredPhoto?.url
					? fields?.step2?.featuredPhoto
					: null,
				featuredVideo: fields?.step2?.featuredVideo?.url
					? fields?.step2?.featuredVideo
					: null,
				partnerName: assetConfig?.nameElement as string,
				primaryJobLocation: {
					regionCode: assetConfig?.regionCode as number,
					title: assetConfig?.address?.city as string,
					locationPoint: {
						longitude: assetConfig?.address?.location
							?.longitude as number,
						latitude: assetConfig?.address?.location
							?.latitude as number
					}
				},

				startDate: fields.step1.startDate
					? format(
							new Date(fields.step1.startDate as string),
							'yyyy-MM-dd'
					  )
					: '',
				expiredDate: fields.step1.expiredDate
					? format(
							new Date(fields.step1.expiredDate as string),
							'yyyy-MM-dd'
					  )
					: '',
				jobTags: fields.step3.jobTags.map((job: any) => {
					return { jobTagId: job?.jobTagId, order: job.order };
				}),
				jobActivities: fields.step3.jobActivities
			};
			await submitRequest(api_data, () => {
				onNext?.();
			});
		}
	};

	const getTitle = (id: any, array: any) => {
		let title = '';
		array?.items?.forEach((element) => {
			if (id === element.id) {
				title = element.title;
				element.translateTitles.find((item) => {
					if (
						item?.language.toLowerCase() ===
						getCurrentLocale().code.toLowerCase()
					) {
						title = item.value
							? item.value
							: element.translateTitles[0].value ?? element.title;
					}
				});
				if (title === '') {
					title = element.title;
				}
			}
		});
		return title;
	};

	const getLocalFormat = () => {
		const currentLocale = getCurrentLocale().code;
		if (currentLocale === 'de') {
			return 'dd.MM.yyyy';
		}
		return 'yyyy-MM-dd';
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
			<Row>
				<Col sm={5} md={12} xl={5} xxl={4} className="mb-4">
					<img
						src={
							fields.step2.featuredPhoto.url ||
							'/assets/img/default-image.png'
						}
						alt={fields.step1.title}
						className={clx(coverStyles.cover, 'h-auto')}
					/>
				</Col>
				<Col sm={7} md={12} xl={7} xxl={8} className="mb-4">
					<div className="d-flex flex-column gap-2 mb-2">
						<h4 className="fw-500 fs-base mb-0 lh-base">
							{fields.step1.title}
						</h4>
						<span className="text-gray-3 fs-small d-flex align-items-center">
							{t('forms.code')}:&nbsp;
							{fields.step1.code || '---'}
						</span>
					</div>
					<p className="text-gray-3 mb-0 lh-base">
						{fields.step1.shortDescription || '---'}
					</p>
				</Col>
				<Col sm={6} md={12} xl={6} className="mb-4">
					<h4 className="fw-500 fs-sm">{t('forms.start-date')}</h4>
					<time className="text-gray-3">
						{fields.step1?.hasStartDate
							? formatLocaleDateTime(
									fields.step1?.startDate as string,
									false
							  )
							: '---'}
					</time>
				</Col>
				<Col sm={6} md={12} xl={6} className="mb-4">
					<h4 className="fw-500 fs-sm">{t('forms.expire-date')}</h4>
					<time className="text-gray-3">
						{fields.step1.hasExpireDate
							? formatLocaleDateTime(
									fields.step1?.expiredDate as string,
									false
							  )
							: '---'}
					</time>
				</Col>
			</Row>
			<div className="my-4 py-4 border-bottom position-relative">
				<Button
					color={Colors['white-gray']}
					className={styles.edit}
					onClick={() => goToStep('step1')}>
					<FontAwesome icon="pen" />
				</Button>
			</div>
			<section className="mb-3 pb-2">
				<h4 className="fs-sm d-flex align-items-center text-primary mb-3">
					{t('wizard.step-job-3')}
				</h4>
				<Row>
					<Col className="mb-4">
						<h4 className="fw-500 fs-sm">{t('forms.job-tags')}</h4>
						<div className="d-flex align-items-center gap-2">
							{fields.step3.jobTags?.map((item) => {
								return (
									<div
										className="d-flex align-items-center"
										style={{
											borderRadius: '8px',
											padding: '8px 16px',
											background:
												'linear-gradient(180deg, #EFEFF2 0%, #F8F8F9 100%)'
										}}
										key={item?.jobTagId}>
										<span className="text-gray-3">
											{getTitle(
												item.jobTagId,
												dataJobsTags
											)}
										</span>
									</div>
								);
							})}
						</div>
					</Col>
				</Row>
				<Row>
					<Col className="mb-4">
						<h4 className="fw-500 fs-sm">
							{t('forms.activity-tags')}
						</h4>
						<div className="d-flex align-items-center gap-2">
							{fields?.step3?.jobActivities?.map((activity) => {
								return (
									<div
										className="d-flex align-items-center"
										style={{
											borderRadius: '8px',
											padding: '8px 16px',
											background:
												'linear-gradient(180deg, #EFEFF2 0%, #F8F8F9 100%)'
										}}
										key={activity}>
										<span className="text-gray-3">
											{getTitle(
												activity,
												dataActivityTags
											)}
										</span>
									</div>
								);
							})}
						</div>
					</Col>
				</Row>
				<div className="my-4 py-4 border-bottom position-relative">
					<Button
						color={Colors['white-gray']}
						className={styles.edit}
						onClick={() => goToStep('step3')}>
						<FontAwesome icon="pen" />
					</Button>
				</div>
			</section>
			<section className="mb-3 pb-2">
				<h4 className="fs-sm d-flex align-items-center text-primary mb-3">
					{t('wizard.step-job-4')}
				</h4>
				<div className="mb-4">
					<h4 className="fw-500 fs-sm">{t('forms.desc')}</h4>
					<div
						className="text-gray-3"
						dangerouslySetInnerHTML={{
							__html: fields.step4.description || '---'
						}}
					/>
				</div>
				<div className="mb-4">
					<h4 className="fw-500 fs-sm">{t('forms.benefits')}</h4>
					<div
						className="text-gray-3"
						dangerouslySetInnerHTML={{
							__html: fields.step4.benefitsText || '---'
						}}
					/>
				</div>
				<div className="mb-4">
					<h4 className="fw-500 fs-sm">{t('forms.requirements')}</h4>
					<div
						className="text-gray-3"
						dangerouslySetInnerHTML={{
							__html: fields.step4.requirementsText || '---'
						}}
					/>
				</div>
				<div className="mb-4">
					<h4 className="fw-500 fs-sm">
						{t('forms.responsibilities')}
					</h4>
					<div
						className="text-gray-3"
						dangerouslySetInnerHTML={{
							__html: fields.step4.responsibilitiesText || '---'
						}}
					/>
				</div>
				<div className="my-4 py-4 border-bottom position-relative">
					<Button
						color={Colors['white-gray']}
						className={styles.edit}
						onClick={() => goToStep('step4')}>
						<FontAwesome icon="pen" />
					</Button>
				</div>
			</section>
			{fields?.step5 && fields?.step5?.length !== 0 && (
				<section className="mb-3 pb-2">
					<h4 className="fs-sm d-flex align-items-center text-primary mb-3">
						{t('wizard.step-job-5')}
					</h4>
					{fields?.step5[0].title === '' ? (
						<span className="fs-sm fw-400">
							{t('text.free-to-apply-internship')}
						</span>
					) : (
						<span className="fs-sm fw-400">
							{t('text.with-defined-appointment-internship')}
						</span>
					)}
					<div className="d-flex flex-wrap w-100 align-items-center gap-4 mt-3">
						{fields?.step5[0].title !== '' &&
							fields?.step5?.map((item, index) => {
								return (
									<div
										key={index}
										className="w-40 border p-3"
										style={{
											borderRadius: '8px',
											height: '157px'
										}}>
										<span className="fw-500 fs-sm">
											{item?.title}
										</span>
										<div className="mt-4 d-flex flex-column gap-2">
											<div className="d-flex justify-content-between fs-sm fw-400 text-gray-3">
												<span>{t('forms.from')}</span>
												<span>
													{`${format(
														new Date(item.from),
														`${getLocalFormat()}`,
														{
															locale:
																getCurrentLocale()
																	.code ===
																'de'
																	? de
																	: enUS
														}
													)}`}
												</span>
											</div>
											<div className="d-flex justify-content-between fs-sm fw-400 text-gray-3">
												<span>{t('forms.to')}</span>
												<span>
													{`${format(
														new Date(item.to),
														`${getLocalFormat()}`,
														{
															locale:
																getCurrentLocale()
																	.code ===
																'de'
																	? de
																	: enUS
														}
													)}`}
												</span>
											</div>
											{item.includeTimeRange && (
												<div className="d-flex justify-content-between fs-sm fw-400 text-gray-3">
													<span>
														{t('forms.time')}
													</span>
													<span>{`${new Date(
														item?.startTime as any
													).getHours()}:${new Date(
														item?.startTime as any
													).getMinutes()} - ${new Date(
														item?.endTime as any
													).getHours()}:${new Date(
														item?.endTime as any
													).getMinutes()}`}</span>
												</div>
											)}
										</div>
									</div>
								);
							})}
					</div>
					<div className="my-4 py-4 border-bottom position-relative">
						<Button
							color={Colors['white-gray']}
							className={styles.edit}
							onClick={() => goToStep('step5')}>
							<FontAwesome icon="pen" />
						</Button>
					</div>
				</section>
			)}
			<StepNavigation
				type="submit"
				label="button.job.create-job"
				savingLabel=""
				onPrev={onPrev}
				isSubmitting={isSubmitting}
			/>
		</form>
	);
};
