import JobPartners from './job-partners';
import PremiumPartners from './premium-partners';
import styles from './visibility-setting.module.scss';
export default function VisibilitySetting() {
	return (
		<>
			<div className={styles.wrapper}>
				<JobPartners />
				<PremiumPartners />
			</div>
		</>
	);
}
